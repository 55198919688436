import React, { useState, useEffect, useCallback } from "react";
import toastService from "services/toastService";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// CSS
import "./DesignBriefing.css";

// API Services
import projectServices from "services/projectServices";

// Reducers
import {
  getNonIterationProjectById,
  getProjectById,
  selectedIteration,
  updateBriefingKey,
  updateCompleteProject,
  updateCurrentProjectId,
  updateProjectProperty,
} from "app/reducers/projectEditSlice";
import { EditItration } from "app/reducers/EditItration";
import { saveProject, updateProject } from "app/reducers/projectSclice";
import { saveActiveButton } from "app/reducers/activeButton";

// Components
import Loading from "../magic/loading";
import RightBar from "views/dashboard/RightBar";
import TryagainModal from "components/TryAgainModal/TryagainModal";

const DesignBriefing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SelectedProjectId = useSelector(
    (state) => state.projectEdit.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state.projectEdit.selectedIterationId
  );

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );

  let projectRecordData = useSelector(
    (state) => state.projectEdit.singleProject
  );
  const itration = useSelector((state) => state.EditItrationNo.itration);
  const [briefingData, setBriefingData] = useState({});
  const [timeDifference, setTimeDifference] = useState("");
  const [loading, setLoading] = useState(false);
  const [toggleTryAgain, settoggleTryAgain] = useState(false);
  const [tryagainText, settryagainText] = useState(false);
  const [liketext, setliketext] = useState({
    dontLike: "",
    extraInstruction: "",
  });

  useEffect(() => {
    function timeSince(date) {
      const now = new Date();
      const secondsPast = (now.getTime() - date.getTime()) / 1000;

      if (secondsPast < 60) {
        return `${Math.round(secondsPast)} seconds ago`;
      }
      if (secondsPast < 3600) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
      }
      if (secondsPast <= 86400) {
        return `${Math.round(secondsPast / 3600)} hours ago`;
      }
      if (secondsPast > 86400) {
        const daysPast = Math.round(secondsPast / 86400);
        return `${daysPast} days ago`;
      }
    }

    if (projectRecordData?.updatedAt) {
      const lastUpdatedDate = new Date(projectRecordData.updatedAt);
      setTimeDifference(timeSince(lastUpdatedDate));
    }

    const intervalId = setInterval(() => {
      if (projectRecordData?.updatedAt) {
        const lastUpdatedDate = new Date(projectRecordData.updatedAt);
        setTimeDifference(timeSince(lastUpdatedDate));
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [projectRecordData]);

  useEffect(() => {
    setBriefingData(projectRecordData?.briefing);
  }, [projectRecordData?.briefing, briefingData]);

  const handleTextareaChange = (key, value) => {
    setBriefingData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    dispatch(updateBriefingKey({ key, value, SelectedIterationId }));
  };

  const handelSubmit = async () => {
    try {
      setLoading(true);
      const res = await projectServices.updateProject(SelectedProjectId, {
        briefing: briefingData,
        iteration_id: SelectedIterationId,
        iterate_step: 2,
      });

      if (itration) {
        const { data } = await projectServices.generateFonts(
          SelectedProjectId,
          {
            ...res.data.data.result,
            iterate_step: 2,
            iteration_id: SelectedIterationId,
          }
        );
        const matchedIteration = data.data.result.iteration.find(
          (iter) => iter._id === SelectedIterationId
        );

        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;

        const projectData = await projectServices.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 3 }
        );
        const sortedIterations = projectData.data.data.result.iteration.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        console.log('projectData.data.data.result', projectData.data.data.result)
        dispatch(updateCompleteProject(projectData.data.data.result));
        const index =
          sortedIterations.length > 0 ? sortedIterations.length - 1 : 0;
        dispatch(selectedIteration(sortedIterations[index]._id));
        dispatch(EditItration(false));
        navigate("/designtypography");
        setLoading(false);
      } else {
        const fonts = await projectServices.generateFonts(SelectedProjectId, {
          ...res.data.data.result,
          iterate_step: 3,
          iteration_id: SelectedIterationId,
        });
        dispatch(updateCompleteProject(fonts.data.data.result));
        console.log('fonts.data.data.result', fonts.data.data.result)
        dispatch(
          updateProjectProperty({
            _id: SelectedProjectId,
            key: "selected_fonts",
            value: fonts.data.data.result?.fonts[0],
          })
        );
        navigate("/designtypography");
        setLoading(false);
      }
    } catch (ex) {
      setLoading(false);
    }
  };

  const handleChangeLikeText = useCallback(
    (name) => (evt) => {
      setliketext((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );

  const handelTryAgain = async (type) => {
    try {
      setLoading(true);
      settryagainText(true);
      let formData = {
        ...projectRecordData,
        iteration_id: SelectedIterationId,
      };
      if (type === "like") {
        formData = {
          ...formData,
          dontLike: liketext?.dontLike,
        };
      } else if (type === "instruction") {
        formData = {
          ...formData,
          extraInstruction: liketext?.extraInstruction,
        };
      }
      const res = await projectServices.briefingTryAgin(
        SelectedProjectId,
        formData
      );

      if (itration) {
        const matchedIteration = res.data.data.result.iteration.find(
          (iter) => iter._id === SelectedIterationId
        );

        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;
        const projectData = await projectServices.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 2 }
        );
        const sortedIterations = projectData.data.data.result.iteration.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        dispatch(updateCompleteProject(projectData.data.data.result));
        dispatch(selectedIteration(sortedIterations[0]._id));
        dispatch(EditItration(false));
        setLoading(false);
        settoggleTryAgain(false);
        settryagainText(false);
        setliketext({
          ...liketext,
          dontLike: "",
          extraInstruction: "",
        });
      } else {
        dispatch(updateCompleteProject(res.data.data.result));
        const index =
          res.data.data.result.iteration.length > 0
            ? res.data.data.result.iteration[
                res.data.data.result.iteration.length - 1
              ]
            : 0;
        dispatch(selectedIteration(index._id));
      }
      setLoading(false);
      settoggleTryAgain(false);
      settryagainText(false);
    } catch {
      setLoading(false);
      settoggleTryAgain(false);
      settryagainText(false);
    }
  };

  const handelEditClick = async (projectId) => {
    dispatch(saveActiveButton("Project"));
    try {
      const pro = await projectServices.getProjectId(projectId);
      dispatch(updateCurrentProjectId(pro?.data?.data?.result?._id));
      dispatch(getNonIterationProjectById(pro?.data?.data?.result?._id));
      dispatch(saveProject(pro?.data?.data?.result));
      dispatch(updateProject({ property: "update", value: true }));
      navigate("/projectUpdate");
    } catch (ex) {
      toastService.error(ex);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading tryagainText={tryagainText} />
        </div>
      ) : (
        <div className="designBriefingContainer">
          <div className="leftBriefingBox1">
            <div className="timelineBoxBriefing">
              <div className="timelineBriefing">
                <div className="connectorBriefing"></div>
                <div className="connectorBriefing"></div>
                <div className="connectorBriefing"></div>
                <div className="connectorBriefing"></div>
                <div className="connectorBriefing"></div>
                <div className="connectorBriefing"></div>
              </div>
            </div>

            <div className="projectDesignBriefingBox">
              <p className="DesignBriefingPara">Designing: Briefing</p>
              <p className="DesignBriefinglastSaved">
                Last saved iteration: {timeDifference}
              </p>
            </div>

            <div className="DesignBriefingBox">
              <div className="noteText">
                <span className="beta_text design_briefing">
                  NOTE: Please use English to write the inputs for the Briefing.
                  More languages coming soon.
                </span>
              </div>

              <p className="mt-2 briefingCreatedPara">
                Here is the briefing we have created for{" "}
                {projectRecordData?.name}.
                <span className="BriefingParaSpan">
                  If there is something you do not agree with, just click on the
                  text and edit it.
                </span>
              </p>

              <p className="briefingParabox">
                {projectRecordData?.client_name} | {projectRecordData?.name}
              </p>

              <div className="projectDescriptionBoxx">
                <div className="projectDescriptionBoxxInner">
                  {Object.entries(briefingData)
                    ?.filter(([key, value]) => key != "Brand Name")
                    ?.map(([key, value]) => (
                      <>
                        <p className="projectDescriptionParain">{key}</p>
                        <textarea
                          type="text"
                          className="projectDescriptionParaout"
                          value={
                            typeof value == "object"
                              ? Object.entries(value)
                                  ?.map(([goal, objective]) => `${objective}`)
                                  .join("\n")
                              : value
                          }
                          rows={1}
                          onChange={(e) =>
                            handleTextareaChange(key, e.target.value)
                          }
                          ref={(textarea) => {
                            if (textarea) {
                              textarea.style.height = "auto";
                              textarea.style.height = `${textarea.scrollHeight}px`;
                            }
                          }}
                        />
                      </>
                    ))}
                </div>
              </div>

              <div className="btnsDivBoxx">
                <button
                  onClick={(e) => {
                    handelEditClick(SelectedProjectId);
                    dispatch(EditItration(true));
                  }}
                  className="GoBackBtnIn"
                >
                  <span style={{ display: "block" }}>Go back</span>
                </button>
                <div className="btnsDivBoxxInner">
                  <button
                    className="TryAgainBtnIn"
                    onClick={() => settoggleTryAgain(true)}
                  >
                    <span style={{ display: "block" }}>Try again</span>
                  </button>
                  <button
                    onClick={() => {
                      handelSubmit();
                    }}
                    className="NextStepBtnIn"
                  >
                    <span style={{ display: "block" }}>Next step</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}

      {toggleTryAgain && (
        <TryagainModal
          settoggleTryAgain={settoggleTryAgain}
          handleSubmit={handelTryAgain}
          handleChangeLikeText={handleChangeLikeText}
          liketext={liketext}
          setliketext={setliketext}
        />
      )}
    </>
  );
};

export default DesignBriefing;
