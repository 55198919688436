import React, { useEffect, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import "./pdfSlider.css";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
const PDFViewer = ({ pdfUrl }) => {
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const loadedPdfDoc = await pdfjsLib.getDocument(pdfUrl).promise;
        setPdfDoc(loadedPdfDoc);
        setNumPages(loadedPdfDoc.numPages);
        // Render the first page
        await renderPage(loadedPdfDoc, 1);
      } catch (error) {
        console.error(`Error during PDF loading: ${error}`);
      }
    };

    loadPdf();
  }, [pdfUrl]);

  useEffect(() => {
    const renderCurrentPage = async () => {
      if (pdfDoc != null && pageNum <= numPages) {
        await renderPage(pdfDoc, pageNum);
      }
    };

    renderCurrentPage();
  }, [pageNum, pdfDoc, numPages]);

  const renderPage = async (pdfDoc, pageNumber) => {
    try {
      const page = await pdfDoc.getPage(pageNumber);

      const desiredHeight = 500;
      const viewport = page.getViewport({ scale: 1 });
      const scale = desiredHeight / viewport.height;
      const scaledViewport = page.getViewport({ scale: scale });

      const canvas = document.getElementById("pdf-canvas");
      const context = canvas?.getContext("2d");
      canvas.height = desiredHeight;
      canvas.width = scaledViewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      };

      await page.render(renderContext).promise;
    } catch (error) {
      console.error(`Error during page rendering: ${error}`);
    }
  };

  const onPrevPage = () => {
    setPageNum((prevPageNum) => (prevPageNum > 1 ? prevPageNum - 1 : 1));
  };

  const onNextPage = () => {
    setPageNum((prevPageNum) =>
      prevPageNum < numPages ? prevPageNum + 1 : numPages
    );
  };

  return (
    <div className="pdfSlider">
      <button onClick={onPrevPage} className="leftarrowbtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="2em"
          viewBox="0 0 448 512"
        >
          <path
            fill="#777879"
            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
          />
        </svg>
      </button>
      <canvas id="pdf-canvas"></canvas>

      <button onClick={onNextPage} className="rightarrowbtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="2em"
          viewBox="0 0 448 512"
        >
          <path
            fill="#777879"
            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
          />
        </svg>
      </button>
    </div>
  );
};

export default PDFViewer;
