/* eslint-disable no-lone-blocks */
import "./Projects.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import { SearchContext } from "components/layout/app/app";
import RightBar from "views/dashboard/RightBar";
import { useNavigate } from "react-router-dom";
import projectServices from "services/projectServices";
import authServices from "services/authServices";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  saveProject,
  updateProject,
} from "../../../app/reducers/projectSclice";
import { saveActiveButton } from "app/reducers/activeButton";
import toastService from "services/toastService";
import { EditItration } from "app/reducers/EditItration";
import memberServices from "services/memberServices";
import CircularProgress from "@mui/material/CircularProgress";
import {
  deleteSelectedProject,
  getProjectById,
  renewProject,
  selectedIteration,
  updateCurrentProjectId,
  deleteIteration,
  getNonIterationProjectById,
  addProject,
} from "app/reducers/projectEditSlice";
import { deleteProject } from "app/reducers/projectEditSlice";
import clientServices from "services/clientServices";
import { saveClientDb } from "app/reducers/clientDbSlice";

export function Projects() {
  const [inProgressProject, setInProgressProject] = useState([]);
  const selected_iD = useRef("");
  const [modalOpenImage, setModalOpenImage] = useState(false);
  const [modalOpenDelete, setModalOpenDelete] = useState(false);
  const planDb = useSelector((state) => state.planDb);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  dispatch(deleteSelectedProject());
  dispatch(deleteIteration());
  dispatch(saveActiveButton("Projects"));

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const handelNewProjectClick = () => {
    if (planDb.remaining_project + planDb.extra_project > 0) {
      dispatch(saveActiveButton("Projects"));
      navigate("/dashboard/createnewproject");
    } else {
      toastService.info("Please subscribe to a plan or buy projects");
      dispatch(saveActiveButton("Billing"));
      navigate("/billing");
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: -100 },
    show: { opacity: 1, y: 0 },
  };

  const navigate = useNavigate();

  useEffect(() => {
    const user = authServices?.getCurrentUser();
    getAllInProgressProjects(user?.id);
  }, []);

  const getAllInProgressProjects = async (id) => {
    try {
      const personalDataRes = await projectServices?.getProjectByUserId(id);
      const memberDataRes = await memberServices?.getProjectsAsMember(id);
      const personalProjects = personalDataRes?.data?.data?.result;
      const memberProjects = memberDataRes?.data?.data?.result;

      personalProjects.forEach((project) => {
        project.role = "Admin";
      });

      const combinedProjects = [
        ...personalProjects,
        ...memberProjects.map((item) => item?.project),
      ];
      combinedProjects.map((project) => {
        dispatch(addProject(project));
      });
      for (let project of combinedProjects) {
        const members = await memberServices?.getMemberByProject(project?._id);
        project.members = members?.data?.data?.result || [];
      }
      const processedProjects = processProjectsData(combinedProjects);
      processedProjects.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setInProgressProject(processedProjects);
      setLoading(false);
      dispatch(EditItration(false));
    } catch (ex) {
      setLoading(false);
    }
  };

  const processProjectsData = (projects) => {
    projects.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const projectsWithProjectNo =
      projects &&
      projects?.map((project, index) => ({
        ...project,
        projectNo: index + 1,
      }));

    return projectsWithProjectNo.filter(
      (project) => project?.status === "inProgress"
    );
  };

  const handelDelete = async (id, role) => {
    if (role === "Admin") {
      setModalOpenDelete(true);
      selected_iD.current = id;
      const projectData = inProgressProject.find(
        (project) => project?._id === id
      );
      const cleanedClientName = projectData?.client_name?.trim();
    } else {
      toastService.error(
        "You do not have permission to access this resource.",
        2
      );
    }
  };

  const itration = useSelector((state) => state.EditItrationNo.itration);

  const doDelete = async () => {
    try {
      await projectServices.deleteProject(selected_iD.current);
      dispatch(deleteProject(selected_iD.current));
      const user = authServices.getCurrentUser();
      const client = await clientServices.getClient(user?.id);
      if (client.data.data.result.length > 0) {
        authServices.updateClientProperty(true);
        dispatch(saveClientDb(client.data.data.result));
      }
      toastService.success("Project deleted successfully");
    } catch (ex) {
      toastService.error(ex);
    }
    window.location.reload();
  };

  const handelEditClick = async (projectId) => {
    dispatch(saveActiveButton("Project"));
    try {
      const pro = await projectServices.getProjectId(projectId);
      dispatch(updateCurrentProjectId(pro.data.data.result._id));
      dispatch(getNonIterationProjectById(pro.data.data.result._id));
      dispatch(saveProject(pro.data.data.result));
      dispatch(updateProject({ property: "update", value: true }));
      navigate("/projectUpdate");
    } catch (ex) {
      toastService.error(ex);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 8;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(inProgressProject.length / projectsPerPage);
  const projectsForPage = useMemo(() => {
    const start = (currentPage - 1) * projectsPerPage;
    const end = start + projectsPerPage;
    return inProgressProject.slice(start, end);
  }, [inProgressProject, currentPage, projectsPerPage]);

  const stepToRoute = {
    1: "projectUpdate",
    2: "designbriefing",
    3: "designtypography",
    4: "colorpalette",
    5: "symbolism",
    6: "symbolismlogo",
    7: "brand-assets",
    8: "mockups",
    9: "resultstarter",
  };

  const iterationStep = {
    2: "Briefing",
    3: "Typography",
    4: "Color Pallete",
    5: "Logo Symbol",
    6: "Logo",
    7: "Brand Assets",
    8: "Mockups",
    9: "Brand Guidelines"
  }

  const handelIterationClick = (projectid, iterationid, step) => {
    dispatch(EditItration(true));
    dispatch(updateCurrentProjectId(projectid));
    dispatch(
      getProjectById({ projectId: projectid, iteration_id: iterationid })
    );
    dispatch(selectedIteration(iterationid));
    navigate(`/${route(step)}`);
  };
  const route = (step) => stepToRoute[step];
  const currentIterationData = (step) => iterationStep[step]

  return (
    <div className="ProjectsContainer">
      <div className="leftSideProjects">
        {loading ? (
          <div className="leftSideLoading">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <p className="currentProjectsPara">Manage Projects</p>
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="show"
              className={
                projectsForPage.length > 0
                  ? "projectsDivBoxProjects"
                  : "anotherClass"
              }
            >
              {projectsForPage?.length > 0 ? (
                projectsForPage?.map((project, index) => (
                  <motion.div
                    variants={cardVariants}
                    className="ManageProjectCard"
                    key={index}
                  >
                    <div
                      className="projectSinnerImage"
                      style={{
                        backgroundColor: `${project?.color_palette?.main[0]?.Hex}`,
                      }}
                    >
                      {project?.result?.fullLogo ? (
                        <img
                          className="projectSinnerImageLogo"
                          style={{
                            objectFit: "contain",
                            padding: "5px",
                            height: "200px",
                          }}
                          src={project.result.fullLogo}
                          alt=""
                        />
                      ) : (
                        <img src="BrandfySmallIcon.png" alt="" />
                      )}
                    </div>
                    <p className="projectsProjectNamePara">{project.name}</p>
                    <p className="projectsClientNamePara">
                      {project.client_name}
                    </p>
                    <div className="projectsAvatarBoxdiv">
                      {project?.members?.map((member) => {
                        return member.image ? (
                          <img
                            className="projectsAvatarBoxdivcircle"
                            src={member.image}
                            alt="member"
                            key={member._id}
                          />
                        ) : (
                          <div
                            className="projectsAvatarBoxdivcircle"
                            key={member._id}
                          ></div>
                        );
                      })}
                    </div>

                    <div className="projectsGrayBoxInner">
                      <ul className="ulfromProject">
                        {project &&
                          project?.iteration.map((iteration, i) => {
                            const updatedAtDate = new Date(iteration.createdAt);
                            return (
                              <li key={i}>
                                <span
                                  className="date_text"
                                  onClick={() => {
                                    dispatch(EditItration(true));
                                    handelIterationClick(
                                      project?._id,
                                      iteration?._id,
                                      iteration?.iterate_step
                                    );
                                  }}
                                >
                                  {moment(updatedAtDate)?.format("DD-MM-YYYY HH:mm")}{" "}@{" "}{currentIterationData(iteration?.last_iteration_step)}
                                </span>
                              </li>
                            );
                          })}
                      </ul>
                    </div>

                    <div className="editdeletebtns">
                      <button
                        className="editbtns"
                        onClick={(e) => {
                          handelEditClick(project._id);
                          dispatch(EditItration(true));
                        }}
                      >
                        <svg
                          className="editbtnssvg"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5"
                            stroke="#D1D1D1"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.5 3.00023C18.8978 2.6024 19.4374 2.37891 20 2.37891C20.5626 2.37891 21.1022 2.6024 21.5 3.00023C21.8978 3.39805 22.1213 3.93762 22.1213 4.50023C22.1213 5.06284 21.8978 5.6024 21.5 6.00023L12 15.5002L8 16.5002L9 12.5002L18.5 3.00023Z"
                            stroke="#D1D1D1"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          handelDelete(project._id, project.role);
                        }}
                        className="editbtns"
                      >
                        <svg
                          className="editbtnssvg"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 6.5H5H21"
                            stroke="#D1D1D1"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19 6.5V20.5C19 21.0304 18.7893 21.5391 18.4142 21.9142C18.0391 22.2893 17.5304 22.5 17 22.5H7C6.46957 22.5 5.96086 22.2893 5.58579 21.9142C5.21071 21.5391 5 21.0304 5 20.5V6.5M8 6.5V4.5C8 3.96957 8.21071 3.46086 8.58579 3.08579C8.96086 2.71071 9.46957 2.5 10 2.5H14C14.5304 2.5 15.0391 2.71071 15.4142 3.08579C15.7893 3.46086 16 3.96957 16 4.5V6.5"
                            stroke="#D1D1D1"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 11.5V17.5"
                            stroke="#D1D1D1"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14 11.5V17.5"
                            stroke="#D1D1D1"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Delete
                      </button>
                    </div>
                  </motion.div>
                ))
              ) : (
                <div className="dontHaveanyProjectDiv">
                  You don't have any project, yet. <br />
                  <span className="dontHaveanyProjectDivSpan">
                    Go ahead and start your journey!
                  </span>
                </div>
              )}
            </motion.div>
            <button
              onClick={() => {
                handelNewProjectClick();
              }}
              className="NewProjectsCreate"
            >
              <svg
                style={{
                  marginRight: "8.06px",
                  width: "24.05px",
                  height: "24.05px",
                }}
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 4.5H4.5C3.96957 4.5 3.46086 4.71071 3.08579 5.08579C2.71071 5.46086 2.5 5.96957 2.5 6.5V20.5C2.5 21.0304 2.71071 21.5391 3.08579 21.9142C3.46086 22.2893 3.96957 22.5 4.5 22.5H18.5C19.0304 22.5 19.5391 22.2893 19.9142 21.9142C20.2893 21.5391 20.5 21.0304 20.5 20.5V13.5"
                  stroke="#363538"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 3.00023C19.3978 2.6024 19.9374 2.37891 20.5 2.37891C21.0626 2.37891 21.6022 2.6024 22 3.00023C22.3978 3.39805 22.6213 3.93762 22.6213 4.50023C22.6213 5.06284 22.3978 5.6024 22 6.00023L12.5 15.5002L8.5 16.5002L9.5 12.5002L19 3.00023Z"
                  stroke="#363538"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span style={{ paddingTop: "2px" }}> New project </span>
            </button>

            <div className="pagination">
              {[...Array(totalPages)]?.map((e, i) => (
                <li className="pagination__item" key={i}>
                  <button
                    className={`pagination__link ${
                      currentPage === i + 1 ? "pagination__link--active" : ""
                    }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </div>
          </>
        )}

        {modalOpenDelete && (
          <div
            onClick={() => {
              setModalOpenDelete(false);
            }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            }}
          >
            <div className="modalBoxTeam">
              <div className="areYouSure">Confirm project deletion</div>
              <p className="allprojectItration">
                Are you sure you want to delete this project?<br></br>
                You will not be able to access it anymore.
              </p>
              <div className="divbuttonbtns">
                <button
                  onClick={() => {
                    setModalOpenDelete(false);
                    doDelete();
                  }}
                  className="DeleteMemberTeam"
                >
                  Delete
                </button>
                <button
                  onClick={() => {
                    setModalOpenDelete(false);
                  }}
                  className="cancelMemberTeam"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {modalOpenImage && (
          <div
            onClick={() => {
              setModalOpenImage(false);
            }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            }}
          >
            <div className="modalBoxTeam">
              <div className="areYouSure">Confirm Client Deletion</div>
              <p className="allprojectItration">
                Before deleting this client, ensure all projects are unassigned:
              </p>
              <div className="divbuttonbtns">
                <button
                  onClick={() => {
                    setModalOpenImage(false);
                  }}
                  className="DeleteMemberTeam"
                >
                  OK
                </button>
                <button
                  onClick={() => {
                    setModalOpenImage(false);
                  }}
                  className="cancelMemberTeam"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="RightSideDashboard">
        <RightBar />
      </div>
    </div>
  );
}
