import React, { useCallback, useEffect } from "react";
import "./ManageTeams.css";
import ManageTeamsRightBar from "./ManageTeamsRightBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi";
import validationService from "services/validationService";
import toastService from "services/toastService";
import authServices from "services/authServices";
import subscriptionServices from "services/subscriptionServices";
import { savePlanDb } from "app/reducers/planDbSlice";
import projectServices from "services/projectServices";
import memberServices from "services/memberServices";
import { useDropzone } from "react-dropzone";
import { CircularProgress } from "@mui/material";

const ManageTeams = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setisLoading] = useState(false);
  const [selectedProjectId, setselectedProjectId] = useState(null);
  const [toggleModal, settoggleModal] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectData, setProjectsData] = useState([]);
  const [error, setErrors] = useState([]);
  const [newMember, setNewMember] = useState({
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    project: [],
    image: "",
  });

  const plan = useSelector((state) => state.planDb);
  const schema = Joi.object().keys({
    firstName: Joi.string().required().label("firstName"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("email"),
    lastName: Joi.string().label("lastName").allow(""),

    project: Joi.array().allow(""),
    role: Joi.string().label("role").required(),
    image: Joi.string().allow(""),
  });

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles?.[0];
    if (file) {
      handelUploadFile(file);
    }
  }, []);

  const { isDragActive } = useDropzone({ onDrop });
  const {
    getRootProps: getAvoidanceRootProps,
    getInputProps: getAvoidanceInputProps,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles?.[0];
      if (!file) {
        alert("Please select a file.");
        return;
      }
      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      if (allowedMimeTypes.includes(file.type)) {
        handelUploadFile(file);
      } else {
        toastService.error(
          "An error occurred while uploading the file. Please try again.",
          2
        );
      }
    }, []),
  });

  const handelUploadFile = async (file) => {
    if (!file) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await projectServices.uploadImage(formData);
      const image = res?.data?.data?.result;
      setNewMember((prevMemberData) => ({ ...prevMemberData, image }));
    } catch (ex) {
      toastService.error(
        "An error occurred while uploading the file. Please try again.",
        2
      );
    }
  };

  const handleChange =
    (setData) =>
    ({ currentTarget: input }) => {
      const updatedData = { ...newMember, [input.name]: input.value };
      const errorMessage = validationService.validateProperty(input, schema);
      setNewMember(updatedData);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [input.name]: errorMessage || "",
      }));
    };

  const [showSuggestionsRole, setShowSuggestionsRole] = useState(false);
  const [valueRole, setValueRole] = useState("");

  const handleInputChangeRole = (event) => {
    setValueRole(event.target.value);
    setShowSuggestionsRole(true);
  };

  const handleSuggestionClickRole = (suggestion) => {
    setValueRole(suggestion);
    setNewMember({ ...newMember, role: suggestion });
    setShowSuggestionsRole(false);
  };

  const handleYellowBoxClickRole = () => {
    setShowSuggestionsRole(!showSuggestionsRole);
  };

  const suggestionsRole = ["Admin", "Collaborator"];
  const filteredSuggestionsRole = suggestionsRole?.filter((suggestion) =>
    suggestion.toLowerCase().includes(valueRole?.toLowerCase())
  );

  const handleSubmit = () => {
    const errors = validationService.validate(newMember, schema);
    setErrors(errors || {});
    if (errors) return;
    if (plan?.remaining_collaborator === 0) {
      toastService.error(
        "You need to upgrade your plan for adding more collaborators.",
        2
      );
    } else {
      doMemberSubmit();
    }
  };

  const doMemberSubmit = async () => {
    newMember.project = selectedProjects;
    try {
      setisLoading(true);
      const user = authServices.getCurrentUser();
      const data = {
        user_id: user.id,
        ...newMember,
      };

      const res = await memberServices.createMember(data);
      const body = {
        user_id: user.id,
      };
      const updatePlan = await subscriptionServices.updatePlanCollaborator(
        body
      );
      dispatch(savePlanDb(updatePlan.data.data?.result));
      navigate("/manageteam");
    } catch (ex) {
      setisLoading(false);
      toastService.error(ex);
    }
  };

  const handleProjectClick = () => {
    if (selectedProjectId) {
      const projectId = selectedProjectId._id;
      if (selectedProjects.includes(projectId)) {
        setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
      } else {
        setSelectedProjects([...selectedProjects, projectId]);
      }
      settoggleModal(false);
    }
  };

  const handleToggle = (project) => {
    setselectedProjectId(project);
    settoggleModal(true);
  };

  useEffect(() => {
    async function fetchProjectData() {
      try {
        const user = authServices.getCurrentUser();
        const pro = await projectServices.getProjectByUserId(user.id);
        const projectData = pro.data.data.result.filter(
          (item) => item.status === "inProgress"
        );
        setProjectsData(projectData);
      } catch (ex) {
        toastService.error(ex);
      }
    }
    fetchProjectData();
  }, []);

  return (
    <div className="ManageTeamsContainer">
      <div className="ManageTeamsLeftSide">
        <span className="MyteamPara">My team / Add new member</span>
        <div className="mainFormTeamDiv">
          <div className="manageTeaminput">
            <div className="firstNameDivBoxTeams">
              <p className="companyNameparaCityTeam">First name</p>
              <input
                placeholder="First name"
                name="firstName"
                onChange={handleChange(setNewMember)}
                value={newMember.firstName}
                className="companyaddressparacityTeam"
                type="text"
              />
              {error.firstName && (
                <small className="text-danger mx-1">
                  first name is not allowed to be empty
                </small>
              )}
            </div>
            <div className="lastNameDivBoxTeams">
              <p className="companyNameparaCityTeam">Last name</p>
              {error.lastName && (
                <small className="text-danger mx-1">
                  Last name is not allowed to be empty
                </small>
              )}
              <input
                placeholder="Lemans"
                name="lastName"
                onChange={handleChange(setNewMember)}
                value={newMember.lastName}
                className="companyaddressparacityTeam"
                type="text"
              />
            </div>
          </div>
          <div className="manageTeaminput">
            <div className="emailNameDivBoxTeams">
              <p className="companyNameparaCityTeam">E-mail</p>
              <input
                placeholder="robert@brandfy.ai"
                name="email"
                onChange={handleChange(setNewMember)}
                value={newMember.email}
                className="companyaddressparacityTeam"
                type="text"
              />
              {error.email && (
                <small className="text-danger mx-1">{error.email}</small>
              )}
            </div>
            <div className="lastNameDivBoxTeams">
              <div className="roleNameInputBox">
                <p className="companyNameparaCityTeam">
                  Role <span className="companyNameparaCityTeamspan">*</span>
                </p>
                <div
                  className={`boxme2RoleCountry ${
                    showSuggestionsRole ? "show-suggestions2Role" : ""
                  }`}
                >
                  <input
                    className="inputForRoleBox2Role"
                    type="text"
                    readOnly
                    value={newMember.role}
                    onClick={handleYellowBoxClickRole}
                    onChange={handleInputChangeRole}
                    placeholder="Select Role"
                  />
                  {valueRole ? (
                    <svg
                      onClick={() => {
                        setValueRole("");
                        setNewMember({ ...newMember, role: "" });
                      }}
                      className="search-iconManageName"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 6L6 18"
                        stroke="#FD5D5D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 6L18 18"
                        stroke="#FD5D5D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  {showSuggestionsRole &&
                    filteredSuggestionsRole?.length > 0 && (
                      <ul className="suggestion-list2Roll">
                        {filteredSuggestionsRole?.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              handleSuggestionClickRole(suggestion)
                            }
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                  <div
                    className="yellowBox2Role"
                    onClick={handleYellowBoxClickRole}
                  >
                    <svg
                      onClick={handleYellowBoxClickRole}
                      className={`arrow-icon ${
                        showSuggestionsRole ? "up-arrow" : "down-arrow"
                      }`}
                      width="22"
                      height="14"
                      viewBox="0 0 22 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                {error.role && (
                  <small className="text-danger mx-1">
                    Role is not allowed to be empty
                  </small>
                )}
              </div>
            </div>
          </div>
          <div
            {...getAvoidanceRootProps()}
            className="describeButton"
            style={{
              marginTop: "20px",
              background: "none",
              border: "1px solid #484848",
            }}
          >
            {newMember.image && (
              <img name="image" src={newMember.image} alt="User" />
            )}
            <input {...getAvoidanceInputProps()} type="file" />
            {!newMember.image && (
              <p>
                {isDragActive ? (
                  "Drop the files here ..."
                ) : (
                  <button className="addImageInProject">
                    Add profile image
                  </button>
                )}
              </p>
            )}
          </div>
          <div className="dottedLine"></div>
          <p className="assignedProjectsBoxParaTeam">
            Assigned projects
            <span className="assignedProjectsBoxParaSpanTeam">
              {projectData && projectData?.length === 0
                ? "(There are no projects created to assign to the user)"
                : "(Click to assign projects)"}
            </span>
          </p>

          <div className="assignedProjectDivBox">
            {projectData &&
              projectData?.map((project) => (
                <span
                  key={project._id}
                  className={`projectNumassigne ${
                    selectedProjects.includes(project._id) ? "active" : ""
                  }`}
                  // onClick={() => handleProjectClick(project, project._id)}
                  onClick={() => handleToggle(project, project._id)}
                >
                  {project.name}
                </span>
              ))}
          </div>

          <div className="dottedLine"></div>
          <div className="buttonsBtnteam">
            <button
              onClick={() => {
                handleSubmit();
              }}
              className="saveNewClientteam"
              disabled={isLoading}
            >
              <svg
                className="saveNewClientsvg"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5 21.5H5.5C4.96957 21.5 4.46086 21.2893 4.08579 20.9142C3.71071 20.5391 3.5 20.0304 3.5 19.5V5.5C3.5 4.96957 3.71071 4.46086 4.08579 4.08579C4.46086 3.71071 4.96957 3.5 5.5 3.5H16.5L21.5 8.5V19.5C21.5 20.0304 21.2893 20.5391 20.9142 20.9142C20.5391 21.2893 20.0304 21.5 19.5 21.5Z"
                  stroke="#484848"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 21.5V13.5H7.5V21.5"
                  stroke="#484848"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 3.5V8.5H15.5"
                  stroke="#484848"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {isLoading ? (
                <CircularProgress
                  style={{ width: 24, height: 24, color: "white" }}
                  color="primary"
                  variant="indeterminate"
                />
              ) : (
                <span style={{ marginTop: "4px", display: "block" }}>Save</span>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="ManageTeamsRightSide">
        <ManageTeamsRightBar EditMemberHandl={true} />
      </div>

      {/* Confirmation Modal */}

      {toggleModal && (
        <div
          onClick={() => {
            settoggleModal(false);
          }}
          className="assign_project"
        >
          <div className="modal_box border-none">
            <div className="areYouSure">
              Do you really want to assign this project to a new client?
            </div>
            <p className="allprojectItration"></p>
            <div className="divbuttonbtns">
              <button onClick={handleProjectClick} className="DeleteMemberTeam">
                Yes
              </button>
              <button
                onClick={() => {
                  settoggleModal(false);
                }}
                className="cancelMemberTeam"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageTeams;
