// reducers/projectRecordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    active: "Dashboard"
}
const ActiveButtonSlice = createSlice({
    name: 'activeButton',
    initialState,
    reducers: {
        saveActiveButton: (state, action) => {
            state.active = action.payload;
        },

        deleteActiveButton: () => {
            return initialState;
        }
    }
});

export const { saveActiveButton, deleteActiveButton } = ActiveButtonSlice.actions;

export default ActiveButtonSlice.reducer;
