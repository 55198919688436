/* eslint-disable import/no-anonymous-default-export */
import Cookies from "universal-cookie";
import http from "./httpService";
import config from "../config";
const { apiUrl } = config;
const apiEndpoint = `${apiUrl}/project`;
const cookies = new Cookies();

async function CreateProject(body) {
  return await http.post(`${apiEndpoint}`, body);
}
async function updateProject(_id, body) {
  return await http.put(`${apiEndpoint}/${_id}`, body);
}
async function updateProjectIteration(_id, body) {
  return await http.put(`${apiEndpoint}/projectIteration/${_id}`, body);
}
async function deleteProject(_id, body) {
  return await http.delete(`${apiEndpoint}/${_id}`, body);
}
async function uploadImage(body) {
  return await http.post(`${apiEndpoint}/imageUpload`, body);
}
async function getProjectId(id) {
  return await http.get(`${apiEndpoint}/${id}`);
}
async function getProjectByUserId(id) {
  return await http.get(`${apiEndpoint}/user/${id}`);
}
async function generateResult(id, body) {
  return await http.post(`${apiEndpoint}/Result/${id}`, body);
}
async function generateBriefing(id, result) {
  return await http.post(`${apiEndpoint}/generateBriefing/${id}`, result);
}
async function generateMockup(body) {
  return await http.post(`${apiEndpoint}/generateMockup`, body);
}
async function generateColor(id, body) {
  return await http.post(`${apiEndpoint}/generateColor/${id}`, body);
}
async function generateFonts(id, result) {
  return await http.post(`${apiEndpoint}/generateFonts/${id}`, result);
}
async function generateSymbol(id, body) {
  return await http.post(`${apiEndpoint}/generateSymbol/${id}`, body);
}
async function generateMissionAndVision(id, body) {
  return await http.post(`${apiEndpoint}/generateMissionAndVision/${id}`, body);
}

async function generateBook(body) {
  return await http.post(`${apiEndpoint}/generateBook`, body);
}

async function generatePreSignedUrl(body) {
  return await http.post(`${apiEndpoint}/generatePreSignedUrl`, body);
}

async function processPdf(body) {
  return await http.post(`${apiEndpoint}/processPdf`, body);
}

async function generateTexture(id, body) {
  return await http.post(`${apiEndpoint}/generateTexture/${id}`, body);
}

async function generateHumans(id, body) {
  return await http.post(`${apiEndpoint}/generateHuman/${id}`, body);
}

async function generatePlace(id, body) {
  return await http.post(`${apiEndpoint}/generatePlace/${id}`, body);
}

async function generateConceptImages(id, body) {
  return await http.post(`${apiEndpoint}/generateConceptImages/${id}`, body);
}

// Images With Logo
async function textureWithLogo(id, body) {
  return await http.post(`${apiEndpoint}/AddLogoToTexture/${id}`, body);
}

async function humanWithLogo(id, body) {
  return await http.post(`${apiEndpoint}/addLogoOverHuman/${id}`, body);
}

async function placeWithLogo(id, body) {
  return await http.post(`${apiEndpoint}/placesWithLogo/${id}`, body);
}

async function conceptWithLogo(id, body) {
  return await http.post(`${apiEndpoint}/conceptWithLogo/${id}`, body);
}

async function briefingTryAgin(id, body) {
  return await http.post(`${apiEndpoint}/generateBriefingTryAgain/${id}`, body);
}

async function generateFontsTryAgin(id, body) {
  return await http.post(`${apiEndpoint}/generateFontsTryAgain/${id}`, body);
}

async function generateColorPaletteTryAgain(id, body) {
  return await http.post(`${apiEndpoint}/generateColorTryAgain/${id}`, body);
}

async function generateSymbolLogoAgain(id, body) {
  return await http.post(`${apiEndpoint}/generateSymbolTryAgain/${id}`, body);
}

async function postGenerateTextureTryAgain(id, body) {
  return await http.post(`${apiEndpoint}/generateTextureTryAgain/${id}`, body);
}

async function postGenerateHumanTryAgain(id, body) {
  return await http.post(`${apiEndpoint}/generateHumanTryAgain/${id}`, body);
}

async function postGeneratePlaceTryAgain(id, body) {
  return await http.post(`${apiEndpoint}/generatePlaceTryAgain/${id}`, body);
}

async function postGenerateConceptImagesTryAgain(id, body) {
  return await http.post(
    `${apiEndpoint}/generateConceptImagesTryAgain/${id}`,
    body
  );
}

export default {
  CreateProject,
  uploadImage,
  updateProject,
  generateResult,
  generateSymbol,
  generateFonts,
  generateColor,
  generateBriefing,
  getProjectByUserId,
  getProjectId,
  updateProjectIteration,
  deleteProject,
  generateMockup,
  generateMissionAndVision,
  generatePreSignedUrl,
  generateBook,
  processPdf,

  generateTexture,
  generateHumans,
  generatePlace,
  generateConceptImages,

  textureWithLogo,
  humanWithLogo,
  placeWithLogo,
  conceptWithLogo,

  briefingTryAgin,
  generateFontsTryAgin,
  generateColorPaletteTryAgain,
  generateSymbolLogoAgain,

  //
  postGenerateTextureTryAgain,
  postGenerateHumanTryAgain,
  postGeneratePlaceTryAgain,
  postGenerateConceptImagesTryAgain,
};
