/* eslint-disable import/no-anonymous-default-export */
import Cookies from "universal-cookie";
import http from "./httpService";
import config from "../config";
const { apiUrl } = config;
const apiEndpoint = `${apiUrl}/subscription`;
const cookies = new Cookies();

async function CreateSubscription(body) {
  return await http.post(`${apiEndpoint}`, body);
}
async function CreateCredits(body) {
  return await http.post(`${apiEndpoint}/createCredits`, body);
}
async function addMoreProject(body) {
  return await http.post(`${apiEndpoint}/addMoreProject`, body);
}
async function getPurchaseHistory(user_id) {
  return await http.get(`${apiEndpoint}/getTransaction/${user_id}`);
}
async function getPlan(user_id) {
  return await http.get(`${apiEndpoint}/getPlan/${user_id}`);
}
async function updatePlanProject(body) {
  return await http.put(`${apiEndpoint}/updatePlanProject`, body);
}
async function updateFullPlan(body) {
  return await http.put(`${apiEndpoint}/updateFullPlan`, body);
}
async function getActivePlan(user_id) {
  return await http.get(`${apiEndpoint}/getActivePlanStripe/${user_id}`);
}
async function getActiveProducts() {
  return await http.get(`${apiEndpoint}/getProducts`);
}
async function updatePlanCollaborator(body) {
  return await http.put(`${apiEndpoint}/updatePlanCollaborator`, body);
}
async function getNotifications(body) {
  return await http.post(`${apiEndpoint}/getNotifications`, body);
}

async function postPayment(body) {
  return await http.post(`${apiEndpoint}/updatePayment`, body);
}

async function updateNotification(body) {
  return await http.post(`${apiEndpoint}/updateNotification`, body);
}

export default {
  CreateSubscription,
  CreateCredits,
  getPurchaseHistory,
  getPlan,
  addMoreProject,
  updatePlanProject,
  updateFullPlan,
  getActivePlan,
  updatePlanCollaborator,
  getActiveProducts,
  getNotifications,
  postPayment,
  updateNotification,
};
