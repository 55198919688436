import { toast } from "react-toastify";
import './toastService.css';
function customToast(data, type, lines = 1) {

  if (typeof data === 'object' && data.message) {
    data = data.message;
  } else if (typeof data !== 'string') {
    data = String(data);
  }

  const words = data.split(" ");
  const wordsPerLine = Math.ceil(words.length / lines);
  let linesData = [];

  for (let i = 0; i < lines; i++) {
    linesData.push(words.splice(0, wordsPerLine).join(" "));
  }

  // If the provided lines are more than actual lines, add empty lines
  while (linesData.length < lines) {
    linesData.push("");
  }

  const formattedData = linesData.map((line, idx) => <div key={idx}>{line}</div>);

  toast[type](<>{formattedData}</>, {
    className: `custom-${type}-toast lines-${lines}`,
    icon: false,
    hideProgressBar: true,
    closeButton: false,  // This line will remove the close button
  });
}



function error(data, lines) {
  customToast(data, 'error', lines);
}

function warning(data, lines) {
  customToast(data, 'warning', lines);
}

function success(data, lines) {
  customToast(data, 'success', lines);
}

function info(data, lines) {
  customToast(data, 'info', lines);
}


export default {
  error,
  warning,
  success,
  info
};
