import React, { useEffect } from "react";
import "./Analytics.css";
import { useState } from "react";
import { motion } from "framer-motion";
import { saveActiveButton } from "app/reducers/activeButton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authServices from "services/authServices";
import analyticsService from "services/analyticsService";
import CircularProgress from "@mui/material/CircularProgress";
import toastService from "services/toastService";
import teamServices from "services/teamServices";
const Analytics = () => {
  const [showSuggestionsRole, setShowSuggestionsRole] = useState(false);
  const [valueRole, setValueRole] = useState("");
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleInputChangeRole = (event) => {
    setValueRole(event.target.value);
    setShowSuggestionsRole(true);
  };

  const handleSuggestionClickRole = (suggestion) => {
    setValueRole(suggestion);
    getAnalyticByMonth(suggestion);
    setShowSuggestionsRole(false);
  };

  const getAnalyticByMonth = async (month) => {
    try {
      setLoading(true);
      const response = await analyticsService.getMonthlyAnalytics(user.id, {
        month: month,
      });
      setResult(response.data.data.result);
      const team = await teamServices.getTeamAndMemberByUserId(user.id);
      const teamDataApi = team.data.data.result;
      if (team?.data?.data.result) {
        setTeamData(teamDataApi);
        setLoading(false);
      }
      setLoading(false);
    } catch (ex) {
      toastService.error(ex);
    }
  };

  const handleYellowBoxClickRole = () => {
    setShowSuggestionsRole(!showSuggestionsRole);
  };
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: -100 },
    show: { opacity: 1, y: 0 },
  };
  const suggestionsMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "july",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedCard, setSelectedCard] = useState("pro");

  const filteredsuggestionsMonth = suggestionsMonth.filter((suggestion) =>
    suggestion.toLowerCase().includes(valueRole.toLowerCase())
  );
  const user = authServices.getCurrentUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await analyticsService.getAnalytics(user.id);
        setResult(response.data.data.result);
        const team = await teamServices.getTeamAndMemberByUserId(user.id);
        const teamDataApi = team.data.data.result;
        if (team?.data?.data.result) {
          setTeamData(teamDataApi);
          setLoading(false);
        }
        setLoading(false);
      } catch (ex) {
        toastService.error(ex);
      }
    };

    fetchData();
  }, []);

  function formatValue(value) {
    if (value === 0) {
      return "0";
    }
    try {
      const suffixes = ["", "K", "M", "B", "T"];
      const order = Math.floor(Math.log10(Math.abs(value)) / 3);
      const suffix = suffixes[order];
      const formattedValue = (value / Math.pow(10, order * 3)).toFixed(1);

      return `${formattedValue}`.replace(".0", "") + suffix;
    } catch {}
  }

  return (
    <div className="analyticsContainer">
      {!loading && (
        <div className="LeftSideanalytics">
          <div className="innerAnalyticsBox">
            <span className="analyticsParaBox">Analytics</span>
            <div
              className={`boxme2RoleCountrySettingsAna ${
                showSuggestionsRole ? "show-suggestions2RoleSettingsAna" : ""
              }`}
            >
              <input
                className="inputForRoleBox2RoleSettingsAna"
                type="text"
                value={valueRole}
                onClick={handleYellowBoxClickRole}
                onChange={handleInputChangeRole}
                placeholder="Select Month"
              />
              {valueRole ? (
                <svg
                  onClick={() => {
                    setValueRole("");
                  }}
                  className="search-iconCountryName"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 6L6 18"
                    stroke="#FD5D5D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#FD5D5D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                ""
              )}

              {showSuggestionsRole && filteredsuggestionsMonth.length > 0 && (
                <ul className="suggestion-list2">
                  {filteredsuggestionsMonth.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClickRole(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}

              <div
                className="yellowBox2RoleSettingsAna"
                onClick={handleYellowBoxClickRole}
              >
                <svg
                  onClick={handleYellowBoxClickRole}
                  className={`arrow-icon ${
                    showSuggestionsRole ? "up-arrow" : "down-arrow"
                  }`}
                  width="22"
                  height="14"
                  viewBox="0 0 22 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="show"
            className="anylyticsCards"
          >
            <motion.div variants={cardVariants} className="anylyticsCard">
              <p className="totalProjectParabox">
                <svg
                  className="totalProjectParaboxSvg"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 18.75C19.8325 18.75 23.75 14.8325 23.75 10C23.75 5.16751 19.8325 1.25 15 1.25C10.1675 1.25 6.25 5.16751 6.25 10C6.25 14.8325 10.1675 18.75 15 18.75Z"
                    stroke="#F5FF01"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.2625 17.3621L8.75 28.7496L15 24.9996L21.25 28.7496L19.7375 17.3496"
                    stroke="#F5FF01"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Total projects
              </p>
              <span className="TotalProjecTnumber">
                {result?.totalProject || 0}
              </span>
              <span className="AverageIteration">
                Average iterations/project:{" "}
                {(result?.averageIteration !== null &&
                  result?.averageIteration.toFixed(1)) ||
                  0}
                <br />
                <span className="AverageIterationspan">Members</span>
              </span>

              <div className="projectsAvatarBoxdivAna">
                {teamData?.member.map((member) => {
                  return member.image ? (
                    <img
                      style={{ marginLeft: "0px" }}
                      className="projectsAvatarBoxdivcircle"
                      src={member.image}
                      alt="member"
                      key={member._id}
                    />
                  ) : (
                    <div
                      style={{ marginLeft: "0px" }}
                      className="projectsAvatarBoxdivcircle"
                      key={member._id}
                    ></div>
                  );
                })}
              </div>
            </motion.div>

            <motion.div variants={cardVariants} className="anylyticsCard2">
              <div className="cards2ndBoxana">
                <svg
                  className="totalProjectParaboxSvg"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4697 1.25V28.75"
                    stroke="#F5FF01"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.7197 6.25H12.3447C11.1844 6.25 10.0716 6.71094 9.25113 7.53141C8.43066 8.35188 7.96973 9.46468 7.96973 10.625C7.96973 11.7853 8.43066 12.8981 9.25113 13.7186C10.0716 14.5391 11.1844 15 12.3447 15H18.5947C19.755 15 20.8678 15.4609 21.6883 16.2814C22.5088 17.1019 22.9697 18.2147 22.9697 19.375C22.9697 20.5353 22.5088 21.6481 21.6883 22.4686C20.8678 23.2891 19.755 23.75 18.5947 23.75H7.96973"
                    stroke="#F5FF01"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p className="totalProjectParabox2">
                  Market value in USD
                  <br />
                  <span className="totalProjectParaboxspan">
                    (based on industry standards)
                  </span>
                </p>
              </div>

              <span className="TotalProjecTnumber2">
                {formatValue(result?.market_value) || 0}
              </span>
              <span className="AverageIteration2">Last update 23.05.4</span>
            </motion.div>
          </motion.div>

          <div className="dottedLineanalytics"></div>
          {result?.industryAverage[1] && (
            <>
              <div className="RevenueBasePara">
                Revenue based on industry average
              </div>
              <div className="pricingCardBox">
                <div className="firstDescriptionCardPricing">
                  <div className="dottedLinepricing"></div>
                  <div className="YourCostPara">
                    Your cost
                    <br />
                    <span className="YourCostParaspan">
                      (based on the total projects amount)
                    </span>
                  </div>
                  <div className="dottedLinepricing2"></div>
                  <div className="YourCostParamarket">
                    Market value
                    <br />
                    <span className="YourCostParaspan">
                      (based on the total projects amount and industry standard
                      rates)
                    </span>
                  </div>
                  <div className="dottedLinepricing2"></div>
                  <div className="YourCostParatotal">
                    Estimated total revenue
                  </div>
                  <div className="dottedLinepricing2"></div>
                </div>

                <div
                  className={`firstDescriptionCardPricing ${
                    selectedCard === "starter" ? "selected" : ""
                  }`}
                >
                  <p className="starterPlan">
                    {result.industryAverage[0].plan_name} Plan
                  </p>
                  <div className="dottedLinepricing2ndcard"></div>
                  <div className="YourCostPara2ndcard">
                    ${result.industryAverage[0].cost}
                  </div>
                  <div className="dottedLinepricing2"></div>
                  <div className="YourPara2ndcard2">
                    ${result.industryAverage[0].market_value}
                  </div>
                  <div className="dottedLinepricing2"></div>
                  <div className="YourCostPara2ndcard3">
                    $
                    {result.industryAverage[0].total_revenue < 0
                      ? 0
                      : result.industryAverage[0].total_revenue}
                  </div>
                  <div className="dottedLinepricing2"></div>
                </div>
                {result?.industryAverage[1] && (
                  <div
                    className={`firstDescriptionCardPricing ${
                      selectedCard === "pro" ? "selected" : ""
                    }`}
                  >
                    <p className="starterPlan">
                      {result?.industryAverage[1].plan_name} Plan
                    </p>
                    <div className="dottedLinepricing2ndcard"></div>
                    <div className="YourCostPara2ndcard">
                      ${result?.industryAverage[1].cost}
                    </div>
                    <div className="dottedLinepricing2"></div>
                    <div className="YourPara2ndcard2">
                      ${result?.industryAverage[1].market_value}
                    </div>
                    <div className="dottedLinepricing2"></div>
                    <div className="YourCostPara2ndcard3yellow">
                      $
                      {result.industryAverage[1].total_revenue < 0
                        ? 0
                        : result.industryAverage[1].total_revenue}
                    </div>
                    <div className="dottedLinepricing2"></div>
                    <button
                      className="UpgradePlanBtns"
                      onClick={() => {
                        dispatch(saveActiveButton("Billing"));
                        navigate("/billing");
                      }}
                    >
                      <svg
                        className="UpgradePlanBtnsvgg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Upgrade plan
                    </button>
                    {result?.industryAverage[1] && (
                      <div className="accordingPara">
                        According to the work of other users the plan you may be
                        interested in is {result?.industryAverage[1].plan_name}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {loading && (
        <div className="LeftSideanalyticsLoading">
          <CircularProgress color="inherit" />
        </div>
      )}

      <div className="RightSideanalytics">
        <span className="AnalyticsUsPara">My settings</span>
        <button
          className="mailanalytics"
          onClick={() => {
            dispatch(saveActiveButton("Setting"));
            navigate("/settings");
          }}
        >
          <svg
            className="SettingsFAQsvgana"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5 3.00023C18.8978 2.6024 19.4374 2.37891 20 2.37891C20.5626 2.37891 21.1022 2.6024 21.5 3.00023C21.8978 3.39805 22.1213 3.93762 22.1213 4.50023C22.1213 5.06284 21.8978 5.6024 21.5 6.00023L12 15.5002L8 16.5002L9 12.5002L18.5 3.00023Z"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Profile
        </button>
        <button
          className="mailanalytics"
          onClick={() => {
            dispatch(saveActiveButton("Billing"));
            navigate("/billing");
          }}
        >
          <svg
            className="SettingsFAQsvgana"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 21.5V19.5C17 18.4391 16.5786 17.4217 15.8284 16.6716C15.0783 15.9214 14.0609 15.5 13 15.5H5C3.93913 15.5 2.92172 15.9214 2.17157 16.6716C1.42143 17.4217 1 18.4391 1 19.5V21.5"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23 21.4999V19.4999C22.9993 18.6136 22.7044 17.7527 22.1614 17.0522C21.6184 16.3517 20.8581 15.8515 20 15.6299"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 3.62988C16.8604 3.85018 17.623 4.35058 18.1676 5.05219C18.7122 5.7538 19.0078 6.61671 19.0078 7.50488C19.0078 8.39305 18.7122 9.25596 18.1676 9.95757C17.623 10.6592 16.8604 11.1596 16 11.3799"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          My plan
        </button>
        <button
          className="mailanalytics"
          onClick={() => {
            dispatch(saveActiveButton("Team"));
            navigate("/manageteam");
          }}
        >
          <svg
            className="SettingsFAQsvgana"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.08984 9.49959C9.32495 8.83126 9.789 8.2677 10.3998 7.90873C11.0106 7.54975 11.7287 7.41853 12.427 7.5383C13.1253 7.65808 13.7587 8.02112 14.2149 8.56312C14.6712 9.10512 14.9209 9.79112 14.9198 10.4996C14.9198 12.4996 11.9198 13.4996 11.9198 13.4996"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 17.5H12.01"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Team
        </button>
        <button
          className="mailanalytics"
          onClick={() => {
            dispatch(saveActiveButton("Analytics"));
            navigate("/analytics");
          }}
        >
          <svg
            className="SettingsFAQsvgana"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.08984 9.49959C9.32495 8.83126 9.789 8.2677 10.3998 7.90873C11.0106 7.54975 11.7287 7.41853 12.427 7.5383C13.1253 7.65808 13.7587 8.02112 14.2149 8.56312C14.6712 9.10512 14.9209 9.79112 14.9198 10.4996C14.9198 12.4996 11.9198 13.4996 11.9198 13.4996"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 17.5H12.01"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Analytics
        </button>
        <button
          className="UpgradeBtnsAnaly"
          onClick={() => {
            dispatch(saveActiveButton("Billing"));
            navigate("/billing");
          }}
        >
          <svg
            className="SettingsFAQsvgana"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1290_2574)">
              <path
                d="M9 22.5C9.55228 22.5 10 22.0523 10 21.5C10 20.9477 9.55228 20.5 9 20.5C8.44772 20.5 8 20.9477 8 21.5C8 22.0523 8.44772 22.5 9 22.5Z"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 22.5C20.5523 22.5 21 22.0523 21 21.5C21 20.9477 20.5523 20.5 20 20.5C19.4477 20.5 19 20.9477 19 21.5C19 22.0523 19.4477 22.5 20 22.5Z"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1.5H5L7.68 14.89C7.77144 15.3504 8.02191 15.764 8.38755 16.0583C8.75318 16.3526 9.2107 16.509 9.68 16.5H19.4C19.8693 16.509 20.3268 16.3526 20.6925 16.0583C21.0581 15.764 21.3086 15.3504 21.4 14.89L23 6.5H6"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1290_2574">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          Upgrade
        </button>
        <img className="SettingsImagePageana" src="DataSettings.png" alt="" />
      </div>
    </div>
  );
};

export default Analytics;
