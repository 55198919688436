import React, { useEffect, useState, useCallback } from "react";
import "./Symbolism.css";
import RightBar from "views/dashboard/RightBar";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import projectServices from "services/projectServices";
import {
  getProjectById,
  selectedIteration,
  updateCompleteProject,
  updateProjectProperty,
} from "app/reducers/projectEditSlice";
import Loading from "../magic/loading";
import { EditItration } from "app/reducers/EditItration";

// Components
import TryagainModal from "components/TryAgainModal/TryagainModal";

const Symbolism = () => {
  const dispatch = useDispatch();
  const SelectedProjectId = useSelector(
    (state) => state?.projectEdit?.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state?.projectEdit?.selectedIterationId
  );
  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );
  let projectRecordData = useSelector(
    (state) => state?.projectEdit?.singleProject
  );

  const formattedText = projectRecordData?.gptLogoConcept
    ?.split("\n")
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

  const symbol = projectRecordData?.symbol;
  const shapes = symbol;
  const [loading, setLoading] = useState(false);
  const [toggleTryAgain, settoggleTryAgain] = useState(false);
  const [tryagainText, settryagainText] = useState(false);
  const [liketext, setliketext] = useState({
    dontLike: "",
    extraInstruction: "",
  });
  const [selectedShape, setSelectedShape] = useState(
    (projectRecordData && projectRecordData?.selected_symbol) || symbol?.[0]
  );
  const itration = useSelector((state) => state?.EditItrationNo?.itration);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };
  const cardVariants = {
    hidden: { opacity: 0, x: -200, scale: 0.5 },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  function estimateTextWidth(textContent) {
    const span = document.createElement("span");
    span.textContent = textContent;

    // Define the style element
    const style = document.createElement("style");

    // Create the @font-face style rule based on the selected font provider
    if (projectRecordData?.selected_font_provider === "pangram pangram") {
      const fontWeight = projectRecordData?.selected_weight; // Assuming weight is something like "Bold", "Regular", etc.
      style.textContent = `
        @font-face {
          font-family: "${projectRecordData?.selected_fonts}-${fontWeight}";
          src: url("https://brandfy-dashboard.s3.ap-south-1.amazonaws.com/Updated_Fonts/${projectRecordData?.selected_fonts}/${projectRecordData?.selected_fonts}-${fontWeight}.ttf") format("truetype");
        }
      `;
      span.style.fontFamily = `"${projectRecordData?.selected_fonts}-${fontWeight}"`;
    } else {
      // For Google fonts, just use the font family name as it will be loaded from Google Fonts in the HTML or CSS
      span.style.fontFamily = `"${projectRecordData?.selected_fonts}"`;
      span.style.fontStyle = projectRecordData?.weight; // Assuming Google Fonts would recognize the weight
    }

    // Append the style to the document head
    document.head.appendChild(style);

    const n = textContent?.length;
    let fontSize = 5;
    if (n <= 4) {
      fontSize = 7;
    }
    if (n <= 5) {
      fontSize = 6.7;
    }
    if (n <= 6) {
      fontSize = 6.5;
    }
    if (n <= 7) {
      fontSize = 6.3;
    } else if (n <= 8) {
      fontSize = 6;
    } else if (n <= 9) {
      fontSize = 5.7;
    } else if (n <= 10) {
      fontSize = 5.5;
    } else if (n <= 11) {
      fontSize = 5.3;
    } else if (n <= 12) {
      fontSize = 5;
    } else if (n <= 13) {
      fontSize = 4.7;
    } else if (n <= 14) {
      fontSize = 4.5;
    } else if (n <= 15) {
      fontSize = 4.2;
    } else if (n <= 16) {
      fontSize = 4;
    } else if (n <= 17) {
      fontSize = 3.7;
    } else if (n <= 18) {
      fontSize = 3.5;
    } else if (n <= 19) {
      fontSize = 3.2;
    } else if (n <= 20) {
      fontSize = 3;
    } else if (n <= 21) {
      fontSize = 2.7;
    } else if (n <= 22) {
      fontSize = 2.5;
    } else if (n <= 23) {
      fontSize = 2.2;
    } else if (n <= 24) {
      fontSize = 2;
    } else if (n <= 25) {
      fontSize = 1.7;
    } else if (n <= 26) {
      fontSize = 1.5;
    } else if (n <= 27) {
      fontSize = 1.2;
    } else if (n <= 28) {
      fontSize = 1;
    } else {
      fontSize = 1;
    }

    span.style.fontSize = `${(fontSize * 19.2).toString()}px`;
    span.style.whiteSpace = "nowrap";

    // Append span to body to measure it
    document.body.appendChild(span);
    const width = span.offsetWidth;
    const height = span.offsetHeight;

    // Clean up: remove span and style from the document
    document.body.removeChild(span);
    document.head.removeChild(style);

    // Further calculations
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth;
    const Font = (viewportWidth * fontSize) / 100;
    return { width, height, Font };
  }

  const handelSubmit = async () => {
    try {
      setLoading(true);
      const body = {
        selected_symbol: selectedShape,
        iteration_id: SelectedIterationId,
        iterate_step: 5,
      };
      const res = await projectServices?.updateProject(SelectedProjectId, body);
      const estimatedWidth = estimateTextWidth(
        projectRecordData.name,
        projectRecordData.fonts[0]
      );

      const body1 = {
        ...res?.data?.data?.result,
        estimatedWidth: estimatedWidth?.width,
        estimatedHeight: estimatedWidth?.height,
        estimatedFont: estimatedWidth?.Font,
        iteration_id: SelectedIterationId,
      };
      if (itration) {
        const result = await projectServices?.generateResult(
          SelectedProjectId,
          {
            ...body1,
            iterate_step: 6,
            iteration_id: SelectedIterationId,
          }
        );
        const matchedIteration = result?.data?.data?.result?.iteration.find(
          (iter) => iter._id === SelectedIterationId
        );
        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;
        const projectData = await projectServices.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 6 }
        );

        const sortedIterations =
          projectData?.data?.data?.result?.iteration.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

        dispatch(updateCompleteProject(projectData?.data?.data?.result));
        dispatch(selectedIteration(sortedIterations?.[0]?._id));
        dispatch(EditItration(false));
        navigate("/symbolismlogo");
        setLoading(false);
      } else {
        const result = await projectServices?.generateResult(
          SelectedProjectId,
          {
            ...body1,
            iterate_step: 6,
            iteration_id: SelectedIterationId,
          }
        );
        dispatch(updateCompleteProject(result.data.data.result));
        const index =
          res.data.data.result.iteration.length > 0
            ? res.data.data.result.iteration[
                res.data.data.result.iteration.length - 1
              ]
            : 0;
        dispatch(selectedIteration(index._id));
        setLoading(false);
        navigate("/symbolismlogo");
      }
    } catch (ex) {
      setLoading(false);
    }
  };

  const handleChangeLikeText = useCallback(
    (name) => (evt) => {
      setliketext((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );

  const handelTryAgain = async (type) => {
    try {
      setLoading(true);
      settryagainText(true);
      let formData = {
        ...projectRecordData,
        iterate_step: 5,
        iteration_id: SelectedIterationId,
      };
      if (type === "like") {
        formData = {
          ...formData,
          dontLike: liketext?.dontLike,
        };
      } else if (type === "instruction") {
        formData = {
          ...formData,
          extraInstruction: liketext?.extraInstruction,
        };
      }
      const res = await projectServices.generateSymbolLogoAgain(
        SelectedProjectId,
        formData
      );

      if (itration) {
        const matchedIteration = res?.data?.data?.result?.iteration?.find(
          (iter) => iter?._id === SelectedIterationId
        );

        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;
        const projectData = await projectServices?.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 6 }
        );
        const sortedIterations =
          projectData?.data?.data?.result?.iteration?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
        dispatch(updateCompleteProject(projectData?.data?.data?.result));
        dispatch(selectedIteration(sortedIterations?.[0]?._id));
        dispatch(EditItration(false));
        setLoading(false);
        settoggleTryAgain(false);
        settryagainText(false);
        setliketext({
          ...liketext,
          dontLike: "",
          extraInstruction: "",
        });
      } else {
        const index =
          res.data.data.result.iteration.length > 0
            ? res.data.data.result.iteration[
                res.data.data.result.iteration.length - 1
              ]
            : 0;
        dispatch(updateCompleteProject(res.data.data.result));
        dispatch(selectedIteration(index._id));
        dispatch(
          updateProjectProperty({
            _id: SelectedProjectId,
            key: "selected_symbol",
            value: res?.data?.data?.result?.symbol?.[0],
          })
        );
        setSelectedShape(res?.data?.data?.result?.symbol[0] || symbol[0]);
        setLoading(false);
        settoggleTryAgain(false);
        settryagainText(false);
      }
    } catch {
      setLoading(false);
      settoggleTryAgain(false);
      settryagainText(false);
    }
  };

  const selectShape = (shape) => {
    dispatch(
      updateProjectProperty({
        _id: projectRecordData?._id,
        key: "selected_symbol",
        value: shape,
      })
    );
    setSelectedShape(shape);
  };

  const navigate = useNavigate();
  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    function timeSince(date) {
      const now = new Date();
      const secondsPast = (now.getTime() - date.getTime()) / 1000;

      if (secondsPast < 60) {
        return `${Math.round(secondsPast)} seconds ago`;
      }
      if (secondsPast < 3600) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
      }
      if (secondsPast <= 86400) {
        return `${Math.round(secondsPast / 3600)} hours ago`;
      }
      if (secondsPast > 86400) {
        const daysPast = Math.round(secondsPast / 86400);
        return `${daysPast} days ago`;
      }
    }

    if (projectRecordData?.updatedAt) {
      const lastUpdatedDate = new Date(projectRecordData.updatedAt);
      setTimeDifference(timeSince(lastUpdatedDate));
    }

    const intervalId = setInterval(() => {
      if (projectRecordData?.updatedAt) {
        const lastUpdatedDate = new Date(projectRecordData.updatedAt);
        setTimeDifference(timeSince(lastUpdatedDate));
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [projectRecordData]);

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading tryagainText={tryagainText} />
        </div>
      ) : (
        <div className="designsymbolismContainer">
          <div className="leftBriefingBox1">
            <div className="timelineBoxsymbol">
              <div className="timelinesymbol">
                <div className="RemoveTextSymbol"></div>
                <div className="RemoveTextSymbol"></div>
                <div className="RemoveTextSymbol"></div>
                <div className="RemoveTextSymbol"></div>
                <div className="RemoveTextSymbol"></div>
                <div className="RemoveTextSymbol"></div>
              </div>
            </div>
            <div className="projectDesignsymbolBox">
              <p className="DesignsymbolPara">Design: Symbolism</p>
              <p className="DesignsymbollastSaved">
                Last saved iteration: {timeDifference}
              </p>
            </div>

            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="show"
              className="DesignsymbolDesignBox"
            >
              <p className="symbolsPara">
                Here are the symbols we have created for your brand:
                <br />
                <span className="symbolsParaspan">
                  Select the symbol that best fits your brand
                </span>
              </p>

              <div className="SymbolismBox">
                {shapes?.map((shape, index) => {
                  return (
                    <motion.div
                      variants={cardVariants}
                      key={index}
                      className={`outdivshape ${
                        shape === selectedShape ? "selected" : ""
                      }`}
                      onClick={() => selectShape(shape)}
                    >
                      <div className="formsymbol">
                        <img
                          alt=""
                          style={{ height: "100%" }}
                          src={shape}
                          loading="lazy"
                        />
                      </div>
                    </motion.div>
                  );
                })}

                <div className="gpt_generate_text">
                  <p className="conceptPara2">{formattedText}</p>
                </div>
              </div>

              <div className="btnsDivBoxx">
                <button
                  onClick={() => {
                    navigate("/colorpalette");
                  }}
                  className="GoBackBtnInsymbol"
                >
                  <span style={{ display: "block" }}>Go back</span>
                </button>
                <div className="btnsDivBoxxInner">
                  <button
                    className="TryAgainBtnInsymbol"
                    onClick={() => settoggleTryAgain(true)}
                  >
                    <span style={{ display: "block" }}>Try again</span>
                  </button>
                  <button
                    onClick={() => {
                      handelSubmit();
                      window.scrollTo(0, 0);
                    }}
                    className="NextStepBtnInsymbol"
                  >
                    <span style={{ display: "block" }}>Next step</span>
                  </button>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}

      {toggleTryAgain && (
        <TryagainModal
          settoggleTryAgain={settoggleTryAgain}
          handleSubmit={handelTryAgain}
          handleChangeLikeText={handleChangeLikeText}
          liketext={liketext}
          setliketext={setliketext}
        />
      )}
    </>
  );
};

export default Symbolism;
