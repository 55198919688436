import React, { useState } from "react";
import "./Style.css";

const TryagainModal = ({
  handleSubmit,
  settoggleTryAgain,
  liketext,
  handleChangeLikeText,
  setliketext,
}) => {
  const [showLikeModal, setshowLikeModal] = useState(false);
  const [showInstructions, setshowInstructions] = useState(false);
  const [selectedButton, setSelectedButton] = useState("first");

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const handleClose = () => {
    settoggleTryAgain(false);
    setliketext({
      ...liketext,
      dontLike: "",
      extraInstruction: "",
    });
  };

  return (
    <React.Fragment>
      <div className="assign_project">
        <div style={{ width: 500 }} className="modal_box border-none">
          <div style={{ textAlign: "left" }} className="areYouSure">
            Try again
          </div>
          <hr />
          <p className="allprojectItration"></p>
          <div className="try_againwrap">
            <button
              className={`confirm_perposal ${
                selectedButton === "first" ? "selectbutton" : ""
              }`}
              onClick={() => handleButtonClick("first")}
            >
              I just want another proposal
            </button>

            <>
              <button
                onClick={() => {
                  setshowLikeModal(!showLikeModal);
                  setshowInstructions(false);
                  handleButtonClick("second");
                }}
                className={`confirm_perposal ${
                  selectedButton === "second" ? "selectbutton" : ""
                }`}
              >
                I have seen something I don't like
              </button>

              {showLikeModal && (
                <div className="">
                  <textarea
                    type="text"
                    className="describeInputchange"
                    name="primary_competitor"
                    placeholder="Specify what you don’t like"
                    value={liketext.dontLike}
                    onChange={handleChangeLikeText("dontLike")}
                  />
                </div>
              )}
            </>

            <>
              <button
                onClick={() => {
                  setshowInstructions(!showInstructions);
                  setshowLikeModal(false);
                  handleButtonClick("third");
                }}
                className={`confirm_perposal ${
                  selectedButton === "third" ? "selectbutton" : ""
                }`}
              >
                Do you want to add any extra instruction?
              </button>

              {showInstructions && (
                <div className="">
                  <textarea
                    type="text"
                    className="describeInputchange"
                    name="primary_competitor"
                    placeholder="Instruction"
                    value={liketext?.extraInstruction}
                    onChange={handleChangeLikeText("extraInstruction")}
                  />
                </div>
              )}
            </>
          </div>

          <div className="modal_close">
            <button onClick={handleClose} className="closeCacel_btn">
              Cancel
            </button>

            {showLikeModal ? (
              <button
                onClick={() => handleSubmit("like")}
                className="ok_likebtn gap-1"
              >
                Confirm
              </button>
            ) : showInstructions ? (
              <button
                onClick={() => handleSubmit("instruction")}
                className="ok_likebtn gap-1"
              >
                Confirm
              </button>
            ) : (
              <button
                onClick={() => handleSubmit()}
                className="ok_likebtn gap-1"
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TryagainModal;
