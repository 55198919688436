// reducers/projectRecordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    _id: "",
    fullLogo: "",
    fulColorLogo1: "",
    fulColorLogo2: "",
    updatedAt: "",
};

const resultSlice = createSlice({
    name: 'result',
    initialState,
    reducers: {
        saveResult: (state, action) => {
            return action.payload;
        },
    },
});

export const { saveResult } = resultSlice.actions;
export default resultSlice.reducer;