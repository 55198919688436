import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

// CSS
import "./ColorPalette.css";

// API Servicess
import projectServices from "services/projectServices";

// Reducers
import {
  getProjectById,
  selectedIteration,
  updateCompleteProject,
  updateProjectProperty,
} from "app/reducers/projectEditSlice";
import { EditItration } from "app/reducers/EditItration";

// Components
import Loading from "../magic/loading";
import RightBar from "views/dashboard/RightBar";
import TryagainModal from "components/TryAgainModal/TryagainModal";

const ColorPalette = () => {
  const dispatch = useDispatch();
  const SelectedProjectId = useSelector(
    (state) => state?.projectEdit?.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state?.projectEdit?.selectedIterationId
  );

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );
  let projectRecordData = useSelector(
    (state) => state?.projectEdit?.singleProject
  );
  const [loading, setLoading] = useState(false);
  const [tryagainText, settryagainText] = useState(false);
  const [toggleTryAgain, settoggleTryAgain] = useState(false);
  const [liketext, setliketext] = useState({
    dontLike: "",
    extraInstruction: "",
  });
  const [selectedColor, setSelectedColor] = useState(
    projectRecordData?.color_palette?.main[0]?.Hex
  );
  const GptColor = projectRecordData?.color_palette;
  const itration = useSelector((state) => state?.EditItrationNo?.itration);

  const getTextColor = (() => {
    const memo = {};
    return (hexColor) => {
      if (memo[hexColor]) {
        return memo[hexColor];
      }
      const hex = hexColor?.replace(/^#/, "") || "000000";
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      const result = luminance > 128 ? "text-black" : "text-white";
      memo[hexColor] = result;
      return result;
    };
  })();

  const primarySection = GptColor?.main?.[0]?.Hex;
  const textColorClass = getTextColor(primarySection);

  const handelSubmit = async () => {
    try {
      setLoading(true);
      const res = await projectServices?.updateProject(SelectedProjectId, {
        selected_color_palette:
          projectRecordData?.color_palette?.main?.[0]?.Hex,
        iteration_id: SelectedIterationId,
        iterate_step: 4,
      });

      if (itration) {
        const resultSymbol = await projectServices.generateSymbol(
          res?.data?.data?.result?._id,
          {
            ...res?.data?.data?.result,
            iterate_step: 5,
            iteration_id: SelectedIterationId,
          }
        );
        const matchedIteration =
          resultSymbol?.data?.data?.result?.iteration?.find(
            (iter) => iter._id === SelectedIterationId
          );
        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;

        const projectData = await projectServices?.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 5 }
        );

        const sortedIterations =
          projectData?.data?.data?.result?.iteration.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
        dispatch(updateCompleteProject(projectData?.data?.data?.result));
        dispatch(selectedIteration(sortedIterations?.[0]?._id));
        dispatch(EditItration(false));
        navigate("/symbolism");
        setLoading(false);
      } else {
        const resultSymbol = await projectServices?.generateSymbol(
          res?.data?.data?.result?._id,
          {
            ...res?.data?.data?.result,
            iterate_step: 5,
            iteration_id: SelectedIterationId,
          }
        );
        dispatch(updateCompleteProject(resultSymbol?.data?.data?.result));
        const index=resultSymbol?.data?.data?.result.iteration.length>0?resultSymbol?.data?.data?.result.iteration[resultSymbol?.data?.data?.result.iteration.length-1]:0
        dispatch(selectedIteration(index._id));
        dispatch(
          updateProjectProperty({
            _id: SelectedProjectId,
            key: "updatedAt",
            value: resultSymbol?.data?.data?.result?.updatedAt,
          })
        );
        navigate("/symbolism");
        setLoading(false);
      }
    } catch (ex) {
      setLoading(false);
    }
  };

  const handleChangeLikeText = useCallback(
    (name) => (evt) => {
      setliketext((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );

  const handelTryAgain = async (type) => {
    try {
      setLoading(true);
      settryagainText(true);
      let formData = {
        ...projectRecordData,
        iterate_step: 4,
        iteration_id: SelectedIterationId,
      };
      if (type === "like") {
        formData = {
          ...formData,
          dontLike: liketext?.dontLike,
        };
      } else if (type === "instruction") {
        formData = {
          ...formData,
          extraInstruction: liketext?.extraInstruction,
        };
      }
      const res = await projectServices.generateColorPaletteTryAgain(
        SelectedProjectId,
        formData
      );

      if (itration) {
        const matchedIteration = res?.data?.data?.result?.iteration?.find(
          (iter) => iter?._id === SelectedIterationId
        );

        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;
        const projectData = await projectServices?.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 4 }
        );
        const sortedIterations =
          projectData?.data?.data?.result?.iteration?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
        dispatch(updateCompleteProject(projectData?.data?.data?.result));
        dispatch(selectedIteration(sortedIterations?.[0]?._id));
        dispatch(EditItration(false));
        setLoading(false);
        settoggleTryAgain(false);
        settryagainText(false);
        setliketext({
          ...liketext,
          dontLike: "",
          extraInstruction: "",
        });
      } else {
        dispatch(
          updateProjectProperty({
            _id: SelectedProjectId,
            key: "selected_color_palette",
            value: res?.data?.data?.result?.color_palette?.main?.[0]?.Hex,
          })
        );
        setSelectedColor(
          res?.data?.data?.result?.color_palette?.main?.[0]?.Hex
        );
        const index=res.data.data.result.iteration.length>0?res.data.data.result.iteration[res.data.data.result.iteration.length-1]:0
        dispatch(updateCompleteProject(res.data.data.result));
        dispatch(selectedIteration(index._id));
        setLoading(false);
        settoggleTryAgain(false);
        settryagainText(false);
      }
    } catch {
      setLoading(false);
      settoggleTryAgain(false);
      settryagainText(false);
    }
  };

  const renderDivPair = (id, color) => (
    <div className="ColorDivOuter">
      <div className="colorDivInner" style={{ background: color.Hex }}>
        <div style={{ padding: "10px" }} className="colorInfo">
          <p className={`${getTextColor(color?.Hex)}`}>
            Secondary Colour {Number(id) + 1}
          </p>

          <div className="colorInfoDetails">
            <span className={`${getTextColor(color?.Hex)}`}>cmyk</span>
            <span className={`${getTextColor(color?.Hex)}`}>
              {color?.CMYK?.[0] +
                " " +
                color?.CMYK?.[1] +
                " " +
                color?.CMYK?.[2] +
                " " +
                color?.CMYK?.[3]}
            </span>
          </div>
          <div className="colorInfoDetails">
            <span className={`${getTextColor(color?.Hex)}`}>rgb</span>
            <span className={`${getTextColor(color?.Hex)}`}>
              {color?.RGB?.[0] + " " + color?.RGB?.[1] + " " + color?.RGB?.[2]}
            </span>
          </div>
          <div className="colorInfoDetails">
            <span className={`${getTextColor(color?.Hex)}`}>HEX</span>
            <span className={`${getTextColor(color?.Hex)}`}>{color?.Hex}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderDivPairTertiary = (id, color) => (
    <div className="ColorDivOuter">
      <div className="colorDivInner1" style={{ background: color.Hex }}>
        <div style={{ padding: "10px" }} className="colorInfoT">
          <p className={`${getTextColor(color?.Hex)}`}>
            Third colour {Number(id) + 1}
          </p>
          <div className="colorInfoTDetails">
            <span className={`${getTextColor(color?.Hex)}`}>cmyk</span>
            <span className={`${getTextColor(color?.Hex)}`}>
              {color?.CMYK?.[0] +
                " " +
                color?.CMYK?.[1] +
                " " +
                color?.CMYK?.[2] +
                " " +
                color?.CMYK?.[3]}
            </span>
          </div>
          <div className="colorInfoTDetails">
            <span className={`${getTextColor(color?.Hex)}`}>rgb</span>
            <span className={`${getTextColor(color?.Hex)}`}>
              {color?.RGB?.[0] + " " + color?.RGB?.[1] + " " + color?.RGB?.[2]}
            </span>
          </div>
          <div className="colorInfoTDetails">
            <span className={`${getTextColor(color?.Hex)}`}>HEX</span>
            <span className={`${getTextColor(color?.Hex)}`}>{color?.Hex}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, x: -200, scale: 0.5 },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };
  const navigate = useNavigate();
  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    function timeSince(date) {
      const now = new Date();
      const secondsPast = (now.getTime() - date.getTime()) / 1000;

      if (secondsPast < 60) {
        return `${Math.round(secondsPast)} seconds ago`;
      }
      if (secondsPast < 3600) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
      }
      if (secondsPast <= 86400) {
        return `${Math.round(secondsPast / 3600)} hours ago`;
      }
      if (secondsPast > 86400) {
        const daysPast = Math.round(secondsPast / 86400);
        return `${daysPast} days ago`;
      }
    }

    if (projectRecordData?.updatedAt) {
      const lastUpdatedDate = new Date(projectRecordData.updatedAt);
      setTimeDifference(timeSince(lastUpdatedDate));
    }
    const intervalId = setInterval(() => {
      if (projectRecordData?.updatedAt) {
        const lastUpdatedDate = new Date(projectRecordData.updatedAt);
        setTimeDifference(timeSince(lastUpdatedDate));
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [projectRecordData]);

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading tryagainText={tryagainText} />
        </div>
      ) : (
        <div className="designdesignColorContainer">
          <div className="leftBriefingBox1">
            <div className="timelineBoxColor">
              <div className="timelineColor">
                <div className="connectorColor"></div>
                <div className="connectorColor"></div>
                <div className="connectorColor"></div>
                <div className="connectorColor"></div>
                <div className="connectorColor"></div>
                <div className="connectorColor"></div>
              </div>
            </div>
            <div className="projectDesignColorBox">
              <p className="DesignColorPara">Design: Color Palette</p>
              <p className="lastSaved">
                Last saved iteration: {timeDifference}
              </p>
            </div>
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="show"
              className="DesignColorDesignBox"
            >
              <p className="commonlyPara">
                Here are the colours we have selected for your brand:
              </p>

              <motion.div
                variants={cardVariants}
                style={{ backgroundColor: GptColor?.main?.[0]?.Hex }}
                // className="mainColor"
                className={`mainColor ${getTextColor(
                  GptColor?.main?.[0]?.Hex
                )}`}
              >
                <div className="colorInfo">
                  <p className={`${textColorClass}`}>Primary colour</p>

                  <div className="colorInfoDetails">
                    <span className={`${textColorClass}`}>cmyk</span>
                    <span className={`${textColorClass}`}>
                      {GptColor?.main?.[0]?.CMYK?.[0] +
                        " " +
                        GptColor?.main?.[0]?.CMYK?.[1] +
                        " " +
                        GptColor?.main?.[0]?.CMYK?.[2] +
                        " " +
                        GptColor?.main?.[0]?.CMYK?.[3]}
                    </span>
                  </div>
                  <div className="colorInfoDetails">
                    <span className={`${textColorClass}`}>rgb</span>
                    <span className={`${textColorClass}`}>
                      {GptColor?.main?.[0]?.RGB?.[0] +
                        " " +
                        GptColor?.main?.[0]?.RGB?.[1] +
                        " " +
                        GptColor?.main?.[0]?.RGB?.[2]}
                    </span>
                  </div>

                  <div className="colorInfoDetails">
                    <span className={`${textColorClass}`}>HEX</span>
                    <span className={`${textColorClass}`}>
                      {GptColor?.main?.[0]?.Hex}
                    </span>
                  </div>
                </div>
              </motion.div>

              <motion.div
                variants={cardVariants}
                className="colorBoxDiv second-row"
              >
                {GptColor?.secondary?.map((color, index) => {
                  return <>{renderDivPair(`${index}`, color)}</>;
                })}
              </motion.div>

              <motion.div
                variants={cardVariants}
                className="colorBoxDiv1 third-row"
              >
                {GptColor?.tertiary.map((color, index) =>
                  renderDivPairTertiary(`${index}`, color)
                )}
              </motion.div>

              <div className="btnsDivBoxx">
                <button
                  onClick={() => {
                    navigate("/designtypography");
                  }}
                  className="GoBackBtnIncolor"
                >
                  <span style={{ display: "block" }}>Go back</span>
                </button>
                <div className="btnsDivBoxxInner">
                  <button
                    className="TryAgainBtnIncolor"
                    onClick={() => settoggleTryAgain(true)}
                  >
                    <span style={{ display: "block" }}>Try again</span>
                  </button>
                  <button
                    onClick={() => {
                      handelSubmit();
                      window.scrollTo(0, 0);
                    }}
                    className="NextStepBtnIncolor"
                  >
                    <span style={{ display: "block" }}>Next step</span>
                  </button>
                </div>
              </div>
            </motion.div>
          </div>

          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}

      {toggleTryAgain && (
        <TryagainModal
          settoggleTryAgain={settoggleTryAgain}
          handleSubmit={handelTryAgain}
          handleChangeLikeText={handleChangeLikeText}
          liketext={liketext}
          setliketext={setliketext}
        />
      )}
    </>
  );
};

export default ColorPalette;
