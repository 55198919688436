import React, { useEffect, useState } from "react";
import "./UpperRightBar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectById,
  selectedIteration,
  updateCompleteProject,
  updateCurrentProjectId,
} from "app/reducers/projectEditSlice";
import projectServices from "services/projectServices";
import authServices from "services/authServices";
import memberServices from "services/memberServices";
import { useNavigate } from "react-router-dom";
import { EditItration } from "app/reducers/EditItration";
import { saveActiveButton } from "app/reducers/activeButton";
import JSZip from "jszip";
import { saveAs } from "file-saver";
const UpperRightBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [iteration, setIteration] = useState([]);
  const [member, setMember] = useState([]);

  const SelectedProjectId = useSelector(
    (state) => state?.projectEdit?.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state?.projectEdit?.selectedIterationId
  );

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );
  let projectRecordData = useSelector(
    (state) => state?.projectEdit?.singleProject
  );
  const user = authServices?.getCurrentUser();

  useEffect(() => {
    const fetchData = async () => {
      const data = await projectServices?.getProjectId(SelectedProjectId);
      setIteration(data?.data?.data?.result?.iteration);
      const members = await memberServices?.getMemberByProject(
        SelectedProjectId
      );
      setMember(members?.data?.data?.result);
    };
    fetchData();
  }, []);

  const handelIterationClick = (projectid, iterationid, step) => {
    dispatch(EditItration(true));
    dispatch(updateCurrentProjectId(projectid));
    dispatch(selectedIteration(iterationid));
    dispatch(
      getProjectById({ projectId: projectid, iteration_id: iterationid })
    );
    dispatch(saveActiveButton("Projects"));
    navigate(`/${route(step)}`);
  };
  const route = (step) => {
    if (step === 1) {
      return "projectUpdate";
    }
    if (step === 2) {
      return "designbriefing";
    }
    if (step === 3) {
      return "designtypography";
    }
    if (step === 4) {
      return "colorpalette";
    }
    if (step === 5) {
      return "symbolism";
    }
    if (step === 6) {
      return "symbolismlogo";
    }
    if (step == 7) {
      return "resultstarter";
    } else {
      return "projectUpdate";
    }
  };

  function generateRandomFileName() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 8;
    let randomFileName = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomFileName += characters.charAt(randomIndex);
    }
    return randomFileName;
  }

  async function fetchPreSignedUrls(url) {
    try {
      const body = { s3Url: url };
      const response = await projectServices?.generatePreSignedUrl(body);
      return response?.data?.data?.result;
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadImage(url) {
    try {
      const preSignedUrl = await fetchPreSignedUrls(url);
      if (preSignedUrl) {
        const response = await fetch(preSignedUrl);
        const blob = await response?.blob();
        return blob;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadJpeg(url) {
    try {
      const response = await fetch(url);
      const blob = await response?.blob();

      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadSvg(url) {
    try {
      const preSignedUrl = await fetchPreSignedUrls(url);
      if (preSignedUrl) {
        const response = await fetch(preSignedUrl);
        const svgText = await response?.text();
        const blob = new Blob([svgText], { type: "image/svg+xml" });
        return blob;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadPdf(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadImages(urls, projectName, moduleName) {
    const imageBlobs = [];
    const svgBlobs = [];
    const pdfBlobs = [];

    for (let i = 0; i < urls?.length; i++) {
      const url = urls[i];
      if (url?.endsWith(".svg")) {
        if (moduleName === "Logo") {
        }
        const svgBlob = await downloadSvg(url);
        if (svgBlob) {
          svgBlobs.push(svgBlob);
        }
      } else if (url?.endsWith(".jpeg")) {
        if (moduleName === "Mockup") {
        }
        const imageBlob = await downloadJpeg(url);
        if (imageBlob) {
          imageBlobs.push(imageBlob);
        }
      } else if (url?.endsWith(".pdf")) {
        const pdfBlob = await downloadPdf(url);
        if (pdfBlob) {
          pdfBlobs.push(pdfBlob);
        }
      } else {
        if (moduleName === "Logo") {
        }
        const imageBlob = await downloadImage(url);
        if (imageBlob) {
          imageBlobs.push(imageBlob);
        }
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    const zip = new JSZip();

    for (let i = 0; i < imageBlobs?.length; i++) {
      const randomFileName = generateRandomFileName();
      const downloadFileName = `${moduleName}_${randomFileName}.png`;
      zip.file(downloadFileName, imageBlobs[i]);
    }

    for (let i = 0; i < svgBlobs?.length; i++) {
      const randomFileName = generateRandomFileName();
      const downloadFileName = `${moduleName}_${randomFileName}.svg`;
      zip.file(downloadFileName, svgBlobs[i]);
    }
    for (let i = 0; i < pdfBlobs?.length; i++) {
      const randomFileName = generateRandomFileName();
      const downloadFileName = `${moduleName}_${randomFileName}.pdf`;
      zip.file(downloadFileName, pdfBlobs[i]);
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      const zipFileName = `${projectName}_${moduleName}.zip`;
      saveAs(content, zipFileName);
    });
  }

  return (
    <div className="RightDivAbove">
      <p className="projectResultPara">Project results</p>
      <div className="clientProjectNameBox">
        <p className="ClientNamePararesult">
          Client name <br />
          <span className="ClientNameParaName">
            {projectRecordData?.client_name}
          </span>
        </p>
        <p className="ClientNamePararesult">
          Project name <br />
          <span className="ClientNameParaName">{projectRecordData?.name}</span>
        </p>
        <p className="ClientNamePararesult">
          Creator <br />
          <span className="ClientNameParaName">{user?.userName}</span>
        </p>
        <p className="ClientNamePararesult">
          Team <br />
          <div className="teamcircleBox">
            {member &&
              member?.map((member) => {
                return member?.image ? (
                  <img
                    className="TeamCircle"
                    src={member?.image}
                    alt="member"
                    key={member?._id}
                  />
                ) : (
                  <div className="TeamCircle" key={member?._id}></div>
                );
              })}
          </div>
        </p>
        <p className="ClientNamePararesult">
          INTERACTIONS <br />
          <div className="UlBoxrResult">
            <ul className="thisresult">
              {iteration &&
                iteration?.map((date, i) => {
                  const updatedAtDate = new Date(date?.createdAt);
                  const day = updatedAtDate
                    .getDate()
                    .toString()
                    .padStart(2, "0");
                  const month = (updatedAtDate?.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = updatedAtDate
                    ?.getFullYear()
                    .toString()
                    .slice(-2);

                  const hours = updatedAtDate
                    .getHours()
                    .toString()
                    .padStart(2, "0");
                  const minutes = updatedAtDate
                    .getMinutes()
                    .toString()
                    .padStart(2, "0");

                  const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;

                  return (
                    <li className="resultLi" key={i}>
                      <span
                        onClick={() => {
                          handelIterationClick(
                            date?.project_id,
                            date?._id,
                            date?.iterate_step
                          );
                        }}
                      >
                        {formattedDateTime}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </p>
      </div>

      {/* <button
        className="DownloadProjectBtns"
        onClick={() => {
          if (
            projectRecordData?.plan_name === "Master Pro" ||
            projectRecordData?.plan_name === "Pro"
          ) {
            const urls = [
              projectRecordData?.result?.fullLogo,
              projectRecordData?.result?.fulColorLogo1,
              projectRecordData?.result?.fulColorLogo2,
            ];
            downloadImages(urls, projectRecordData?.name, "Logo");
            downloadImages(
              [projectRecordData?.pdfBook],
              projectRecordData?.name,
              "Brand_Guidelines"
            );
            downloadImages(
              projectRecordData?.gptMockup,
              projectRecordData?.name,
              "Mockup"
            );
            downloadImages(
              [
                projectRecordData?.gptSymbol?.fulColorSymbol1,
                projectRecordData?.gptSymbol?.fulColorSymbol1,
              ],
              projectRecordData?.name,
              "Logo"
            );
          } else {
            const urls = [
              projectRecordData?.result?.fullLogo,
              projectRecordData?.result?.fulColorLogo1,
              projectRecordData?.result?.fulColorLogo2,
            ];
            downloadImages(urls, projectRecordData?.name, "Logo");
          }
        }}
      >
        <svg
          className="DownloadProjectBtnsvg"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5039 5.35693V19.3569"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.5039 12.3569L12.5039 19.3569L5.50391 12.3569"
            stroke="#D1D1D1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Download project
      </button> */}
    </div>
  );
};
export default UpperRightBar;
