import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userServices from "services/userServices";
const AccountVerification = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [verified, setVerified] = useState(null);
  useEffect(() => {
    const linkVerification = async () => {
      try {
        const id = params.id;
        const token = params.token;
        const data = await userServices.verifyUser(id, token);
        if (data) {
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          setVerified(false);
        }
      } catch (error) {
        console.error(error);
        setVerified(false);
      }
    };
    linkVerification();
  }, []);
  return (
    <div
      className=" inset-0 flex items-center justify-center bg-opacity-50 bg-black"
      style={{ height: "100vh" }}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg">
        {verified && (
          <>
            <img
              src="https://i.gifer.com/7efs.gif"
              alt="Success"
              className="mx-auto"
            />
            <p className="text-green-600 text-center mt-4">
              Your account is verified.
            </p>
          </>
        )}
        {verified === false && (
          <>
            <img
              src="https://cliply.co/wp-content/uploads/2021/07/372107370_CROSS_MARK_400px.gif"
              alt="Wrong"
              className="mx-auto"
            />
            <p className="text-red-600 text-center mt-4">
              Your account could not be verified.
            </p>
          </>
        )}
        {verified === null && (
          <>
            <p className="text-gray-600 text-center mt-4">
              Verifying your account...
            </p>
          </>
        )}
      </div>
    </div>
  );
};
export default AccountVerification;
