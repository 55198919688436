import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  remaining_project: 0,
  remaining_collaborator: 0,
  extra_project: 0,
  plan_name: "",
  notification: [],
  paymentUpdated: [],
};

const planDbSlice = createSlice({
  name: "planDb",
  initialState,
  reducers: {
    savePlanDb: (state, action) => {
      const updatedProps = action.payload;
      Object.keys(updatedProps).forEach((key) => {
        if (state.hasOwnProperty(key)) {
          state[key] = updatedProps[key];
        }
      });
    },

    updatePlanDb: (state, action) => {
      const { property, value } = action.payload;
      state[property] = value;
    },
    deletePlanDb(state, action) {
      const itemId = action.payload;
      state.items = state.items.filter((item) => item.id !== itemId);
    },
    deletePlanRecordDb: () => {
      return initialState;
    },

    saveNotification: (state, action) => {
      state.notification = action.payload;
    },

    // savePaymnetUpdate: (state, action) => {
    //   state.paymentUpdated = action.payload;
    // },
  },
});
export const { savePlanDb } = planDbSlice.actions;
export const { deletePlanDb } = planDbSlice.actions;
export const { updatePlanDb } = planDbSlice.actions;
export const { deletePlanRecordDb } = planDbSlice.actions;
export const { saveNotification } = planDbSlice.actions;
// export const { savePaymnetUpdate } = planDbSlice.actions;

export default planDbSlice.reducer;
