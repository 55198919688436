import { useState } from "react";
import "./app.scss";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import { Badge } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../../features/InputValue/Inputvalue";
import authServices from "services/authServices";
import projectServices from "services/projectServices";
import { saveActiveButton } from "app/reducers/activeButton";
import {
  saveProject,
  updateProject,
} from "../../../app/reducers/projectSclice"; // Import the saveProject action from the projectRecordSlice
import toastService from "services/toastService";
import { savePlanDb, saveNotification } from "app/reducers/planDbSlice";
import subscriptionServices from "services/subscriptionServices";
import clientServices from "services/clientServices";
import { deleteClientRecord, saveClient } from "app/reducers/client";
import {
  getNonIterationProjectById,
  updateCurrentProjectId,
} from "app/reducers/projectEditSlice";
import { EditItration } from "app/reducers/EditItration";

export function AppNav({ searchValue, onSearch, ...props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getNotificationData = useSelector(
    (state) => state?.planDb?.notification
  );

  const user = authServices.getCurrentUser();

  const clientData = useSelector((state) => state.clientDb);
  const inputValueredux = useSelector((state) => state.app.inputValue);

  const [openDrop, setOpenDrop] = useState(false);
  const [filteredClients, setFilteredClients] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [inProgressProject, setInProgressProject] = useState([]);
  const [modalOpenNotification, setModelOpenNotification] = useState(false);
  const [showResults, setShowResults] = useState(true);
  const [open, setOpen] = useState(false); // mobile is open

  // const [inputValue, setinputValue] = useState("")

  const handleClose = () => {
    setOpenDrop(false);
  };

  const handleToggle = (event) => {
    setOpenDrop(!open);
  };

  const handelCLick = () => {
    if (modalOpenNotification === false) {
      setModelOpenNotification(true);
    } else {
      setModelOpenNotification(false);
    }
  };

  const handleSearch = (event) => {
    setShowResults(true);
    const query = event.target.value;
    const filtered = inProgressProject.filter(
      (item) =>
        item.name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, "")) ||
        item.client_name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
    );

    const clients = clientData.filter((item) =>
      item.client_name
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(query.toLowerCase().replace(/\s+/g, ""))
    );
    const projects = filtered.filter((item) =>
      item.name
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(query.toLowerCase().replace(/\s+/g, ""))
    );
    setFilteredClients(clients);
    setFilteredProjects(projects);
    onSearch(query);
    if (typeof setOpenDrop === "function") {
      setOpenDrop((prevOpen) => !prevOpen);
    }
    dispatch(setInputValue(query));
  };

  useEffect(() => {
    const user = authServices.getCurrentUser();
    getProject(user.id);
    getPlan(user.id);
  }, []);

  useEffect(() => {
    const user = authServices.getCurrentUser();
    getNotification({ user_id: user?.id });
  }, []);

  const getNotification = async (id) => {
    try {
      const data = await subscriptionServices.getNotifications(id);
      const getAllNotifications = data?.data?.data?.result?.notification;
      if(data?.data?.data?.result?.url) {
        window.location.href = data?.data?.data?.result?.url;
      }
      dispatch(saveNotification(getAllNotifications));
    } catch (ex) {
      toastService.error(ex, 2);
    }
  };

  const getPlan = async (id) => {
    try {
      // if (planDb === "") {
      const data = await subscriptionServices.getPlan(id);
      const plan = data.data.data.result[0];
      dispatch(savePlanDb(plan));
      // }
    } catch (ex) {
      toastService.error(ex, 2);
    }
  };

  const getProject = async (id) => {
    const projectsWithProjectNo = await fetchAndProcessProjects(id);
    const completedProjects = projectsWithProjectNo.filter(
      (project) => project.status === "completed"
    );
    const inProgressProjects = projectsWithProjectNo.filter(
      (project) => project.status === "inProgress"
    );
    setInProgressProject(inProgressProjects);
  };

  const fetchAndProcessProjects = async (userId) => {
    const personalData = await projectServices.getProjectByUserId(userId);
    // const teamData = await teamServices.getTeamProjectByUser(userId);
    const combinedProjects = [...personalData.data.data.result];
    return processProjectsData(combinedProjects);
  };

  const processProjectsData = (data) => {
    data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    return data.map((project, index) => ({
      ...project,
      projectNo: index + 1,
    }));
  };

  const handelEditClick = async (projectId) => {
    handleClose(false);
    setShowResults(false);
    dispatch(saveActiveButton("Projects"));
    try {
      const pro = await projectServices.getProjectId(projectId);
      dispatch(updateCurrentProjectId(pro.data.data.result._id));
      dispatch(getNonIterationProjectById(pro.data.data.result._id));
      dispatch(saveProject(pro.data.data.result));
      dispatch(updateProject({ property: "update", value: true }));
      navigate("/projectUpdate");
    } catch (ex) {
      toastService.error(ex);
    }
  };

  const handelClientEditClick = async (clientId) => {
    handleClose(false);
    setShowResults(false);
    dispatch(saveActiveButton("Clients"));
    try {
      const res = await clientServices.getClientById(clientId);
      dispatch(deleteClientRecord());
      dispatch(saveClient(res.data.data.result[0]));
      navigate("/updateclients");
    } catch (ex) {
      toastService.error(ex, 2);
    }
  };

  const putNotification = async () => {
    try {
      const user = authServices.getCurrentUser();
      const data = await subscriptionServices?.updateNotification({
        user_id: user?.id,
      });
      getNotification({ user_id: user?.id });
    } catch (error) {}
  };

  return (
    <div className="navbarContainer">
      <div className="navbarContainerUpper">
        <div
          className="BrandfyLogoBox"
          onClick={() => {
            navigate("/dashboard");
            dispatch(saveActiveButton("Dashboard"));
          }}
        >
          <div className="brandfyLogoDiv">
            <img src="/BrandfyLogo.svg" alt="" />
          </div>
        </div>

        <div className="discountBox">
          <span className="beta_text">
            Brandfy is currently in Beta. Expect the unexpected and let us know
            what you think!
          </span>
        </div>

        <div className="searchWithIcon">
          <input
            value={inputValueredux}
            className="SearchInputNavbar"
            onClick={handleToggle}
            onChange={handleSearch}
            placeholder="Search projects or clients"
            type="text"
          />

          {inputValueredux && showResults && (
            <div className="search-results">
              {filteredProjects?.length > 0 && (
                <div className="projects-section">
                  <h4 style={{ textTransform: "capitalize" }}>Projects</h4>
                  {filteredProjects?.map((project) => (
                    <div
                      className="project-item"
                      onClick={(e) => {
                        handelEditClick(project._id);
                        dispatch(EditItration(true));
                      }}
                      key={project._id}
                    >
                      {project.name.charAt(0).toUpperCase() +
                        project.name.slice(1)}
                    </div>
                  ))}
                </div>
              )}

              {filteredClients.length > 0 && (
                <div className="clients-section">
                  <h4 style={{ textTransform: "capitalize" }}>Clients</h4>
                  {filteredClients.map((client) => (
                    <div
                      onClick={() => handelClientEditClick(client._id)}
                      key={client.id}
                    >
                      {client.client_name.charAt(0).toUpperCase() +
                        client.client_name.slice(1)}
                    </div>
                  ))}
                </div>
              )}

              {filteredProjects.length === 0 &&
                filteredClients.length === 0 && (
                  <p className="notFounded">No clients or projects found.</p>
                )}
            </div>
          )}

          {inputValueredux ? (
            <svg
              onClick={() => {
                // setinputValue('')
                dispatch(setInputValue(""));
                onSearch("");
              }}
              className="search-icon"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#D1D1D1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#D1D1D1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            ""
          )}

          <Box
            sx={{
              position: "relative",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openDrop}
              onClick={handleClose}
            ></Backdrop>
          </Box>

          <Badge
            className=""
            badgeContent={
              getNotificationData &&
              getNotificationData?.filter((n) => n?.read === false)?.length
            }
            color="secondary"
            sx={{
              ".MuiBadge-badge": {
                height: "19.872px",
                maxWidth: "19.872px",
                width: "100%",
                marginTop: "22.896px",
                marginRight: "2.88px",
                fontSize: "11.952px", // Badge width
              },
            }}
          >
            <svg
              onClick={() => {
                handelCLick();
              }}
              className="NotificationIcon"
              viewBox="0 0 22 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9018 20.5299C8.78858 20.5299 6.67533 20.1599 4.67091 19.4199C3.90905 19.1299 3.32858 18.5399 3.07463 17.7699C2.81161 16.9999 2.9023 16.1499 3.31951 15.3899L4.36254 13.4799C4.58021 13.0799 4.77975 12.2799 4.77975 11.8099V8.91992C4.77975 5.19992 7.52789 2.16992 10.9018 2.16992C14.2758 2.16992 17.0239 5.19992 17.0239 8.91992V11.8099C17.0239 12.2699 17.2235 13.0799 17.4411 13.4899L18.4751 15.3899C18.8651 16.1099 18.9377 16.9799 18.6746 17.7699C18.4116 18.5599 17.8402 19.1599 17.1237 19.4199C15.1283 20.1599 13.0151 20.5299 10.9018 20.5299ZM10.9018 3.66992C8.28068 3.66992 6.14021 6.01992 6.14021 8.91992V11.8099C6.14021 12.5399 5.86812 13.6199 5.53254 14.2499L4.48951 16.1599C4.28998 16.5299 4.23556 16.9199 4.35347 17.2499C4.4623 17.5899 4.7344 17.8499 5.10626 17.9899C8.89742 19.3899 12.9153 19.3899 16.7065 17.9899C17.033 17.8699 17.287 17.5999 17.4049 17.2399C17.5228 16.8799 17.4956 16.4899 17.3142 16.1599L16.2711 14.2499C15.9265 13.5999 15.6635 12.5299 15.6635 11.7999V8.91992C15.6635 6.01992 13.5321 3.66992 10.9018 3.66992Z"
                fill="#D1D1D1"
              />
              <path
                d="M12.5889 3.94018C12.5254 3.94018 12.4619 3.93018 12.3984 3.91018C12.1354 3.83018 11.8814 3.77018 11.6366 3.73018C10.8656 3.62018 10.1219 3.68018 9.42353 3.91018C9.16958 4.00018 8.89749 3.91018 8.72516 3.70018C8.55283 3.49018 8.49842 3.19018 8.59818 2.92018C8.97004 1.87018 9.87702 1.18018 10.911 1.18018C11.9449 1.18018 12.8519 1.86018 13.2238 2.92018C13.3145 3.19018 13.2691 3.49018 13.0968 3.70018C12.9607 3.86018 12.7703 3.94018 12.5889 3.94018Z"
                fill="#D1D1D1"
              />
              <path
                d="M10.9019 22.8101C10.004 22.8101 9.13329 22.4101 8.49841 21.7101C7.86352 21.0101 7.50073 20.0501 7.50073 19.0601H8.8612C8.8612 19.6501 9.07887 20.2301 9.4598 20.6501C9.84073 21.0701 10.3668 21.3101 10.9019 21.3101C12.0265 21.3101 12.9426 20.3001 12.9426 19.0601H14.3031C14.3031 21.1301 12.7793 22.8101 10.9019 22.8101Z"
                fill="#D1D1D1"
              />
            </svg>
          </Badge>

          {modalOpenNotification && (
            <>
              <div className="blurbg" onClick={handelCLick}></div>
              <div
                onClick={() => {
                  setModelOpenNotification(false);
                }}
                style={{
                  position: "absolute",
                  width: "100%",
                  top: "70px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 9999,
                }}
              >
                <div className="notification_modal modalBoxTeam">
                  <div className="forNotificatio">
                    {getNotificationData &&
                      getNotificationData?.filter((n) => n?.read === false)
                        ?.length !== 0 && (
                        <p className="text-right">
                          <button
                            onClick={() => putNotification()}
                            className="read_notification"
                          >
                            Mark as read
                          </button>
                        </p>
                      )}

                    {getNotificationData &&
                    getNotificationData?.filter((n) => n?.read === false)
                      ?.length === 0 ? (
                      <div className="forNoNotificationn">
                        No notifications Found.
                      </div>
                    ) : (
                      <>
                        {getNotificationData &&
                          getNotificationData
                            ?.filter((n) => n?.read === false)
                            .map((notification, index) => {
                              return (
                                <div
                                  key={index}
                                  className="notificatioCard mb-2"
                                >
                                  <span className="notificationPara">
                                    {notification?.plan
                                      ?.split("")
                                      ?.includes("+") ? (
                                      <p>
                                        You have added {notification?.plan}{" "}
                                        projects to your plan successfully.
                                      </p>
                                    ) : notification?.role ? (
                                      <p>
                                        You have added new team member{" "}
                                        {notification?.firstName} and Assign
                                        projects successfully.
                                      </p>
                                    ) : (
                                      <p>
                                        {notification?.plan} plan has been
                                        purchased successfully.
                                      </p>
                                    )}
                                  </span>
                                </div>
                              );
                            })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
