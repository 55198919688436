import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import toastService from "services/toastService";

// CSS
import "./DesignTypography.css";

// API Servics
import projectServices from "services/projectServices";

// Reducers
import { EditItration } from "app/reducers/EditItration";
import {
  getProjectById,
  selectedIteration,
  updateCompleteProject,
  updateProjectProperty,
} from "app/reducers/projectEditSlice";

// Components
import Loading from "../magic/loading";
import RightBar from "views/dashboard/RightBar";
import TryagainModal from "components/TryAgainModal/TryagainModal";

const DesignTypography = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPanagram = localStorage.getItem("pana");

  const SelectedProjectId = useSelector(
    (state) => state.projectEdit.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state.projectEdit.selectedIterationId
  );
  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );

  let projectRecordData = useSelector(
    (state) => state.projectEdit.singleProject
  );


  const fonts = projectRecordData?.fonts;
  const [loading, setLoading] = useState(false);
  const [toggleTryAgain, settoggleTryAgain] = useState(false);
  const [tryagainText, settryagainText] = useState(false);
  const [liketext, setliketext] = useState({
    dontLike: "",
    extraInstruction: "",
  });
  const [selectedFont, setSelectedFont] = useState(
    projectRecordData?.selected_fonts || projectRecordData?.fonts[0]
  );

  const [timeDifference, setTimeDifference] = useState("");
  const itration = useSelector((state) => state.EditItrationNo.itration);

  const [selectedFontProvider, setSelectedFontProvider] = useState(getPanagram);

  function filterBoldWeights(weights) {
    const firstBoldWeight = weights.find((weight) => /bold/i.test(weight));
    return firstBoldWeight ? firstBoldWeight : weights[0];
  }

  const handelSubmit = async () => {
    try {
      setLoading(true);
      const res = await projectServices.updateProject(SelectedProjectId, {
        selected_fonts: selectedFont,
        selected_font_Provider: selectedFontProvider,
        iteration_id: SelectedIterationId,
        iterate_step: 3,
      });
      if (itration) {
        const matchedIteration = res.data.data.result.iteration.find(
          (iter) => iter._id === SelectedIterationId
        );
        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;
        const projectData = await projectServices.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 3 }
        );
        const sortedIterations = projectData.data.data.result.iteration.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        dispatch(updateCompleteProject(projectData.data.data.result));
        dispatch(selectedIteration(sortedIterations[0]._id));
        dispatch(EditItration(false));
        navigate("/typographyweight");
        setLoading(false);
      } else {
        dispatch(updateCompleteProject(res.data.data.result));
        setLoading(false);
        navigate("/typographyweight");
      }
    } catch (ex) {
      toastService.error(ex);
      setLoading(false);
    }
  };

  const handleChangeLikeText = useCallback(
    (name) => (evt) => {
      setliketext((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );

  const handelTryAgain = async (type) => {
    try {
      setLoading(true);
      settryagainText(true);

      let formData = {
        ...projectRecordData,
        iteration_id: SelectedIterationId,
      };
      if (type === "like") {
        formData = {
          ...formData,
          dontLike: liketext?.dontLike,
        };
      } else if (type === "instruction") {
        formData = {
          ...formData,
          extraInstruction: liketext?.extraInstruction,
        };
      }
      const res = await projectServices.generateFontsTryAgin(
        SelectedProjectId,
        formData
      );

      if (itration) {
        const matchedIteration = res.data.data.result.iteration.find(
          (iter) => iter._id === SelectedIterationId
        );

        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;
        const projectData = await projectServices.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 3 }
        );
        const sortedIterations = projectData.data.data.result.iteration.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        dispatch(updateCompleteProject(projectData.data.data.result));
        setLoading(false);
        dispatch(selectedIteration(sortedIterations[0]._id));
        dispatch(EditItration(false));
        setLoading(false);
        settoggleTryAgain(false);
        settryagainText(false);
        setliketext({
          ...liketext,
          dontLike: "",
          extraInstruction: "",
        });
      } else {
        dispatch(
          updateProjectProperty({
            _id: SelectedProjectId,
            key: "selected_fonts",
            value: res.data.data.result?.fonts[0],
          })
        );
        dispatch(
          updateProjectProperty({
            _id: SelectedProjectId,
            key: "updatedAt",
            value: res.data.data.result?.updatedAt,
          })
        );

        dispatch(updateCompleteProject(res.data.data.result));
        const index = res.data.data.result.iteration.length > 0 ? res.data.data.result.iteration[res.data.data.result.iteration.length - 1] : 0
        dispatch(selectedIteration(index._id));
        setLoading(false);
        settoggleTryAgain(false);
        settryagainText(false);
      }
    } catch (ex) {
      toastService.error(ex);
      setLoading(false);
      settoggleTryAgain(false);
      settryagainText(false);
    }
  };

  const selectFont = (font, index) => {
    if (index === 0) {
      dispatch(
        updateProjectProperty({
          _id: SelectedProjectId,
          key: "selected_font_Provider",
          value: "pangram pangram",
        })
      );
      localStorage.setItem("pana", "pangram pangram");
      setSelectedFontProvider("pangram pangram");
    } else {
      dispatch(
        updateProjectProperty({
          _id: SelectedProjectId,
          key: "selected_font_Provider",
          value: "google",
        })
      );
      setSelectedFontProvider("google");
      localStorage.setItem("pana", "google");
    }
    dispatch(
      updateProjectProperty({
        _id: SelectedProjectId,
        key: "selected_fonts",
        value: font,
      })
    );
    setSelectedFont(font);
  };

  useEffect(() => {
    function timeSince(date) {
      const now = new Date();
      const secondsPast = (now.getTime() - date.getTime()) / 1000;

      if (secondsPast < 60) {
        return `${Math.round(secondsPast)} seconds ago`;
      }
      if (secondsPast < 3600) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
      }
      if (secondsPast <= 86400) {
        return `${Math.round(secondsPast / 3600)} hours ago`;
      }
      if (secondsPast > 86400) {
        const daysPast = Math.round(secondsPast / 86400);
        return `${daysPast} days ago`;
      }
    }

    if (projectRecordData?.updatedAt) {
      const lastUpdatedDate = new Date(projectRecordData.updatedAt);
      setTimeDifference(timeSince(lastUpdatedDate));
    }

    const intervalId = setInterval(() => {
      if (projectRecordData?.updatedAt) {
        const lastUpdatedDate = new Date(projectRecordData.updatedAt);
        setTimeDifference(timeSince(lastUpdatedDate));
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [projectRecordData]);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, x: -200, scale: 0.5 },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading tryagainText={tryagainText} />
        </div>
      ) : (
        <div className="designdesignTypographyContainer">
          <div className="leftBriefingBox1">
            <div className="timelineBoxTypography">
              <div className="timelineTypography">
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
              </div>
            </div>
            <div className="projectDesignTypographyBox">
              <p className="DesignTypographyPara">Design: Typography</p>
              <p className="DesignTypographylastSaved">
                Last saved iteration: {timeDifference}
              </p>
            </div>

            <div className="DesignTypographydDesignBox">
              <p className="TypographyPara">
                Here is our selection of typography.
                <br />
                <span className="TypographyParaSpan">
                  Select the typeface you like best
                </span>
              </p>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="show"
                className="fontsBoxDiv"
              >
                {fonts.map((font, index) => (
                  <motion.div
                    variants={cardVariants}
                    key={index}
                    className={`fontSDivouter ${font === selectedFont ? "selectedFont" : ""
                      }`}
                    onClick={() => selectFont(font, index)}
                  >
                    <div className="fontSDivinner" style={{ fontFamily: font }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "start",
                        }}
                      >
                        {index === 0 ? (
                          <style>
                            {`
              @font-face {
                font-family: "${font}";
                src: url("https://brandfy-dashboard.s3.ap-south-1.amazonaws.com/Updated_Fonts/${font}/${font}-${filterBoldWeights(
                              projectRecordData.weight
                            )}.ttf") format("truetype");
              }
            `}
                          </style>
                        ) : (
                          <style>
                            {`
            @font-face {
              font-family: "${font}";
              src: url("https://brandfy-dashboard.s3.ap-south-1.amazonaws.com/Updated_Fonts/${font}/${font}-${filterBoldWeights(
                              projectRecordData.weight2
                            )}.ttf") format("truetype");
            }
          `}
                          </style>
                        )}

                        <div>
                          <p className="mt-2 mb-3 fontName"> {font}</p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: { font },
                              
                            }}
                          >
                            {projectRecordData.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>

              <div className="btnsDivBoxx">
                <button
                  onClick={() => navigate("/designbriefing")}
                  className="GoBackBtnInTypography"
                >
                  <span>Go back</span>
                </button>
                <div className="btnsDivBoxxInner">
                  <button
                    className="TryAgainBtnInTypography"
                    onClick={() => settoggleTryAgain(true)}
                  >
                    <span>Try again</span>
                  </button>
                  <button
                    onClick={() => {
                      handelSubmit();
                      window.scrollTo(0, 0);
                    }}
                    className="NextStepBtnInTypography"
                  >
                    <span>Next step</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}

      {toggleTryAgain && (
        <TryagainModal
          settoggleTryAgain={settoggleTryAgain}
          handleSubmit={handelTryAgain}
          handleChangeLikeText={handleChangeLikeText}
          liketext={liketext}
          setliketext={setliketext}
        />
      )}
    </>
  );
};

export default DesignTypography;
