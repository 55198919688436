import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./app/app";
import store, { persistor } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";

const container = document.getElementById("root");

ReactDOM.createRoot(container).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
