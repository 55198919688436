import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";
import authServices from "./authServices";
axios.interceptors.response.use(
  (response) => {
    if (response.status < 200 || response.status > 299) {
      logger.log(response.data);
      return Promise.reject(response.data.data.message || "An error occurred.");
    }
    return response;
  },
  (error) => {
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : "Server Unavailable.";
    logger.log(error);
    return Promise.reject(errorMessage);
  }
);
function setJwt() {
  const token = authServices.getCurrentToken()
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
