import React, { useState } from "react";
import "./ManageTeamsRightBar.css";
import teamServices from "services/teamServices";
import toastService from "services/toastService";
import { useNavigate } from "react-router-dom";
import memberServices from "services/memberServices";
import { saveActiveButton } from "app/reducers/activeButton";
import { useDispatch } from "react-redux";
const ManageTeamsRightBar = (props) => {
  const EditMemberHandl = props.EditMemberHandl;
  const selectedMemberId = props.memberId;
  const [modalOpenImage, setModalOpenImage] = useState(false);
  const navigate = useNavigate();
  const handelDelete = async () => {
    try {
      const res = await memberServices.deleteMemberById(selectedMemberId);
      window.location.reload();
    } catch (ex) {
      toastService.error(ex);
    }
  };
  const dispatch = useDispatch();
  return (
    <>
      <div className="ManageTeamsRightBarContainer">
        <div className="ManageRightBarCard">
          <span className="ManageTeamParaxo">Manage team</span>
          {!EditMemberHandl ? (
            <>
              <button
                onClick={() => {
                  navigate("/createmember");
                }}
                className="AddMemberBtns"
              >
                {" "}
                <svg
                  className="AddMemberBtnsvg"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 21.5V19.5C20 18.4391 19.5786 17.4217 18.8284 16.6716C18.0783 15.9214 17.0609 15.5 16 15.5H8C6.93913 15.5 5.92172 15.9214 5.17157 16.6716C4.42143 17.4217 4 18.4391 4 19.5V21.5"
                    stroke="#D1D1D1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 11.5C14.2091 11.5 16 9.70914 16 7.5C16 5.29086 14.2091 3.5 12 3.5C9.79086 3.5 8 5.29086 8 7.5C8 9.70914 9.79086 11.5 12 11.5Z"
                    stroke="#D1D1D1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Add members
              </button>
              <button
                onClick={() => {
                  if (selectedMemberId !== null) {
                    setModalOpenImage(true);
                  }
                }}
                className="AddMemberBtns"
              >
                {" "}
                <svg
                  className="AddMemberBtnsvg"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 21.5V19.5C17 18.4391 16.5786 17.4217 15.8284 16.6716C15.0783 15.9214 14.0609 15.5 13 15.5H5C3.93913 15.5 2.92172 15.9214 2.17157 16.6716C1.42143 17.4217 1 18.4391 1 19.5V21.5"
                    stroke="#D1D1D1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z"
                    stroke="#D1D1D1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23 21.4999V19.4999C22.9993 18.6136 22.7044 17.7527 22.1614 17.0522C21.6184 16.3517 20.8581 15.8515 20 15.6299"
                    stroke="#D1D1D1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 3.62988C16.8604 3.85018 17.623 4.35058 18.1676 5.05219C18.7122 5.7538 19.0078 6.61671 19.0078 7.50488C19.0078 8.39305 18.7122 9.25596 18.1676 9.95757C17.623 10.6592 16.8604 11.1596 16 11.3799"
                    stroke="#D1D1D1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Delete members
              </button>
              <button
                onClick={() => {
                  navigate("/billing");
                  dispatch(saveActiveButton("Billing"));
                }}
                className="yellowTeamManagebtn"
              >
                <svg
                  className="AddMemberBtnsvg"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1260_1914)">
                    <path
                      d="M9 22.5C9.55228 22.5 10 22.0523 10 21.5C10 20.9477 9.55228 20.5 9 20.5C8.44772 20.5 8 20.9477 8 21.5C8 22.0523 8.44772 22.5 9 22.5Z"
                      stroke="#262626"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20 22.5C20.5523 22.5 21 22.0523 21 21.5C21 20.9477 20.5523 20.5 20 20.5C19.4477 20.5 19 20.9477 19 21.5C19 22.0523 19.4477 22.5 20 22.5Z"
                      stroke="#262626"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1.5H5L7.68 14.89C7.77144 15.3504 8.02191 15.764 8.38755 16.0583C8.75318 16.3526 9.2107 16.509 9.68 16.5H19.4C19.8693 16.509 20.3268 16.3526 20.6925 16.0583C21.0581 15.764 21.3086 15.3504 21.4 14.89L23 6.5H6"
                      stroke="#262626"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1260_1914">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Add more members
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  navigate("/NewClient");
                  dispatch(saveActiveButton("Billing"));
                }}
                className="yellowTeamManagebtn"
              >
                <svg
                  className="AddMemberBtnsvg"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1260_1914)">
                    <path
                      d="M9 22.5C9.55228 22.5 10 22.0523 10 21.5C10 20.9477 9.55228 20.5 9 20.5C8.44772 20.5 8 20.9477 8 21.5C8 22.0523 8.44772 22.5 9 22.5Z"
                      stroke="#262626"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20 22.5C20.5523 22.5 21 22.0523 21 21.5C21 20.9477 20.5523 20.5 20 20.5C19.4477 20.5 19 20.9477 19 21.5C19 22.0523 19.4477 22.5 20 22.5Z"
                      stroke="#262626"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1.5H5L7.68 14.89C7.77144 15.3504 8.02191 15.764 8.38755 16.0583C8.75318 16.3526 9.2107 16.509 9.68 16.5H19.4C19.8693 16.509 20.3268 16.3526 20.6925 16.0583C21.0581 15.764 21.3086 15.3504 21.4 14.89L23 6.5H6"
                      stroke="#262626"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1260_1914">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Add more members
              </button>
            </>
          )}
          <div className="ThinkManageTeam">
            <img src="ThinkManageTeam.png" alt="" />
          </div>
        </div>
      </div>
      {modalOpenImage && (
        <div
          onClick={() => {
            setModalOpenImage(false);
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <div className="modalBoxTeam">
            <div className="areYouSure">
              Are you sure you want to delete this member?
            </div>
            <p className="allprojectItration">
              All the projects
              <span className="allprojectItrationspan">
                and iterations produced by this user will be transferred to the
                account
              </span>
              Owner.
            </p>
            <div className="divbuttonbtns">
              <button onClick={handelDelete} className="DeleteMemberTeam">
                DELETE
              </button>
              <button className="cancelMemberTeam">CANCEL</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ManageTeamsRightBar;
