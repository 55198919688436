import React, { useEffect, useState } from "react";
import "./DoingMagic.css";
import { LinearProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

const Loading = ({ tryagainText }) => {
  const [loadingDescription, setLoadingDescription] = useState("");
  const location = useLocation();

  const loadingText = () => {
    switch (location?.pathname) {
      case "/designbriefing":
        setLoadingDescription(
          tryagainText
            ? "Wait a bit please and your briefing will be ready in a moment!"
            : "Wait a bit please and your typography will be ready in a moment!"
        );
        break;

      case "/designtypography":
        setLoadingDescription(
          tryagainText
            ? "Wait a bit please and your typography will be ready in a moment!"
            : "Wait a bit please and your font weight will be ready in a moment!"
        );
        break;

      case "/typographyweight":
        setLoadingDescription(
          "Wait a bit please and your color palette will be ready in a moment!"
        );
        break;

      case "/colorpalette":
        setLoadingDescription(
          tryagainText
            ? "Wait a bit please and your color palette will be ready in a moment!"
            : "Wait a bit please and your logo symbol will be ready in a moment!"
        );
        break;

      case "/symbolism":
        setLoadingDescription(
          tryagainText
            ? "Wait a bit please and your logo symbol will be ready in a moment!"
            : "Wait a bit please and your logo will be ready in a moment!"
        );
        break;

      case "/symbolismlogo":
        setLoadingDescription(
          "Wait a bit please and your brand images will be ready in a moment!"
        );
        break;

      case "/brand-assets":
        setLoadingDescription(
          tryagainText
            ? "Wait a bit please and your brand images will be ready in a moment!"
            : "Wait a bit please and your mockups will be ready in a moment!"
        );
        break;

      case "/mockups":
        setLoadingDescription(
          "Wait a bit please and your final deliverable page will be ready in a moment!"
        );
        break;
      default:
        return "";
    }
  };

  useEffect(() => {
    loadingText();
  }, [location.pathname]);

  return (
    <div className="DoingMagicContainer">
      <div className="leftside-loading">
        <img className="LogoBrandfyIcon" src="/brandfy_black.svg" alt="" />
        <p className="wearedoingPara">
          We are doing our magic.
          <span className="magicSpan">{loadingDescription}</span>
          <LinearProgress
            sx={{
              backgroundColor: "#d5caca",
              marginTop: "40px",
              borderRadius: "8px",
              height: "20px",
            }}
          />
        </p>
      </div>

      <img className="thinkingLoadingWaiting" src="/loading2.gif" alt="" />
    </div>
  );
};
export default Loading;
