import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// CSS
import "./SymbolismLogo.css";

// Reducers
import {
  getProjectById,
  saveHumnas,
  savePlace,
  saveTextured,
  saveConceptImage,
  saveTexturedWithLogo,
  savePlaceWithLogo,
  saveHumanWithLogo,
  saveConceptWithLogo,
} from "app/reducers/projectEditSlice";
import projectServices from "services/projectServices";

// Components
import Loading from "../magic/loading";
import RightBar from "views/dashboard/RightBar";
import TryagainModal from "components/TryAgainModal/TryagainModal";

const SymbolismLogo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [toggleTryAgain, settoggleTryAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [liketext, setliketext] = useState({
    dontLike: "",
    extraInstruction: "",
  });

  const SelectedProjectId = useSelector(
    (state) => state?.projectEdit?.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state?.projectEdit?.selectedIterationId
  );

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );

  let projectRecordData = useSelector(
    (state) => state?.projectEdit?.singleProject
  );
  const result = projectRecordData?.result;
  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    function timeSince(date) {
      const now = new Date();
      const secondsPast = (now.getTime() - date.getTime()) / 1000;

      if (secondsPast < 60) {
        return `${Math.round(secondsPast)} seconds ago`;
      }
      if (secondsPast < 3600) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
      }
      if (secondsPast <= 86400) {
        return `${Math.round(secondsPast / 3600)} hours ago`;
      }
      if (secondsPast > 86400) {
        const daysPast = Math.round(secondsPast / 86400);
        return `${daysPast} days ago`;
      }
    }

    if (projectRecordData?.updatedAt) {
      const lastUpdatedDate = new Date(projectRecordData?.updatedAt);
      setTimeDifference(timeSince(lastUpdatedDate));
    }

    const intervalId = setInterval(() => {
      if (projectRecordData?.updatedAt) {
        const lastUpdatedDate = new Date(projectRecordData?.updatedAt);
        setTimeDifference(timeSince(lastUpdatedDate));
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [projectRecordData]);

  const handelSubmitClick = async () => {
    setLoading(true);

    try {
      const [humansData, textureData, conceptImageData, placeData] =
        await Promise.all([
          projectServices?.generateHumans(SelectedProjectId, {
            id: SelectedProjectId,
            iteration_id: SelectedIterationId,
          }),
          projectServices?.generateTexture(SelectedProjectId, {
            id: SelectedProjectId,
            iteration_id: SelectedIterationId,
          }),
          projectServices?.generateConceptImages(SelectedProjectId, {
            id: SelectedProjectId,
            iteration_id: SelectedIterationId,
          }),
          projectServices?.generatePlace(SelectedProjectId, {
            id: SelectedProjectId,
            iteration_id: SelectedIterationId,
          }),
        ]);

      dispatch(saveHumnas(humansData?.data?.data?.result));
      dispatch(saveTextured(textureData?.data?.data?.result));
      dispatch(saveConceptImage(conceptImageData?.data?.data?.result));
      dispatch(savePlace(placeData?.data?.data?.result));

      const [
        textTurewithLogoData,
        placewithLogoData,
        humanWithLogoData,
        conceptWithLogoData,
      ] = await Promise.all([
        projectServices?.textureWithLogo(SelectedProjectId, {
          svgUrl: projectRecordData?.result?.logoForImages,
        }),
        projectServices?.placeWithLogo(SelectedProjectId, {
          svgUrl: projectRecordData?.result?.logoForImages,
        }),
        projectServices?.humanWithLogo(SelectedProjectId, {
          svgUrl: projectRecordData?.result?.logoForImages,
        }),
        projectServices?.conceptWithLogo(SelectedProjectId, {
          svgUrl: projectRecordData?.result?.logoForImages,
        }),
      ]);

      dispatch(
        saveTexturedWithLogo(
          textTurewithLogoData?.data && textTurewithLogoData?.data?.data?.result
        )
      );
      dispatch(
        savePlaceWithLogo(
          placewithLogoData?.data && placewithLogoData?.data?.data?.result
        )
      );
      dispatch(
        saveHumanWithLogo(
          humanWithLogoData?.data && humanWithLogoData?.data?.data?.result
        )
      );
      dispatch(
        saveConceptWithLogo(
          conceptWithLogoData?.data && conceptWithLogoData?.data?.data?.result
        )
      );
      navigate("/brand-assets");
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const handleChangeLikeText = useCallback(
    (name) => (evt) => {
      setliketext((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );

  const handelTryAgain = () => {};

  const handleOpenCustommizeTool = () => {
    navigate("/edit-tool");
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="designsymbolismLogoContainer">
          <div className="leftBriefingBox1">
            <div className="timelineBoxsymbolLogo">
              <div className="timelinesymbolLogo">
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
              </div>
            </div>

            <div className="projectDesignsymbolLogoBox">
              <p className="DesignsymbolLogoPara">Design: Logo</p>
              <p className="DesignsymbolLogolastSaved">
                Last saved iteration: {timeDifference}
              </p>
            </div>

            <div className="DesignsymbolLogoDesignBox">
              <p className="symbolsParaLogo">
                Here is your logo.
                <span className="symbolsParaspanLogo">
                  If you like the result continue with the next step.
                </span>
              </p>

              {/* <button onClick={handleOpenCustommizeTool}>Customize Logo</button> */}

              <div className="SymbolismBoxLogo">
                <div
                  className="FullLogo"
                  style={{
                    backgroundColor:
                      projectRecordData &&
                      projectRecordData?.color_palette?.main[0]?.Hex,
                  }}
                >
                  <img
                    style={{ maxWidth: "650px", objectFit: "cover" }}
                    src={result?.fullLogo}
                    alt=""
                  ></img>
                </div>

                <div className="color12">
                  <div
                    className="fullLogoColor1"
                    style={{
                      backgroundColor:
                        projectRecordData &&
                        projectRecordData?.color_palette?.secondary[0]?.Hex,
                    }}
                  >
                    <img
                      style={{ maxWidth: "400px", objectFit: "cover" }}
                      src={result?.fulColorLogo3}
                      alt=""
                    ></img>
                  </div>

                  <div
                    className="fullLogoColor2"
                    style={{
                      backgroundColor:
                        projectRecordData &&
                        projectRecordData?.color_palette?.main[0]?.Hex,
                    }}
                  >
                    <img
                      style={{ maxWidth: "400px", objectFit: "cover" }}
                      src={result?.fulColorLogo2}
                      alt=""
                    ></img>
                  </div>
                </div>
              </div>

              <div className="btnsDivBoxx">
                <button
                  onClick={() => {
                    navigate("/symbolism");
                  }}
                  className="GoBackBtnInsymbolLogo"
                >
                  <span>Go back</span>
                </button>
                <div className="btnsDivBoxxInner">
                  <button
                    onClick={() => {
                      handelSubmitClick();
                    }}
                    className="NextStepBtnInsymbolLogo"
                  >
                    <span>Next step</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}

      {toggleTryAgain && (
        <TryagainModal
          settoggleTryAgain={settoggleTryAgain}
          handleSubmit={handelTryAgain}
          handleChangeLikeText={handleChangeLikeText}
          liketext={liketext}
          setliketext={setliketext}
        />
      )}
    </>
  );
};

export default SymbolismLogo;
