import { Fragment, useContext } from "react";
import { AuthContext, AppNav, Header, User, UserProfile } from "components/lib";
import Style from "./app.module.scss";
import { useState } from "react";
import { useEffect, createContext } from "react";

export const SearchContext = createContext();
export function AppLayout(props) {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  // using useEffect to log the state value every time it changes
  useEffect(() => {}, [searchValue]);
  const clearSearchValue = () => {
    setSearchValue("");
  };
  // context & style
  const context = useContext(AuthContext);

  return (
    <SearchContext.Provider value={searchValue}>
      <div className={Style.app}>
        <AppNav searchValue={searchValue} onSearch={handleSearch} />

        <main style={{ display: "flex" }}>
          <UserProfile clearSearchValue={clearSearchValue} />

          {<props.children {...props.data} />}
        </main>
      </div>
    </SearchContext.Provider>
  );
}
