import React, { useEffect } from "react";
import "./RightBar.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clientServices from "services/clientServices";

import authServices from "services/authServices";
import { saveClientDb } from "app/reducers/clientDbSlice";
import { saveActiveButton } from "app/reducers/activeButton";
import toastService from "services/toastService";
import {
  getProjectById,
  selectedIteration,
  updateCurrentProjectId,
} from "app/reducers/projectEditSlice";
import { EditItration } from "app/reducers/EditItration";
import moment from "moment";

const RightBar = () => {
  const [activeButton, setActiveButton] = useState(null);
  const navigate = useNavigate();

  const planDb = useSelector((state) => state.planDb);
  const clientDb = useSelector((state) => state.clientDb);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = authServices.getCurrentUser();
        const user_id = user.id;
        const client = await clientServices.getClient(user_id);
        dispatch(saveClientDb(client.data.data.result));
      } catch (ex) {}
    };
    fetchData();
  }, []);

  const handelNewProjectClick = () => {
    if (planDb.remaining_project + planDb.extra_project > 0) {
      navigate("/dashboard/createnewproject");
      dispatch(saveActiveButton("Projects"));
    } else {
      toastService.error("Please subscribe to a plan or buy projects.");
      dispatch(saveActiveButton("Billing"));
      navigate("/billing");
    }
  };
  const handleClick = (clientId, projectId) => {
    const buttonId = `${clientId}-${projectId}`;
    setActiveButton(activeButton === buttonId ? null : buttonId);
  };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (isExpanded, panel) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handelIterationClick = (projectid, iterationid, step) => {
    dispatch(EditItration(true));
    dispatch(updateCurrentProjectId(projectid));
    dispatch(
      getProjectById({ projectId: projectid, iteration_id: iterationid })
    );
    dispatch(selectedIteration(iterationid));
    dispatch(saveActiveButton("Projects"));
    navigate(`/${route(step)}`);
  };
  const route = (step) => {
    if (step === 1) {
      return "projectUpdate";
    }
    if (step === 2) {
      return "designbriefing";
    }
    if (step === 3) {
      return "designtypography";
    }
    if (step === 4) {
      return "colorpalette";
    }
    if (step === 5) {
      return "symbolism";
    }
    if (step === 6) {
      return "symbolismlogo";
    }
    if (step == 7) {
      return "resultstarter";
    } else {
      return "projectUpdate";
    }
  };

  const lastIterationStep = (step) => {
    console.log(step, 'step');
    if (step == 2) {
      return "Briefing";
    }
    if (step == 3) {
      return "Typography";
    }
    if (step == 4) {
      return "Color Pallete";
    }
    if (step == 5) {
      return "Logo Symbol";
    }
    if (step == 6) {
      return "Logo";
    }
    if (step == 7) {
      return "Brand Assets";
    }
    if (step == 8) {
      return "Mockups";
    }
    if (step == 9) {
      return "Brand Guidelines";
    }
    else {
      return "projectUpdate";
    }
  };

  return (
    <div className="RightSideDashboardContainer">
      <p className="mangageProjectPara">Manage Projects</p>
      {clientDb.map((client) => (
        <div className="clientNameCard" key={client._id}>
          <div className="ClientNameBoxIcon">
            <div
              style={{
                marginTop: "8.064px",
                marginLeft: "9.072px",
                marginRight: "7.056px",
              }}
              className="FolderIcon"
            >
              <svg
                style={{
                  width: "40.896px",
                  height: "39.888px",
                }}
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.3424 31.6667C37.3424 32.5507 36.9899 33.3986 36.3623 34.0237C35.7348 34.6488 34.8837 35 33.9962 35H7.22701C6.33956 35 5.48845 34.6488 4.86093 34.0237C4.2334 33.3986 3.88086 32.5507 3.88086 31.6667V8.33333C3.88086 7.44928 4.2334 6.60143 4.86093 5.97631C5.48845 5.35119 6.33956 5 7.22701 5H15.5924L18.9386 10H33.9962C34.8837 10 35.7348 10.3512 36.3623 10.9763C36.9899 11.6014 37.3424 12.4493 37.3424 13.3333V31.6667Z"
                  stroke="#F5FF01"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <p className="clientNamePara">{client.client_name}</p>
          </div>

          {client.project &&
            client.project.map((project) => (
              <div key={project._id}>
                <button
                  className="ProjectBtnNum"
                  onClick={() => handleClick(client._id, project._id)}
                >
                  <svg
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginLeft: "8.064px",
                      marginRight: "8.064px",
                      height: "24.8928px",
                      width: "24.8928px",
                    }}
                  >
                    <path
                      d="M15.5 10.5L20.5 15.5L15.5 20.5"
                      stroke="#D1D1D1"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.5 4.5V11.5C4.5 12.5609 4.92143 13.5783 5.67157 14.3284C6.42172 15.0786 7.43913 15.5 8.5 15.5H20.5"
                      stroke="#D1D1D1"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    {project.name}
                  </span>
                </button>
                {activeButton === `${client._id}-${project._id}` && (
                  <div className="UlBox">
                    <ul className="this">
                      {project.iteration &&
                        project.iteration.map((item, index) => {
                          {
                           console.log('item', item)
                          }
                          return (
                            <li className="LiSpanLi" key={index}>
                              <span
                                onClick={() => {
                                  handelIterationClick(
                                    project._id,
                                    item._id,
                                    item.iterate_step
                                  );
                                }}
                                className="LiSpan"
                              >
                                {moment(item?.updatedAt).format(
                                  "DD MMMM YYYY - HH:mm"
                                )}{" "}
                                @{" "}{lastIterationStep(item?.last_iteration_step)}
                                {/* {`${dateString} ${timeString}`} */}
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          <button
            onClick={() => {
              handelNewProjectClick();
            }}
            className="newprojectButton"
          >
            <svg
              style={{
                height: "25.0176px",
                width: "25.0176px",
                marginLeft: "8.064px",
                marginRight: "8.064px",
              }}
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 4.5H4.5C3.96957 4.5 3.46086 4.71071 3.08579 5.08579C2.71071 5.46086 2.5 5.96957 2.5 6.5V20.5C2.5 21.0304 2.71071 21.5391 3.08579 21.9142C3.46086 22.2893 3.96957 22.5 4.5 22.5H18.5C19.0304 22.5 19.5391 22.2893 19.9142 21.9142C20.2893 21.5391 20.5 21.0304 20.5 20.5V13.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 3.00023C19.3978 2.6024 19.9374 2.37891 20.5 2.37891C21.0626 2.37891 21.6022 2.6024 22 3.00023C22.3978 3.39805 22.6213 3.93762 22.6213 4.50023C22.6213 5.06284 22.3978 5.6024 22 6.00023L12.5 15.5002L8.5 16.5002L9.5 12.5002L19 3.00023Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "3px",
              }}
            >
              New Project
            </span>
          </button>
        </div>
      ))}
      <button
        onClick={() => {
          navigate("/NewClient");
        }}
        className="NewClientAdd"
      >
        <svg
          style={{
            marginLeft: "74.8864px",
            marginRight: "9.9456px",
            height: "24.8928px",
            width: "23.9104px",
          }}
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 19.5C22 20.0304 21.7893 20.5391 21.4142 20.9142C21.0391 21.2893 20.5304 21.5 20 21.5H4C3.46957 21.5 2.96086 21.2893 2.58579 20.9142C2.21071 20.5391 2 20.0304 2 19.5V5.5C2 4.96957 2.21071 4.46086 2.58579 4.08579C2.96086 3.71071 3.46957 3.5 4 3.5H9L11 6.5H20C20.5304 6.5 21.0391 6.71071 21.4142 7.08579C21.7893 7.46086 22 7.96957 22 8.5V19.5Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span
          style={{ display: "flex", alignItems: "center", marginTop: "3px" }}
        >
          New client
        </span>
      </button>
      <button
        onClick={() => {
          navigate("/manageclients");
        }}
        className="ManageClient"
      >
        <svg
          style={{
            marginLeft: "54px",
            marginRight: "8.064px",
            height: "24.8928px",
            width: "23.9104px",
          }}
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 21.5V19.5C17 18.4391 16.5786 17.4217 15.8284 16.6716C15.0783 15.9214 14.0609 15.5 13 15.5H5C3.93913 15.5 2.92172 15.9214 2.17157 16.6716C1.42143 17.4217 1 18.4391 1 19.5V21.5"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 21.4999V19.4999C22.9993 18.6136 22.7044 17.7527 22.1614 17.0522C21.6184 16.3517 20.8581 15.8515 20 15.6299"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 3.62988C16.8604 3.85018 17.623 4.35058 18.1676 5.05219C18.7122 5.7538 19.0078 6.61671 19.0078 7.50488C19.0078 8.39305 18.7122 9.25596 18.1676 9.95757C17.623 10.6592 16.8604 11.1596 16 11.3799"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span
          style={{ display: "flex", alignItems: "center", marginTop: "3px" }}
        >
          Manage clients
        </span>
      </button>
    </div>
  );
};

export default RightBar;
