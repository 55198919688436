// reducers/projectRecordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const clientDbSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        addClient: (state, action) => {
            state.push(action.payload);
        },
        saveClientDb: (state, action) => {
            return [...action.payload];
        },
        updateClientDb: (state, action) => {
            const { _id, updatedProps } = action.payload;
            const clientIndex = state.findIndex(client => client._id === _id);
            if (clientIndex > -1) {
                Object.keys(updatedProps).forEach((key) => {
                    state[clientIndex][key] = updatedProps[key];
                });
            }
        },
        deleteClientDb: (state, action) => {
            const _id = action.payload;
            return state.filter(client => client._id !== _id);
        },
        deleteAllClients: () => {
            return initialState;
        }
    }
});

export const { addClient, saveClientDb, updateClientDb, deleteClientDb, deleteAllClients } = clientDbSlice.actions;

export default clientDbSlice.reducer;
