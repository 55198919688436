import React, { useEffect, useState } from "react";
import toastService from "services/toastService";
import { motion } from "framer-motion";
import "./Billing.css";

// Components
import CircularProgress from "@mui/material/CircularProgress";
import BillingRightBar from "./BillingRightBar";
import { useSelector, useDispatch } from "react-redux";
import {
  deletePaymentRecord,
  savePayment,
} from "../../../app/reducers/payment";
import Joi from "joi";

// Store &  API Services
import { deletePlanRecord, savePlan } from "app/reducers/plan";
import { savePlanDb } from "app/reducers/planDbSlice";
import subscriptionServices from "services/subscriptionServices";
import authServices from "services/authServices";

// Validations
import validationService from "services/validationService";

function Billing() {
  const plansMapping = {
    Starter: [
      {
        id: 1,
        price: 239 * 1,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHeWF913NLSsZSMQESOr0S",
        plan_name: "+1",
        quantity: 1,
      },
      {
        id: 2,
        price: 239 * 2,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHeWF913NLSsZSMQESOr0S",
        plan_name: "+2",
        quantity: 2,
      },
      {
        id: 3,
        price: 239 * 3,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHeWF913NLSsZSMQESOr0S",
        plan_name: "+3",
        quantity: 3,
      },
      {
        id: 4,
        price: 239 * 4,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHeWF913NLSsZSMQESOr0S",
        plan_name: "+4",
        quantity: 4,
      },
      {
        id: 5,
        price: 239 * 5,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHeWF913NLSsZSMQESOr0S",
        plan_name: "+5",
        quantity: 5,
      },
      {
        id: 6,
        price: 239 * 6,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHeWF913NLSsZSMQESOr0S",
        plan_name: "+6",
        quantity: 6,
      },
    ],
    Pro: [
      {
        id: 1,
        price: 199 * 1,
        // priceId: "price_1NfjSUF913NLSsZSIzKL132N",
        priceId: "price_1OBHeRF913NLSsZSogTLdvqS",
        plan_name: "+1",
        quantity: 1,
      },
      {
        id: 2,
        price: 199 * 2,
        // priceId: "price_1NfjSUF913NLSsZSIzKL132N",
        priceId: "price_1OBHeRF913NLSsZSogTLdvqS",
        plan_name: "+2",
        quantity: 2,
      },
      {
        id: 3,
        price: 199 * 3,
        // priceId: "price_1NfjSUF913NLSsZSIzKL132N",
        priceId: "price_1OBHeRF913NLSsZSogTLdvqS",
        plan_name: "+3",
        quantity: 3,
      },
      {
        id: 4,
        price: 199 * 4,
        // priceId: "price_1NfjSUF913NLSsZSIzKL132N",
        priceId: "price_1OBHeRF913NLSsZSogTLdvqS",
        plan_name: "+4",
        quantity: 4,
      },
      {
        id: 5,
        price: 199 * 5,
        // priceId: "price_1NfjSUF913NLSsZSIzKL132N",
        priceId: "price_1OBHeRF913NLSsZSogTLdvqS",
        plan_name: "+5",
        quantity: 5,
      },
      {
        id: 6,
        price: 199 * 6,
        // priceId: "price_1NfjSUF913NLSsZSIzKL132N",
        priceId: "price_1OBHeRF913NLSsZSogTLdvqS",
        plan_name: "+6",
        quantity: 6,
      },
    ],

    "Master Pro": [
      {
        id: 1,
        price: 159 * 1,
        // priceId: "price_1NfjSUF913NLSsZSVNfND7tl",
        priceId: "price_1OuAXsF913NLSsZSoq7FHYn8",
        plan_name: "+1",
        quantity: 1,
      },
      {
        id: 2,
        price: 159 * 2,
        // priceId: "price_1NfjSUF913NLSsZSVNfND7tl",
        priceId: "price_1OuAXsF913NLSsZSoq7FHYn8",
        plan_name: "+2",
        quantity: 2,
      },
      {
        id: 3,
        price: 159 * 3,
        // priceId: "price_1NfjSUF913NLSsZSVNfND7tl",
        priceId: "price_1OuAXsF913NLSsZSoq7FHYn8",
        plan_name: "+3",
        quantity: 3,
      },
      {
        id: 4,
        price: 159 * 4,
        // priceId: "price_1NfjSUF913NLSsZSVNfND7tl",
        priceId: "price_1OuAXsF913NLSsZSoq7FHYn8",
        plan_name: "+4",
        quantity: 4,
      },
      {
        id: 5,
        price: 159 * 5,
        // priceId: "price_1NfjSUF913NLSsZSVNfND7tl",
        priceId: "price_1OuAXsF913NLSsZSoq7FHYn8",
        plan_name: "+5",
        quantity: 5,
      },

      {
        id: 6,
        price: 159 * 6,
        // priceId: "price_1NfjSUF913NLSsZSVNfND7tl",
        priceId: "price_1OuAXsF913NLSsZSoq7FHYn8",
        plan_name: "+6",
        quantity: 6,
      },
    ],
    Free: [
      {
        id: 1,
        price: 239 * 1,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHe8F913NLSsZSCB9zAK7L",
        plan_name: "+1",
        quantity: 1,
      },
      {
        id: 2,
        price: 239 * 2,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHe8F913NLSsZSCB9zAK7L",
        plan_name: "+2",
        quantity: 2,
      },
      {
        id: 3,
        price: 239 * 3,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHe8F913NLSsZSCB9zAK7L",
        plan_name: "+3",
        quantity: 3,
      },
      {
        id: 4,
        price: 239 * 4,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHe8F913NLSsZSCB9zAK7L",
        plan_name: "+4",
        quantity: 4,
      },
      {
        id: 5,
        price: 239 * 5,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHe8F913NLSsZSCB9zAK7L",
        plan_name: "+5",
        quantity: 5,
      },
      {
        id: 6,
        price: 239 * 6,
        // priceId: "price_1NfjSUF913NLSsZS1OrI0muP",
        priceId: "price_1OBHe8F913NLSsZSCB9zAK7L",
        plan_name: "+6",
        quantity: 6,
      },
    ],
  };

  const dispatch = useDispatch();
  const user = authServices.getCurrentUser();

  const plan = useSelector((state) => state?.planDb);

  const [hoveredCard, setHoveredCard] = useState("Pro Plan");
  const [Loading, setLoading] = useState(true);
  const [Loading1, setLoading1] = useState(false);
  const [history, setHistory] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(plan?.plan_name || "Free"); // default to 'starter'
  const [projectsBill, setProjectsBill] = useState(plansMapping[selectedPlan]);
  const [activeProducts, setActiveProduct] = useState();
  const [BillingData, setBillingData] = useState({
    user_id: "",
    plan_name: "",
    price: 0,
    remaining_project: 0,
    remaining_collaborator: 0,
    extra_project: 0,
    priceId: "",
  });

  const schema = Joi.object().keys({
    user_id: Joi.string().required().allow(""),
    plan_name: Joi.string().label("plan_name").required(),
    price: Joi.number().label("price").required(),
    remaining_project: Joi.number().label("remaining_project").required(),
    remaining_collaborator: Joi.number()
      .label("remaining_collaborator")
      .required(),
    extra_project: Joi.number().label("extra_project").required(),
    priceId: Joi.string().required(),
    quantity: Joi.number().allow(""),
  });

  function capitalizeFirstLetter(string) {
    if (string && typeof string === "string") {
      for (let i = 0; i < string.length; i++) {
        if (string[i].match(/[a-z]/i)) {
          return (
            string.slice(0, i) +
            string[i].toUpperCase() +
            string.slice(i + 1).toLowerCase()
          );
        }
      }
    }
    return string;
  }

  const handleSubmit = (data) => {
    const errors = validationService.validate(data, schema);
    if (errors) return;
    doSubmit(data);
  };

  const handleProjectSubmit = (data) => {
    const errors = validationService.validate(data, schema);
    doProjectSubmit(data);
  };

  const doSubmit = async (data) => {
    setLoading1(true);
    try {
      const result = await subscriptionServices.CreateSubscription(data);
      try {
        dispatch(async (dispatch) => {
          try {
            const paymentResult = await subscriptionServices.postPayment({
              user_id: user?.id,
            });
          } catch (paymentError) {
            toastService.error(paymentError);
          }
        });
      } catch (error) {
        toastService.error(error);
      }

      try {
        if (result && result.data.data.result.url) {
          window.location.href = result.data.data.result.url;
          setLoading1(false);
        } else {
          const plan = await subscriptionServices.getPlan(user.id);
          dispatch(savePlanDb(plan?.data?.data?.result?.[0]));
          setLoading1(false);
          window.location.reload();
        }
      } catch (ex) {
        toastService.error(ex);
        setLoading1(false);
      }
    } catch (ex) {
      toastService.error(ex);
      setLoading1(false);
    }
  };

  const doProjectSubmit = async (data) => {
    setLoading1(true);
    const result = await subscriptionServices.addMoreProject(data);
    try {
      if (result && result.data.data.result.url) {
        window.location.href = result.data.data.result.url;
        setLoading1(false);
      } else {
        const plan = await subscriptionServices.getPlan(user.id);
        dispatch(savePlanDb(plan?.data?.data?.result[0]));
        window.location.reload();
        toastService.success(
          `Thank you for upgrading to the ${plan.plan_name} plan!`,
          3
        );
        setLoading1(false);
      }
    } catch (ex) {
      toastService.error(ex);
      setLoading1(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, x: -200, scale: 0.5 },
    show: {
      opacity: 0.5,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  useEffect(() => {
    const getProducts = async () => {
      try {
        const result = await subscriptionServices.getActiveProducts();
        const products = result.data.data.result;
        
        const updatedProducts = products.map((product) => {
          if (product.metadata && product.metadata.function_list) {
            const functionListString = product.metadata.function_list;

            const cleanedString = functionListString
              .replace(/^\[/, "")
              .replace(/\]$/, "");

            const regex = /,(?![^\(]*\))/g;
            const functionListArray = cleanedString.split(regex);

            return {
              ...product,
              function_list: functionListArray,
            };
          }
          return product;
        });

        const sortedProducts = updatedProducts.sort((a, b) => {
          if (
            a.metadata &&
            b.metadata &&
            a.metadata.db_price &&
            b.metadata.db_price
          ) {
            return (
              parseFloat(a.metadata.db_price) - parseFloat(b.metadata.db_price)
            );
          }
          return 0;
        });
        setActiveProduct(sortedProducts);
      } catch (error) {
        console.error(error);
      }
    };
    getProducts();
  }, []);

  useEffect(() => {
    setProjectsBill(plansMapping[selectedPlan]);
  }, [selectedPlan]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await authServices.getCurrentUser();
        const data = await subscriptionServices.getPurchaseHistory(user?.id);
        setHistory(
          data && data?.data && data?.data?.data?.result?.notification
        );
        const plan = await subscriptionServices.getPlan(user?.id);
        if (plan) {
          dispatch(deletePlanRecord());
          dispatch(savePlan(plan?.data?.data?.result));
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    try {
      dispatch(async (dispatch) => {
        try {
          const paymentResult = await subscriptionServices.postPayment({
            user_id: user?.id,
          });
        } catch (paymentError) {}
      });
    } catch (error) {}
  }, []);

  return (
    <div className="BillingContainerBill">
      {Loading1 && (
        <div className="fullLoader">
          <CircularProgress color="inherit" />
        </div>
      )}

      <div className="leftSideBillingBill">
        <div className="newClientFormDivBill">
          <p className="currentBillingParaBill">Billing</p>
          <div className="dottedLineBill"></div>
          <div className="accordingToBilling">
            According to the work of other users, the plan that might suit you
            is:
          </div>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="show"
            style={{
              gridTemplateColumns: Loading ? "1fr" : "1fr 1fr 1fr",
            }}
            className="billingCardsBox"
          >
            {Loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#f5ff01",
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            ) : (
              activeProducts &&
              activeProducts?.map((product) => (
                <>
                  <motion.div
                    onMouseEnter={() => setHoveredCard(product.name)}
                    onMouseLeave={() => setHoveredCard("Pro Plan")}
                    variants={cardVariants}
                    className={`BillingCard ${
                      hoveredCard === product.name.split("@")[1].trim()
                        ? "activeplan"
                        : ""
                    }`}
                  >
                    {product.name !== "Brandfy @ Pro Plan" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <rect width="26" height="50" fill="#F5FF01" />
                        <rect x="38" width="12" height="50" fill="#F5FF01" />
                      </svg>
                    ) : (
                      <svg
                        className="BillingCardsvg"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 0H12V10L38 10V0H50V50H38V40H12V50H0V40V10V0Z"
                          fill="#F5FF01"
                        />
                      </svg>
                    )}

                    <div className="ProTextBilling">
                      {product.name.split("@")[1]}
                      <br />
                      <span className="ProTextBillingspn">
                        {capitalizeFirstLetter(product.description)}
                      </span>
                    </div>

                    <div className="biilingamountPara">
                      <del className="biilingamountParaspan">
                        ${product.metadata.del_price}
                      </del>
                      {product.metadata.price}
                    </div>

                    {/* <p className="unlimitedParaAccess">
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Unlimited access to:
                    </p> */}
                    <br />

                    <div className="unlimitedAccessBox">
                      {product?.function_list &&
                        product.function_list.map((functionItem, index) => (
                          <span key={index} className="allStarterPack">
                            {product.name.split("@")[1].trim() ===
                            "Pro Plan" ? (
                              functionItem === "All Starter Pack" ? (
                                <svg
                                  className="unlimitedParaAccesssvg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20 6L9 17L4 12"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="unlimitedParaAccesssvg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 5V19"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M5 12H19"
                                    stroke="#D1D1D1"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )
                            ) : (
                              <svg
                                className="unlimitedParaAccesssvg"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20 6L9 17L4 12"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                            {capitalizeFirstLetter(functionItem)}
                          </span>
                        ))}
                    </div>

                    {/* <p
                      className={`OneUserNoCollaboration ${
                        product.name.split("@")[1].trim() === "Master Plan"
                          ? "OneUserNoCollaboration1"
                          : ""
                      }`}
                    >
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {capitalizeFirstLetter(
                        product.metadata.collaborative_users
                      )}
                    </p> */}

                    {/* <p className="monthInclude">
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {capitalizeFirstLetter(product.metadata.projects)}
                    </p> */}
                    {/* <span className="MoreProjectBilling">
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 5V19"
                          stroke="#F5FF01"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 12H19"
                          stroke="#F5FF01"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      {capitalizeFirstLetter(product.metadata.more_project)}
                    </span> */}
                    <br />
                    <button
                      className="upgradeBilling"
                      onClick={() => {
                        const data = {
                          user_id: user.id,
                          plan_name: product.metadata.db_name,
                          price: parseInt(product.metadata.db_price),
                          remaining_project: parseInt(
                            product.metadata.db_remaining_project
                          ),
                          remaining_collaborator: parseInt(
                            product.metadata.db_remaining_collaborator
                          ),
                          extra_project: 0,
                          //   priceId: product.metadata.db_priceId,
                          priceId: product.default_price,
                        };
                        dispatch(savePayment(data));
                        handleSubmit(data);
                      }}
                    >
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1319_2357)">
                          <path
                            d="M9.5 22.9336C10.0523 22.9336 10.5 22.4859 10.5 21.9336C10.5 21.3813 10.0523 20.9336 9.5 20.9336C8.94772 20.9336 8.5 21.3813 8.5 21.9336C8.5 22.4859 8.94772 22.9336 9.5 22.9336Z"
                            stroke="#484848"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.5 22.9336C21.0523 22.9336 21.5 22.4859 21.5 21.9336C21.5 21.3813 21.0523 20.9336 20.5 20.9336C19.9477 20.9336 19.5 21.3813 19.5 21.9336C19.5 22.4859 19.9477 22.9336 20.5 22.9336Z"
                            stroke="#484848"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.5 1.93359H5.5L8.18 15.3236C8.27144 15.784 8.52191 16.1976 8.88755 16.4919C9.25318 16.7862 9.7107 16.9426 10.18 16.9336H19.9C20.3693 16.9426 20.8268 16.7862 21.1925 16.4919C21.5581 16.1976 21.8086 15.784 21.9 15.3236L23.5 6.93359H6.5"
                            stroke="#484848"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1319_2357">
                            <rect
                              width="24"
                              height="24"
                              fill="white"
                              transform="translate(0.5 0.933594)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <span>Upgrade</span>
                    </button>
                  </motion.div>
                </>
              ))
            )}
          </motion.div>

          {/***********************    Purchase Card Map Method End *************************/}

          <div className="dottedLineBill"></div>
          <div className="alsoAddmore">
            You can also add more projects to your current plan:
          </div>

          {/* *********************** Add More Project Map Method Start ********************* */}

          <div className="moreProjectDiVBox">
            {projectsBill?.map((project) => (
              <div key={project.id} className="moreprojectDivCard">
                <p className="buyMoreProject">Buy more projects</p>
                <div className="projectnumBerBox">
                  + <span style={{ marginTop: "30px" }}>{project.id}</span>
                </div>
                <div className="projectBillingPara">
                  ${project.price}/Project
                </div>

                <button
                  className="addPRojectBtnBilling"
                  onClick={() => {
                    dispatch(deletePaymentRecord());
                    const data = {
                      user_id: user.id,
                      price: project.price,
                      extra_project: project.id,
                      priceId: project.priceId,
                      plan_name: project.plan_name,
                      quantity: project.quantity,
                    };
                    dispatch(savePayment(data));
                    handleProjectSubmit(data);
                  }}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1290_1487)">
                        <path
                          d="M9 22.0264C9.55228 22.0264 10 21.5787 10 21.0264C10 20.4741 9.55228 20.0264 9 20.0264C8.44772 20.0264 8 20.4741 8 21.0264C8 21.5787 8.44772 22.0264 9 22.0264Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 22.0264C20.5523 22.0264 21 21.5787 21 21.0264C21 20.4741 20.5523 20.0264 20 20.0264C19.4477 20.0264 19 20.4741 19 21.0264C19 21.5787 19.4477 22.0264 20 22.0264Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 1.02637H5L7.68 14.4164C7.77144 14.8768 8.02191 15.2903 8.38755 15.5847C8.75318 15.879 9.2107 16.0354 9.68 16.0264H19.4C19.8693 16.0354 20.3268 15.879 20.6925 15.5847C21.0581 15.2903 21.3086 14.8768 21.4 14.4164L23 6.02637H6"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1290_1487">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0 0.0263672)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </svg>
                  <span> Buy more projects </span>
                </button>
              </div>
            ))}
          </div>

          <p className="butIfPraa"></p>
          <div className="dottedLineBill"></div>
          <p className="purchaseHistoryPara">Purchase history</p>
          <div className="purchasingDivParaBox">
            <p className="purchasingDataPara">Purchase date</p>
            <p className="purchasingDataPara">Plan</p>
            <p className="purchasingDataPara">Start date</p>
            <p className="purchasingDataPara">End date</p>
            <p className="purchasingDataPara">Price</p>
          </div>

          <div className="dottedLineBilling"></div>
          {history?.length > 0 ? (
            <div>
              {history?.map((data, index) => (
                <div className="purchasingDivParaBoxDate" key={index}>
                  <div className="innerBilingBox">
                    <p className="purchasingDataPara2">{data.purchaseDate}</p>
                    <p className="purchasingDataPara22">{data.plan}</p>
                    <p className="purchasingDataPara23">{data.purchaseDate}</p>
                    <p className="purchasingDataPara24">{data.endDate}</p>
                    <p className="purchasingDataPara25">${data.price}</p>
                  </div>
                  <div className="dottedLineBillingDown"></div>
                </div>
              ))}
            </div>
          ) : (
            <p style={{ color: "white" }}>No transaction history found.</p>
          )}
        </div>
      </div>

      {/*  Your actual plan  component*/}

      <div className="RightSideDashboard">
        <BillingRightBar activeProductData={activeProducts} />
      </div>
    </div>
  );
}
export default Billing;
