import React, { useEffect, useState } from "react";
import "./DoingMagic.css";
import { LinearProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
const DoingMagic = () => {
  const [loadingDescription, setLoadingDescription] = useState("");
  const location = useLocation();

  const loadingText = () => {
    switch (location?.pathname) {
      case "/projectUpdate":
        setLoadingDescription(
          "Wait a bit please and your briefing will be ready in a moment!"
        );
        break;
      case "/dashboard/createnewproject":
        setLoadingDescription(
          "Wait a bit please and your briefing will be ready in a moment!"
        );
        break;

      default:
        return "Wait a bit please and your mockups will be ready in a moment!";
    }
  };

  useEffect(() => {
    loadingText();
  }, [location.pathname]);

  return (
    <div className="DoingMagicContainer">
      <div className="leftside-loading">
        <img className="LogoBrandfyIcon" src="/brandfy_black.svg" alt="" />
        <p className="wearedoingPara">
          We are doing our magic.
          <span className="magicSpan">{loadingDescription}</span>
          <LinearProgress
            sx={{
              backgroundColor: "#d5caca",
              marginTop: "40px",
              borderRadius: "8px",
              height: "20px",
            }}
          />
        </p>
      </div>

      <img className="thinkingLoadingWaiting" src="/loading2.gif" alt="" />
    </div>
  );
};

export default DoingMagic;
