import React from "react";
import "./DoingMagic.css";
import { LinearProgress } from "@mui/material";
const WorkingMagic = () => {
  return (
    <div className="DoingMagicContainer">
      <div className="leftside-loading">
        <img className="LogoBrandfyIcon" src="/brandfy_black.svg" alt="" />
        <p className="wearedoingPara">
          We are doing our magic.
          <span className="magicSpan">
            We are working on it, in a moment you will have the new iteration
            saved
          </span>
          <LinearProgress
            sx={{
              backgroundColor: "#d5caca",
              marginTop: "40px",
              borderRadius: "8px",
              height: "20px",
            }}
          />
        </p>
      </div>

      <img className="thinkingLoadingWaiting" src="/loading2.gif" alt="" />
    </div>
  );
};
export default WorkingMagic;
