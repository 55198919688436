// slice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    inputValue: "",
    value: 0,
    Inputobject:' this is object data'
};

const slice = createSlice({
    name: "app",
    initialState,
    reducers: {
        increment: state => {
            state.value += 1
        },
        decrement: state => {
            state.value -= 1
        },
        setInputValue: (state, action) => {
            state.inputValue = action.payload;
        },
        setInputobject: (state, action) => {
            state.Inputobject = action.payload;
        },
    },
});

export const { setInputValue,increment,decrement,setInputobject } = slice.actions;

export default slice.reducer;
