import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveActiveButton } from "app/reducers/activeButton";
function Success() {
  const [countdown, setCountdown] = React.useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown === 0) {
        dispatch(saveActiveButton("Billing"));
        navigate("/billing");
      }
      if (countdown > 0) {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown, navigate, dispatch]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
      }}
    >
      <div
        className="flex justify-center items-center h-screen"
        style={{
          width: "100%",
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg
              className="h-6 w-6 text-green-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium  text-black"
              id="modal-headline"
            >
              Payment failed...
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500 w-96">
                You will be redirected to the subscriptions page in{" "}
                <span className="font-bold">{countdown}</span> seconds.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Success;
