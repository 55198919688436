import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  remaining_project: 0,
  remaining_collaborator: 0,
  extra_project: 0,
  plan_name: "",
};
const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    savePlan: (state, action) => {
      const updatedProps = action.payload;
      Object.keys(updatedProps).forEach((key) => {
        if (state.hasOwnProperty(key)) {
          state[key] = updatedProps[key];
        }
      });
    },
    updatePlan: (state, action) => {
      const { property, value } = action.payload;
      state[property] = value;
    },
    deletePlan(state, action) {
      const itemId = action.payload;
      state.items = state.items.filter((item) => item.id !== itemId);
    },
    deletePlanRecord: (state) => {
      return initialState;
    },
  },
});
export const { savePlan } = planSlice.actions;
export const { savePayment } = planSlice.actions;
export const { updatePlan } = planSlice.actions;
export const { deletePlanRecord } = planSlice.actions;
export default planSlice.reducer;
