import Cookies from 'universal-cookie';
import http from "./httpService";
import config from "../config"
const { apiUrl } = config;
const apiEndpoint = `${apiUrl}/user`;
const cookies = new Cookies();


async function createUser(user) {
    return await http.post(`${apiEndpoint}`, user);
}
async function UpdateUser(id, data) {
    return await http.put(`${apiEndpoint}/${id}`, data);
}
async function verifyUser(id, token) {
    return await http.get(`${apiEndpoint}/${id}/verify/${token}`);
}
async function resendEmail(body) {
    return await http.post(`${apiEndpoint}/resendEmail`, body);
}
export default {
    createUser,
    UpdateUser, verifyUser, resendEmail
};
