
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    rememberMe: false
};
const rememberMe = createSlice({
    name: 'rememberMe',
    initialState,
    reducers: {
        saveRememberMe: (state, action) => {
            state.rememberMe = action.payload;
        },
        deleteRememberMe: (state) => {
            return initialState;
        },
    },
});
export const { saveRememberMe } = rememberMe.actions;
export const { deleteRememberMe } = rememberMe.actions;
export default rememberMe.reducer;