import React from "react";
import "./DesignMotherBoard.css";
import RightBar from "views/dashboard/RightBar";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const Designmotherboard = () => {
  const [isHovered, setIsHovered] = useState(false);
  const svgpath = (
    <svg
      width="125"
      height="125"
      viewBox="0 0 125 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.947 20.9939L120.947 20.994L79.8333 62.1466L79.4799 62.5003L79.8336 62.8537L120.986 103.967C120.986 103.967 120.986 103.967 120.986 103.967C125.671 108.653 125.671 116.262 120.986 120.947C116.301 125.632 108.691 125.632 104.006 120.947L104.006 120.947L62.8534 79.8333L62.4997 79.4799L62.1463 79.8336L21.0329 120.986C21.0328 120.986 21.0328 120.986 21.0327 120.986C16.3475 125.671 8.7382 125.671 4.05299 120.986C-0.63227 116.301 -0.632271 108.691 4.05299 104.006L4.05316 104.006L45.1667 62.8534L45.5201 62.4997L45.1664 62.1463L4.01395 21.0329C4.01389 21.0328 4.01383 21.0328 4.01378 21.0327C-0.671316 16.3475 -0.67126 8.7382 4.01395 4.05299C8.69921 -0.632272 16.3086 -0.632272 20.9939 4.05299L20.994 4.05316L62.1466 45.1667L62.5003 45.5201L62.8537 45.1664L103.967 4.01395C103.967 4.01389 103.967 4.01383 103.967 4.01378C108.653 -0.671316 116.262 -0.67126 120.947 4.01395C125.632 8.69921 125.632 16.3086 120.947 20.9939Z"
        fill="#F5FF01"
        stroke="black"
      />
    </svg>
  );
  const navigate = useNavigate();
  return (
    <div className="designdesignMotherBoardContainer">
      <div className="leftBriefingBox">
        <div className="timelineBoxMotherBoard">
          <div clasName="timelineMotherBoard">
            <div className="connectorMotherBoardpin"></div>
            <div className="connectorMotherBoardpin"></div>
            <div className="connectorMotherBoardpin"></div>
            <div className="connectorMotherBoardpin"></div>
            <div className="connectorMotherBoardpin"></div>
            <div className="connectorMotherBoardpin"></div>
          </div>
        </div>
        <div className="projectDesignMotherBoardBox">
          <p className="DesignMotherBoardPara">Designing: Moodboard</p>
          <p className="DesignMotherBoardlastSaved">
            Last saved iteration: 2 minutes ago
          </p>
        </div>
        <div className="DesignMotherBoardDesignBox">
          <div className="createReference">
            This is the moodboard we have created with references we will use to
            create your brand.
            <span className="refferenceSpan">
              If there is any content with which you do not agree, you just have
              to hover over it with your mouse and click on the X.
            </span>
          </div>

          <div className="fontLogoBox">
            <div
              className="Logo1 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Logo
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
            <div
              className="Font1 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Font
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
            <div
              className="Color1 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Color
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>

            <div
              className="MockUp1 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Mockup
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
            <div
              className="Logo2 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Logo
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
            <div
              className="Logo3 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Logo
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>

            <div
              className="color2 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Color
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>

            <div
              className="color3 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Color
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>

            <div
              className="Font2 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Font
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
            <div
              className="Font3 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Font
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
            <div
              className="Logo4 crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Logo
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
            <div
              className="MockUpRemove crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Mockup
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
            <div
              className="illustration crossShow"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Illusation
              {isHovered && (
                <div className="hover-image">
                  {svgpath}

                  <p className="RemoveText">Remove this reference</p>
                </div>
              )}
            </div>
          </div>
          <button
            onClick={() => {
              navigate("/designbriefing");
            }}
            className="GoBackBtnInMotherBoard"
          >
            Go back
          </button>
          <button className="TryAgainBtnInMotherBoard">Try again</button>
          <button
            onClick={() => {
              navigate("/designtypography");
              window.scrollTo(0, 0);
            }}
            className="NextStepBtnInMotherBoard"
          >
            Next step
          </button>
        </div>
      </div>

      <div className="RightSideDashboard">
        <RightBar />
      </div>
    </div>
  );
};

export default Designmotherboard;
