import React from "react";
import { useContext } from "react";
import { SearchContext } from "components/layout/app/app";
import "./ManageClients.css";
import RightBar from "views/dashboard/RightBar";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import authServices from "services/authServices";
import clientServices from "services/clientServices";
import { deleteClientRecord, saveClient } from "../../../app/reducers/client";
import { useDispatch, useSelector } from "react-redux";
import { saveClientDb } from "app/reducers/clientDbSlice";
import toastService from "services/toastService";

export function ManageClients() {
  const [modalOpenDelete, setModalOpenDelete] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const dispatch = useDispatch();
  const clientDb = useSelector((state) => state.clientDb);

  const cardVariants = {
    hidden: { opacity: 0, y: -100 },
    show: { opacity: 1, y: 0 },
  };

  const navigate = useNavigate();
  const searchValue = useContext(SearchContext);

  const filteredClients = clientDb.filter((client) =>
    client.client_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handelDelete = async (id) => {
    try {
      const res = await clientServices.deleteClient(id);
      const client = await clientServices.getClient(
        res.data.data?.result.user_id
      );
      if (client.data.data.result.length > 0) {
        authServices.updateClientProperty(true);
        dispatch(saveClientDb(client.data.data.result));
        setSelectedClient("");
        window.location.reload();
        toastService.success("Client deleted successfully");
      } else {
        authServices.updateClientProperty(false);
        dispatch(saveClientDb(client.data.data.result));
        setSelectedClient("");
        window.location.reload();
        toastService.success("Client deleted successfully");
      }
    } catch (ex) {
      toastService.error(ex);
    }
  };

  function convertTimestampToMonthYear(timestamp) {
    const date = new Date(timestamp);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  const handelEdit = async (clientId) => {
    const res = await clientServices?.getClientById(clientId);
    dispatch(deleteClientRecord());
    dispatch(saveClient(res?.data?.data?.result[0]));
    navigate("/updateclients");
  };

  return (
    <div className="ManageClientsContainer">
      <div className="leftSideManageClients">
        <p className="currentManageClientsPara">
          {searchValue
            ? "Results of the search for projects or clients"
            : "  Manage clients"}
        </p>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          className={
            filteredClients.length > 0
              ? "projectsDivBoxClients"
              : "anotherClass"
          }
        >
          {filteredClients.length > 0 ? (
            filteredClients.map((client, index) => (
              <motion.div
                variants={cardVariants}
                className="ManageClientsCard"
                key={index}
              >
                <p className="clientNameManageClientPara">
                  {client.client_name}
                </p>
                <p className="clientManageDated">
                  {convertTimestampToMonthYear(client.createdAt)}
                </p>
                <div className="manageprojectLInee"></div>
                <p className="workingParaD">Working</p>
                <div className="spandivProjectClients">
                  {client.project.map((project) => {
                    return (
                      <span className="spandivProjectClientsspan">
                        {project.name}
                      </span>
                    );
                  })}
                </div>

                <div className="editdeletebtnsclient">
                  <button
                    onClick={() => {
                      handelEdit(client._id);
                    }}
                    className="editbtnsclientbtn"
                  >
                    <svg
                      className="editbtnssvgclient"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5"
                        stroke="#D1D1D1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.5 3.00023C18.8978 2.6024 19.4374 2.37891 20 2.37891C20.5626 2.37891 21.1022 2.6024 21.5 3.00023C21.8978 3.39805 22.1213 3.93762 22.1213 4.50023C22.1213 5.06284 21.8978 5.6024 21.5 6.00023L12 15.5002L8 16.5002L9 12.5002L18.5 3.00023Z"
                        stroke="#D1D1D1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Edit client
                  </button>
                  <button
                    onClick={() => {
                      setModalOpenDelete(true);
                      setSelectedClient(client._id);
                    }}
                    className="editbtnsclient"
                  >
                    <svg
                      className="editbtnssvgclient"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 6.5H5H21"
                        stroke="#D1D1D1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 6.5V20.5C19 21.0304 18.7893 21.5391 18.4142 21.9142C18.0391 22.2893 17.5304 22.5 17 22.5H7C6.46957 22.5 5.96086 22.2893 5.58579 21.9142C5.21071 21.5391 5 21.0304 5 20.5V6.5M8 6.5V4.5C8 3.96957 8.21071 3.46086 8.58579 3.08579C8.96086 2.71071 9.46957 2.5 10 2.5H14C14.5304 2.5 15.0391 2.71071 15.4142 3.08579C15.7893 3.46086 16 3.96957 16 4.5V6.5"
                        stroke="#D1D1D1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 11.5V17.5"
                        stroke="#D1D1D1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 11.5V17.5"
                        stroke="#D1D1D1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Delete
                  </button>
                </div>
              </motion.div>
            ))
          ) : (
            <div className="dontHaveanyClientDiv">
              You have no active clients, <br />
              <span className="dontHaveanyProjectDivSpan">
                Click on “New client” to create one
              </span>
            </div>
          )}
        </motion.div>
        <button
          onClick={() => {
            navigate("/NewClient");
          }}
          className="NewClientCreate"
        >
          <svg
            style={{
              marginRight: "8.06px",
              width: "24.05px",
              height: "24.05px",
            }}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5 21.5V19.5C20.5 18.4391 20.0786 17.4217 19.3284 16.6716C18.5783 15.9214 17.5609 15.5 16.5 15.5H8.5C7.43913 15.5 6.42172 15.9214 5.67157 16.6716C4.92143 17.4217 4.5 18.4391 4.5 19.5V21.5"
              stroke="#363538"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 11.5C14.7091 11.5 16.5 9.70914 16.5 7.5C16.5 5.29086 14.7091 3.5 12.5 3.5C10.2909 3.5 8.5 5.29086 8.5 7.5C8.5 9.70914 10.2909 11.5 12.5 11.5Z"
              stroke="#363538"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          New client
        </button>
      </div>

      <div className="RightSideDashboard">
        <RightBar />
      </div>
      {modalOpenDelete && (
        <div
          onClick={() => {
            setModalOpenDelete(false);
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <div className="modalBoxTeam">
            <div className="areYouSure">Confirm Client deletion</div>
            <p className="allprojectItration">
              Are you sure you want to delete this client?<br></br>
              You will not be able to access it anymore.
            </p>
            <div className="divbuttonbtns">
              <button
                onClick={() => {
                  setModalOpenDelete(false);
                  handelDelete(selectedClient);
                }}
                className="DeleteMemberTeam"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setModalOpenDelete(false);
                }}
                className="cancelMemberTeam"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
