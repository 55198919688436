import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Reducers
import { getProjectById } from "app/reducers/projectEditSlice";

// Components
import Loading from "../magic/loading";
import RightBar from "views/dashboard/RightBar";

const LogoEditingTool = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const SelectedProjectId = useSelector(
    (state) => state?.projectEdit?.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state?.projectEdit?.selectedIterationId
  );

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );

  let projectRecordData = useSelector(
    (state) => state?.projectEdit?.singleProject
  );
  const result = projectRecordData?.result;

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="designsymbolismLogoContainer">
          <div className="leftBriefingBox1">
            <div className="projectDesignsymbolLogoBox">
              <p className="DesignsymbolLogoPara">Customize your logo</p>
            </div>

            <div className="DesignsymbolLogoDesignBox">
              <div className="SymbolismBoxLogo">
                <div className="FullLogo">
                  <img
                    style={{ width: "100%", objectFit: "fill" }}
                    src={result?.fullLogo}
                    alt=""
                  ></img>
                </div>
              </div>

              {/* Button */}
              <div className="btnsDivBoxx">
                <button
                  onClick={() => {
                    navigate("/symbolismlogo");
                  }}
                  className="GoBackBtnInsymbolLogo"
                >
                  <span>Go back</span>
                </button>
                <div className="btnsDivBoxxInner">
                  <button className="NextStepBtnInsymbolLogo">
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}
    </>
  );
};

export default LogoEditingTool;
