import React, { useEffect } from "react";
import "../Symbolism/Symbolism.css";
import RightBar from "views/dashboard/RightBar";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import projectServices from "services/projectServices";
import Loading from "../magic/loading";
import {
  getProjectById,
  updateCompleteProject,
  savePdfBook,
} from "app/reducers/projectEditSlice";

const MockupsImage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const SelectedProjectId = useSelector(
    (state) => state?.projectEdit?.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state?.projectEdit?.selectedIterationId
  );
  const mockupsImageData = useSelector(
    (state) => state?.projectEdit?.mockups?.gptMockup
  );
  const [loading, setLoading] = useState(false);
  const [timeDifference, setTimeDifference] = useState("");

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );

  let projectRecordData = useSelector(
    (state) => state?.projectEdit?.singleProject
  );
  const result = projectRecordData?.result;

  useEffect(() => {
    function timeSince(date) {
      const now = new Date();
      const secondsPast = (now.getTime() - date.getTime()) / 1000;

      if (secondsPast < 60) {
        return `${Math.round(secondsPast)} seconds ago`;
      }
      if (secondsPast < 3600) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
      }
      if (secondsPast <= 86400) {
        return `${Math.round(secondsPast / 3600)} hours ago`;
      }
      if (secondsPast > 86400) {
        const daysPast = Math.round(secondsPast / 86400);
        return `${daysPast} days ago`;
      }
    }

    if (projectRecordData?.updatedAt) {
      const lastUpdatedDate = new Date(projectRecordData?.updatedAt);
      setTimeDifference(timeSince(lastUpdatedDate));
    }

    const intervalId = setInterval(() => {
      if (projectRecordData?.updatedAt) {
        const lastUpdatedDate = new Date(projectRecordData?.updatedAt);
        setTimeDifference(timeSince(lastUpdatedDate));
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [projectRecordData]);

  const handelSubmitClick = async () => {
    setLoading(true);
    try {
      const data1 = await projectServices?.generateMissionAndVision(
        SelectedProjectId,
        { ...projectRecordData, iteration_id: SelectedIterationId }
      );

      // Images With Logo API's Call
      // const textTurewithLogoData = await projectServices?.textureWithLogo(
      //   SelectedProjectId,
      //   {
      //     svgUrl: projectRecordData?.result?.fullLogo,
      //   }
      // );

      // dispatch(
      //   saveTexturedWithLogo(
      //     textTurewithLogoData &&
      //       textTurewithLogoData?.data &&
      //       textTurewithLogoData?.data?.data?.result
      //   )
      // );

      // const placewithLogoData = await projectServices?.placeWithLogo(
      //   SelectedProjectId,
      //   {
      //     svgUrl: projectRecordData?.result?.fullLogo,
      //   }
      // );
      // dispatch(
      //   savePlaceWithLogo(
      //     placewithLogoData &&
      //       placewithLogoData?.data &&
      //       placewithLogoData?.data?.data?.result
      //   )
      // );

      // const humanWithLogoData = await projectServices?.humanWithLogo(
      //   SelectedProjectId,
      //   {
      //     svgUrl: projectRecordData?.result?.fullLogo,
      //   }
      // );
      // dispatch(
      //   saveHumanWithLogo(
      //     humanWithLogoData &&
      //       humanWithLogoData?.data &&
      //       humanWithLogoData?.data?.data?.result
      //   )
      // );

      // const conceptWithLogoData = await projectServices?.conceptWithLogo(
      //   SelectedProjectId,
      //   {
      //     svgUrl: projectRecordData?.result?.fullLogo,
      //   }
      // );
      // dispatch(
      //   saveConceptWithLogo(
      //     conceptWithLogoData &&
      //       conceptWithLogoData?.data &&
      //       conceptWithLogoData?.data?.data?.result
      //   )
      // );

      const res = await generatePdf(data1?.data?.data?.result);
      dispatch(updateCompleteProject(res?.data?.data?.result));
      if (res?.statusText === "OK") {
        navigate("/resultstarter");
      }
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const generatePdf = async () => {
    const obj = {
      project_id: SelectedProjectId,
    };
    const result = await projectServices?.generateBook(obj);
    dispatch(savePdfBook(result?.data?.data?.result));
    return result;
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="designsymbolismLogoContainer">
          <div className="leftBriefingBox1">
            <div className="timelineBoxsymbolLogo">
              <div className="timelinesymbolLogo">
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
              </div>
            </div>

            <div className="projectDesignsymbolLogoBox">
              <p className="DesignsymbolLogoPara">Mockups Images</p>
              <p className="DesignsymbolLogolastSaved">
                Last saved iteration: {timeDifference}
              </p>
            </div>

            <div className="DesignsymbolLogoDesignBox">
              <p className="symbolsParaLogo">
                Here is your Mockups Images.
                <span className="symbolsParaspanLogo">
                  If you like the result continue with the next step.
                </span>
              </p>

              <div className="SymbolismBoxLogo">
                <div className="color12">
                  <div className="row">
                    {mockupsImageData?.map((mock, idx) => {
                      return (
                        <div key={idx} className="col-12 motion_reduce">
                          <div
                            className="mt-2 card w-100"
                            style={{ height: "100%" }}
                          >
                            <img
                              style={{ height: "100%" }}
                              src={mock}
                              className="card-img-top card_image"
                              alt="..."
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="btnsDivBoxx">
                <button
                  onClick={() => {
                    navigate("/brand-assets");
                  }}
                  className="GoBackBtnInsymbolLogo"
                >
                  <span>Go back</span>
                </button>

                <div className="btnsDivBoxxInner">
                  <button
                    onClick={() => {
                      handelSubmitClick();
                    }}
                    className="NextStepBtnInsymbolLogo"
                  >
                    <span>Next step</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}
    </>
  );
};

export default MockupsImage;
