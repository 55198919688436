// reducers/projectRecordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    _id: "",
    user_id: "",
    client_name: "",
    company_name: "",
    city: "",
    zip_code: "",
    country: "",
    vat_no: "",
    createdAt: "",
    updatedAt: "",
    project: []
};
const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        saveClient: (state, action) => {
            const updatedProps = action.payload;
            Object.keys(updatedProps).forEach((key) => {
                if (state.hasOwnProperty(key)) {
                    state[key] = updatedProps[key];
                }
            });
        },
        updateClient: (state, action) => {
            const { property, value } = action.payload;
            state[property] = value;
        },
        deleteClient(state, action) {
            const itemId = action.payload;
            state.items = state.items.filter((item) => item.id !== itemId);
        },
        deleteClientRecord: (state) => {
            return initialState;
        },
    },
});

export const { deleteClient } = clientSlice.actions;
export const { saveClient } = clientSlice.actions;
export const { updateClient } = clientSlice.actions;
export const { deleteClientRecord } = clientSlice.actions;
export default clientSlice.reducer;