import React, { useCallback, useEffect } from "react";
import "./ManageTeams.css";
import ManageTeamsRightBar from "./ManageTeamsRightBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi";
import validationService from "services/validationService";
import toastService from "services/toastService";
import authServices from "services/authServices";
import { useDropzone } from "react-dropzone";

import teamServices from "services/teamServices";
import projectServices from "services/projectServices";
import memberServices from "services/memberServices";
import CircularProgress from "@mui/material/CircularProgress";
import { saveActiveButton } from "app/reducers/activeButton";

const ManageTeams = () => {
  const dispatch = useDispatch();

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: -100 },
    show: { opacity: 1, y: 0 },
  };

  const navigate = useNavigate();
  const [toggleModal, settoggleModal] = useState(false);
  const [selectedProjectId, setselectedProjectId] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isupdateLoading, setisupdateLoading] = useState(false);
  const [EditMemberHandl, setEditMemberHandl] = useState(false);
  const [modalOpenImage, setModalOpenImage] = useState(false);
  const [projectData, setProjectsData] = useState([]);
  const [memberData, setMemberData] = useState({});
  const [error, setErrors] = useState([]);
  const [teamData, setTeamData] = useState({});
  const [memberSelected, setMemberSelected] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSuggestionsRole, setShowSuggestionsRole] = useState(false);
  const [valueRole, setValueRole] = useState("");

  const schema = Joi.object().keys({
    firstName: Joi.string().required().label("firstName"),
    email: Joi.string().required().label("email"),
    lastName: Joi.string().label("lastName").allow(""),
    role: Joi.string().label("role").required(),
    project: Joi.array().allow(""),
    image: Joi.string().allow(""),
  });

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      handelUploadFile(file);
    }
  }, []);

  const { isDragActive } = useDropzone({ onDrop });
  const {
    getRootProps: getAvoidanceRootProps,
    getInputProps: getAvoidanceInputProps,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        alert("Please select a file.");
        return;
      }
      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      if (allowedMimeTypes.includes(file.type)) {
        handelUploadFile(file);
      } else {
        toastService.error(
          "An error occurred while uploading the file. Please try again.",
          2
        );
      }
    }, []),
  });

  const handelUploadFile = async (file) => {
    if (!file) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await projectServices.uploadImage(formData);
      const image = res.data.data.result;
      setMemberData((prevMemberData) => ({ ...prevMemberData, image }));
    } catch (ex) {
      toastService.error(
        "An error occurred while uploading the file. Please try again.",
        2
      );
    }
  };

  const handleChange =
    (setData) =>
    ({ currentTarget: input }) => {
      const updatedData = { ...memberData, [input.name]: input.value };
      const errorMessage = validationService.validateProperty(input, schema);
      setMemberData(updatedData);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [input.name]: errorMessage || "",
      }));
    };

  const handleEditMemberClick = async (memberId) => {
    setMemberData({});
    try {
      setisLoading(true);
      const member = await memberServices.getMemberById(memberId);
      setMemberData(member.data.data.result);
      const user = authServices.getCurrentUser();
      const pro = await projectServices.getProjectByUserId(user.id);
      const projectData = pro.data.data.result.filter(
        (item) => item.status === "inProgress"
      );
      setProjectsData(projectData);
      const memberProjectIds =
        member?.data?.data?.result?.project?.map((proj) => proj._id) || [];
      const projectSelectedData = projectData.filter((project) =>
        memberProjectIds.includes(project._id)
      );
      setSelectedProjects(projectSelectedData?.map((project) => project._id));
      setEditMemberHandl(true);
      setMemberData(member.data.data.result);
    } catch (ex) {
      setisLoading(false);
      toastService.error(ex);
    }
  };

  const handleSubmit = () => {
    const data = {
      project: selectedProjects,
      email: memberData.email,
      firstName: memberData.firstName,
      lastName: memberData.lastName,
      image: memberData.image || "",
      role: memberData.role,
    };
    memberData.project = selectedProjects;
    const errors = validationService.validate(data, schema);
    setErrors(errors || {});
    if (errors) return;
    else {
      doMemberSubmit(data);
    }
  };

  const doMemberSubmit = async (data) => {
    try {
      setisupdateLoading(true);
      const res = await memberServices.updateMemberById(memberData._id, data);
      window.location.reload();
    } catch (ex) {
      setisupdateLoading(false);
      toastService.error(ex);
    }
  };

  const handelDelete = async () => {
    try {
      const res = await memberServices.deleteMemberById(memberSelected);
      window.location.reload();
    } catch (ex) {
      toastService.error(ex);
    }
  };

  const handleInputChangeRole = (event) => {
    setValueRole(event.target.value);
    setShowSuggestionsRole(true);
  };

  const handleSuggestionClickRole = (suggestion) => {
    setValueRole(suggestion);
    setMemberData({ ...memberData, role: suggestion });
    setShowSuggestionsRole(false);
  };

  const handleYellowBoxClickRole = () => {
    setShowSuggestionsRole(!showSuggestionsRole);
  };

  const suggestionsRole = ["Admin", "Collaborator"];
  const filteredSuggestionsRole = suggestionsRole.filter((suggestion) =>
    suggestion.toLowerCase().includes(valueRole.toLowerCase())
  );

  const handleCardClick = (memberId) => {
    if (selectedMemberId === memberId) {
      setSelectedMemberId(null);
    } else {
      setSelectedMemberId(memberId);
    }
  };

  const handleProjectClick = () => {
    if (selectedProjectId) {
      const projectId = selectedProjectId._id;
      if (selectedProjects.includes(projectId)) {
        setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
      } else {
        setSelectedProjects([...selectedProjects, projectId]);
      }
      settoggleModal(false);
    }
  };

  const handleToggle = (project) => {
    setselectedProjectId(project);
    settoggleModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const user = authServices.getCurrentUser();
        const user_id = user.id;
        const team = await teamServices.getTeamAndMemberByUserId(user_id);
        const teamDataApi = team.data.data.result;
        if (team?.data?.data.result) {
          setTeamData(teamDataApi);
          setLoading(false);
        }
      } catch (ex) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="ManageTeamsContainer">
      {loading ? (
        <div className="ManageTeamsLeftSideLoader">
          <CircularProgress color="inherit" />{" "}
        </div>
      ) : (
        <>
          {!EditMemberHandl ? (
            <div className="ManageTeamsLeftSide">
              <span className="MyteamPara">{teamData.name}</span>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="show"
                className="nameMemberCardsDiv"
              >
                {teamData?.member?.map((member) => (
                  <motion.div
                    onClick={() => handleCardClick(member._id)}
                    style={{
                      border:
                        member._id === selectedMemberId
                          ? "2px solid red"
                          : "none",
                    }}
                    variants={cardVariants}
                    className="nameMemeberCard"
                    key={member._id}
                  >
                    <div className="nameMemeberCardCircle">
                      <img src={member.image} alt="" />
                    </div>
                    <p className="newMemberPara">
                      {member.firstName} {member.lastName}
                    </p>
                    <p className="memberRoleDef">{member.role}</p>
                    <p className="lastLoginParabox"></p>
                    <p className="CollaboratedProjectPara">
                      Collaborated projects:{" "}
                      <span className="CollaboratedProjectParaSpan">
                        {member?.project?.length}
                      </span>
                    </p>
                    <div className="LineManageTeams"></div>
                    <p className="workingParaManageTeams">Working on</p>
                    <div className="workingOnParaBox">
                      {member?.project && member?.project?.length > 0 ? (
                        member?.project?.map((project, projectIndex) => (
                          <p
                            className={
                              projectIndex % 2 === 0
                                ? "projectNameParaManageTeam"
                                : "projectNameParaManageTeam2"
                            }
                            key={projectIndex}
                          >
                            {project.name}
                          </p>
                        ))
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setMemberSelected(member._id);
                        handleEditMemberClick(member._id);
                      }}
                      className="EditMemberBtns"
                      disabled={isLoading}
                    >
                      <svg
                        className="EditMemberBtnsvg"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5"
                          stroke="#D1D1D1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.5 3.00023C18.8978 2.6024 19.4374 2.37891 20 2.37891C20.5626 2.37891 21.1022 2.6024 21.5 3.00023C21.8978 3.39805 22.1213 3.93762 22.1213 4.50023C22.1213 5.06284 21.8978 5.6024 21.5 6.00023L12 15.5002L8 16.5002L9 12.5002L18.5 3.00023Z"
                          stroke="#D1D1D1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {isLoading ? (
                        <CircularProgress
                          style={{ width: 24, height: 24, color: "white" }}
                          color="primary"
                          variant="indeterminate"
                        />
                      ) : (
                        "Edit member"
                      )}
                    </button>
                  </motion.div>
                ))}
              </motion.div>

              {teamData?.member?.length === 0 && (
                <div className="dontHaveanyteamDiv">
                  You have no active team members, <br />
                  <span className="dontHaveanyProjectDivSpan">
                    Click on “New member” to create one.
                  </span>
                </div>
              )}

              <button
                onClick={() => {
                  navigate("/createmember");
                  dispatch(saveActiveButton("Team"));
                }}
                className="NewProjectCreate"
              >
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 4.5H4.5C3.96957 4.5 3.46086 4.71071 3.08579 5.08579C2.71071 5.46086 2.5 5.96957 2.5 6.5V20.5C2.5 21.0304 2.71071 21.5391 3.08579 21.9142C3.46086 22.2893 3.96957 22.5 4.5 22.5H18.5C19.0304 22.5 19.5391 22.2893 19.9142 21.9142C20.2893 21.5391 20.5 21.0304 20.5 20.5V13.5"
                    stroke="#363538"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19 3.00023C19.3978 2.6024 19.9374 2.37891 20.5 2.37891C21.0626 2.37891 21.6022 2.6024 22 3.00023C22.3978 3.39805 22.6213 3.93762 22.6213 4.50023C22.6213 5.06284 22.3978 5.6024 22 6.00023L12.5 15.5002L8.5 16.5002L9.5 12.5002L19 3.00023Z"
                    stroke="#363538"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span
                  style={{
                    lineHeight: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "-4px",
                  }}
                >
                  New member
                </span>
              </button>
            </div>
          ) : (
            <div className="ManageTeamsLeftSide">
              <span className="MyteamPara">My team / Edit member</span>

              <div className="mainFormTeamDiv">
                <div className="manageTeaminput">
                  <div className="firstNameDivBoxTeams">
                    <p className="companyNameparaCityTeam">First name</p>
                    <input
                      placeholder="First name"
                      name="firstName"
                      onChange={handleChange(setMemberData)}
                      value={memberData.firstName}
                      className="companyaddressparacityTeam"
                      type="text"
                    />
                    {error.firstName && (
                      <small className="text-danger mx-1">
                        first name is not allowed to be empty
                      </small>
                    )}
                  </div>
                  <div className="lastNameDivBoxTeams">
                    <p className="companyNameparaCityTeam">Last name</p>
                    <input
                      placeholder="Lemans"
                      name="lastName"
                      onChange={handleChange(setMemberData)}
                      value={memberData.lastName}
                      className="companyaddressparacityTeam"
                      type="text"
                    />
                    {error.lastName && (
                      <small className="text-danger mx-1">
                        Last name is not allowed to be empty
                      </small>
                    )}
                  </div>
                </div>
                <div className="manageTeaminput">
                  <div className="emailNameDivBoxTeams">
                    <p className="companyNameparaCityTeam">E-mail</p>
                    <input
                      readOnly
                      placeholder="robert@brandfy.ai"
                      name="email"
                      onChange={handleChange(setMemberData)}
                      value={memberData.email}
                      className="companyaddressparacityTeam"
                      type="text"
                    />
                    {error.email && (
                      <small className="text-danger mx-1">{error.email}</small>
                    )}
                  </div>
                  <div className="lastNameDivBoxTeams">
                    <div className="roleNameInputBox">
                      <p className="companyNameparaCityTeam">
                        Role{" "}
                        <span className="companyNameparaCityTeamspan">*</span>
                      </p>
                      <div
                        className={`boxme2RoleCountry ${
                          showSuggestionsRole ? "show-suggestions2Role" : ""
                        }`}
                      >
                        <input
                          className="inputForRoleBox2Role"
                          type="text"
                          value={memberData.role}
                          onClick={handleYellowBoxClickRole}
                          onChange={handleInputChangeRole}
                          placeholder="Select Role"
                        />
                        {valueRole ? (
                          <svg
                            onClick={() => {
                              setValueRole("");
                              setMemberData({ ...memberData, role: "" });
                            }}
                            className="search-iconManageName"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 6L6 18"
                              stroke="#FD5D5D"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6 6L18 18"
                              stroke="#FD5D5D"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                        {showSuggestionsRole &&
                          filteredSuggestionsRole.length > 0 && (
                            <ul className="suggestion-list2Roll">
                              {filteredSuggestionsRole?.map(
                                (suggestion, index) => (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      handleSuggestionClickRole(suggestion)
                                    }
                                  >
                                    {suggestion}
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                        <div
                          className="yellowBox2Role"
                          onClick={handleYellowBoxClickRole}
                        >
                          <svg
                            onClick={handleYellowBoxClickRole}
                            className={`arrow-icon ${
                              showSuggestionsRole ? "up-arrow" : "down-arrow"
                            }`}
                            width="22"
                            height="14"
                            viewBox="0 0 22 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      </div>
                      {error.role && (
                        <small className="text-danger mx-1">
                          Role is not allowed to be empty
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  {...getAvoidanceRootProps()}
                  className="describeButton"
                  style={{
                    marginTop: "20px",
                    background: "none",
                    border: "1px solid #484848",
                  }}
                >
                  {memberData.image && (
                    <img name="image" src={memberData.image} alt="User" />
                  )}
                  <input {...getAvoidanceInputProps()} type="file" />
                  {!memberData.image && (
                    <p>
                      {isDragActive ? (
                        "Drop the files here ..."
                      ) : (
                        <button className="addImageInProject">
                          Add images
                        </button>
                      )}
                    </p>
                  )}
                </div>
                <div
                  className="buttonsBtnteam"
                  style={{ marginTop: "20px" }}
                ></div>
                <div className="dottedLine"></div>
                <p className="assignedProjectsBoxParaTeam">
                  Assigned projects
                  <span className="assignedProjectsBoxParaSpanTeam">
                    {projectData.length === 0
                      ? "(There are no projects created to assign to the user)"
                      : "(Click to assign projects)"}
                  </span>
                </p>

                <div className="assignedProjectDivBox">
                  {projectData?.map((project) => (
                    <span
                      key={project._id}
                      className={`projectNumassigne ${
                        selectedProjects.includes(project._id) ? "active" : ""
                      }`}
                      onClick={() => handleToggle(project, project._id)}
                    >
                      {project.name}
                    </span>
                  ))}
                </div>
                <div className="dottedLine"></div>
                <div className="buttonsBtnteam1">
                  <button
                    onClick={() => {
                      setModalOpenImage(true);
                    }}
                    className="DeleteNewClientteam"
                  >
                    <svg
                      className="saveNewClientsvg"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.5 6.5H5.5H21.5"
                        stroke="#484848"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.5 6.5V20.5C19.5 21.0304 19.2893 21.5391 18.9142 21.9142C18.5391 22.2893 18.0304 22.5 17.5 22.5H7.5C6.96957 22.5 6.46086 22.2893 6.08579 21.9142C5.71071 21.5391 5.5 21.0304 5.5 20.5V6.5M8.5 6.5V4.5C8.5 3.96957 8.71071 3.46086 9.08579 3.08579C9.46086 2.71071 9.96957 2.5 10.5 2.5H14.5C15.0304 2.5 15.5391 2.71071 15.9142 3.08579C16.2893 3.46086 16.5 3.96957 16.5 4.5V6.5"
                        stroke="#484848"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 11.5V17.5"
                        stroke="#484848"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5 11.5V17.5"
                        stroke="#484848"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Delete member
                  </button>
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="saveNewClientteam"
                  >
                    <svg
                      className="saveNewClientsvg"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5 21.5H5.5C4.96957 21.5 4.46086 21.2893 4.08579 20.9142C3.71071 20.5391 3.5 20.0304 3.5 19.5V5.5C3.5 4.96957 3.71071 4.46086 4.08579 4.08579C4.46086 3.71071 4.96957 3.5 5.5 3.5H16.5L21.5 8.5V19.5C21.5 20.0304 21.2893 20.5391 20.9142 20.9142C20.5391 21.2893 20.0304 21.5 19.5 21.5Z"
                        stroke="#484848"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 21.5V13.5H7.5V21.5"
                        stroke="#484848"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 3.5V8.5H15.5"
                        stroke="#484848"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {isupdateLoading ? (
                      <CircularProgress
                        style={{ width: 24, height: 24, color: "white" }}
                        color="primary"
                        variant="indeterminate"
                      />
                    ) : (
                      <span style={{ marginTop: "2px", display: "block" }}>
                        Save
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className="ManageTeamsRightSide">
        <ManageTeamsRightBar
          EditMemberHandl={EditMemberHandl}
          memberId={selectedMemberId}
        />
      </div>
      {modalOpenImage && (
        <div
          onClick={() => {
            setModalOpenImage(false);
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <div className="modalBoxTeam">
            <div className="areYouSure">
              Are you sure you want to delete this member?
            </div>
            <p className="allprojectItration">
              All the projects
              <span className="allprojectItrationspan">
                and iterations produced by this user will be transferred to the
                account
              </span>
              Owner.
            </p>
            <div className="divbuttonbtns">
              <button onClick={handelDelete} className="DeleteMemberTeam">
                DELETE
              </button>
              <button className="cancelMemberTeam">CANCEL</button>
            </div>
          </div>
        </div>
      )}

      {toggleModal && (
        <div
          onClick={() => {
            settoggleModal(false);
          }}
          className="assign_project"
        >
          <div className="modal_box border-none">
            <div className="areYouSure">
              Do you really want to assign this project to a new client?
            </div>
            <p className="allprojectItration"></p>
            <div className="divbuttonbtns">
              <button onClick={handleProjectClick} className="DeleteMemberTeam">
                Yes
              </button>
              <button
                onClick={() => {
                  settoggleModal(false);
                }}
                className="cancelMemberTeam"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ManageTeams;
