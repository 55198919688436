// reducers/projectRecordSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  currentProject: "",
  singleProject: {},
  selectedIterationId: "",
  textureImageData: {},
  humnas: {},
  placeData: {},
  conceptImage: {},

  textureWithLogo: {},
  humanWithLogo: {},
  placeWithLogo: {},
  conceptWithLogo: {},
  mockups: {},
  pdfBook: {},
};

const projectEditSlice = createSlice({
  name: "projectEdit",
  initialState,
  reducers: {
    addProject: (state, action) => {
      state.currentProject = action.payload._id;
      const existingProject = state.projects.findIndex(
        (project) => project._id === action.payload._id
      );
      if (existingProject!==-1) {
        state.projects[existingProject].iteration= action.payload.iteration       
      }
      else{
        state.projects.push(action.payload);
      }
    },
    updateCurrentProjectId: (state, action) => {    
      state.currentProject = action.payload;
    },
    updateBriefingKey: (state, action) => {  
      const { key, value } = action.payload;
      const projectId = state.currentProject;
      const iterationId = state.selectedIterationId;
      const project = state.projects.find((p) => p._id === projectId);
      if (project) {
        const iteration = project.iteration.find(
          (it) => it._id === iterationId
        );
        if (iteration.briefing) {
          iteration.briefing[key] = value;
        }
      }
    },
    updateProjectProperty: (state, action) => {
      const projectToUpdate = state.projects.find(
        (project) => project._id === action.payload._id
      );
      const iterationId = state.selectedIterationId;
      if (projectToUpdate) {
        const iteration = projectToUpdate.iteration.find(
          (it) => it._id === iterationId
        );
        if (iteration) {
          iteration[action.payload.key] = action.payload.value;
        }
      }
    },

    updateCompleteProject: (state, action) => {
      const index = state.projects.findIndex(
        (project) => project?._id === action?.payload?._id
      );
      if (index !== -1) {
        state.projects[index] = action?.payload;
        state.singleProject= action?.payload;
      }
    },

    deleteProject: (state, action) => {
      state.projects = state.projects.filter(
        (project) => project._id !== action.payload
      );
    },

    getProjectById: (state, action) => {
      const { projectId, iteration_id } = action?.payload;

      // Find the project by projectId
      const foundProject = state.projects.find(
        (project) => project._id === projectId
      );

      // If a project is found
      if (foundProject) {
        // If the found project has iterations, find the specific iteration by iteration_id
        if (Array.isArray(foundProject.iteration)) {
          const foundIteration = foundProject.iteration.find(
            (iteration) => iteration._id === iteration_id
          );
          // Assign the found iteration to singleProject state
          state.singleProject = foundIteration || null; // Assign null if the iteration is not found
        } else {
          // If there are no iterations, just assign the project to singleProject
          state.singleProject = foundProject;
        }
      } else {
        // If no project is found, set singleProject to null
        state.singleProject = null;
      }
    },

    getNonIterationProjectById: (state, action) => {
      const { projectId } = action.payload;
      const foundProject = state.projects.find(
        (project) => project._id === projectId
      );
      state.singleProject = foundProject;
    },

    deleteSelectedProject: (state) => {
      state.currentProject = "";
      state.singleProject = {};
    },
    deleteAllProjectsData: (state) => {
      state.projects = [];
      state.currentProject = "";
      state.singleProject = {};
    },
    renewProject: (state, action) => {
      const updatedProject = action.payload;
      const existingIndex = state.projects.findIndex(
        (project) => project._id === updatedProject._id
      );
      if (existingIndex !== -1) {
        const existingProject = state.projects[existingIndex];
        if (existingProject.updatedAt < updatedProject.updatedAt) {
          state.projects[existingIndex] = updatedProject;
          if (state.singleProject._id === updatedProject._id) {
            state.singleProject = updatedProject;
          }
        }
      }
    },
    selectedIteration: (state, action) => {
      state.selectedIterationId = action.payload;
    },
    deleteIteration: (state) => {
      state.selectedIterationId = "";
    },

    // Generate Images
    saveTextured: (state, action) => {
      state.textureImageData = action.payload;
    },

    saveHumnas: (state, action) => {
      state.humnas = action.payload;
    },

    savePlace: (state, action) => {
      state.placeData = action.payload;
    },

    saveConceptImage: (state, action) => {
      state.conceptImage = action?.payload;
    },

    // With Logo Slices
    saveTexturedWithLogo: (state, action) => {
      state.textureWithLogo = action.payload;
    },
    saveHumanWithLogo: (state, action) => {
      state.humanWithLogo = action.payload;
    },
    saveConceptWithLogo: (state, action) => {
      state.conceptWithLogo = action.payload;
    },
    savePlaceWithLogo: (state, action) => {
      state.placeWithLogo = action.payload;
    },
    saveMockups: (state, action) => {
      state.mockups = action.payload;
    },

    savePdfBook: (state, action) => {
      state.pdfBook = action.payload;
    },
  },
});
export const {
  updateProjectProperty,
  getNonIterationProjectById,
  updateCurrentProjectId,
  updateCompleteProject,
  deleteAllProjectsData,
  renewProject,
  deleteProject,
  selectedIteration,
  deleteIteration,
  getProjectById,
  addProject,
  updateBriefingKey,
  deleteSelectedProject,

  // Generate Images
  saveTextured,
  saveHumnas,
  savePlace,
  saveConceptImage,
  saveMockups,
  // Save Logo
  saveTexturedWithLogo,
  saveConceptWithLogo,
  saveHumanWithLogo,
  savePlaceWithLogo,
  savePdfBook,
} = projectEditSlice.actions;
export default projectEditSlice.reducer;
