import { useEffect } from "react";
import "./ForgotPassword.css";
import React, { useState, useRef } from "react";
import validationService from "services/validationService";
import Joi from "joi";
import authServices from "services/authServices";
import { useNavigate } from "react-router-dom";
import toastService from "services/toastService";
const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [ForgotPassOpen, setForgotPassOpen] = useState(true);
  const [OTPOpen, setOTPOpen] = useState(false);
  const [dbotp, setDbotp] = useState();
  const [NewPasswordOpen, setNewPasswordOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [confirm_email, setConfirm_Email] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPsdError] = useState("");
  const [errors, setErrors] = useState({});
  const [password, setNewPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [submitError, setSubmitError] = useState();
  const [getOtpError, setGetOtpError] = useState("");
  const [otpError, setOtpError] = useState("");
  const emailSchema = Joi.object().keys({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    confirm_email: Joi.string().required(),
  });
  const passwordSchema = Joi.object().keys({
    password: Joi.string()
      .required()
      .label("Password")
      .custom((value, helpers) => {
        if (
          !value.match(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/
          )
        ) {
          return helpers.message(
            "{{#label}} should be minimum 8 and maximum 30 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
          );
        }
        return value;
      }),
    confirm_password: Joi.string().required(),
  });
  const handleChange =
    (setData) =>
      ({ currentTarget: input }) => {
        setGetOtpError("");
        const errorMessage = validationService.validateProperty(
          input,
          emailSchema
        );
        if (errorMessage) errors[input.name] = errorMessage;
        else errors[input.name] = "";
        setErrors(errors);
        setData(input.value);
      };
  const handlePsdChange =
    (setData) =>
      ({ currentTarget: input }) => {
        setSubmitError("");
        const errorMessage = validationService.validateProperty(
          input,
          passwordSchema
        );
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        setErrors(errors);
        setData(input.value);
      };
  const handleConfirmEmailChange = (e) => {
    setGetOtpError("");
    setConfirm_Email(e.target.value);
    if (e.target.value !== email) {
      setEmailError("Email do not match");
    } else {
      setEmailError("");
    }
  };
  const handleConfirmPsdChange = (e) => {
    setConfirm_password(e.target.value);
    if (e.target.value !== password) {
      setPsdError("Password do not match");
    } else {
      setPsdError("");
    }
  };
  const sendOtp = async () => {
    setLoading(true);
    const data = { email };
    const errors = validationService.validate(data, emailSchema);
    setErrors(errors || {});
    if (errors && Object.keys(errors).length > 0) {
      setLoading(false);
    }
    try {
      const response = await authServices.generateOtp(data);

      setDbotp(response.data.message.res);
      setForgotPassOpen(false);

      setOTPOpen(true);

      setLoading(false);
    } catch (ex) {
      toastService.error(ex);
      setLoading(false);
    }
  };

  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpInputRefs = useRef([]);

  useEffect(() => {
    otpInputRefs.current = otp.map(
      (_, i) => otpInputRefs.current[i] ?? React.createRef()
    );
  }, [otp]);

  useEffect(() => {
    otp.forEach((digit, index) => {
      if (
        digit !== "" &&
        index < otp.length - 1 &&
        otpInputRefs.current[index + 1].current
      ) {
        otpInputRefs.current[index + 1].current.focus();
      }
    });
  }, [otp]);

  const handleOTPChange = (e, index) => {
    let value = e.target.value;

    // Check if the event is a paste event
    if (e.type === "paste") {
      value = e.clipboardData.getData("text").trim();
      // Check if the pasted value is numeric and has the correct length
      if (value.length === otp.length && /^\d+$/.test(value)) {
        // Distribute the pasted value into the OTP array
        const newOtp = value.split("");
        setOtp(newOtp);
        // Optionally, move focus to the submit button or elsewhere if needed
        return;
      }
    } else {
      // This is for regular input changes
      setOtp(otp.map((data, i) => (i === index ? value : data)));
    }

    setOtpError("");
  };

  const handelOtpSubmit = () => {
    setLoading(true);
    const concatenatedString = otp.join("");
    const numberValue = parseInt(concatenatedString, 10);
    if (numberValue == dbotp) {
      setForgotPassOpen(false);
      setOTPOpen(false);
      setNewPasswordOpen(true);

      setLoading(false);
    } else {
      toastService.error("Enter valid otp");
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const submitPsd = async () => {
    setLoading(true);
    const data = { email, password, confirm_password };
    const errors = validationService.validate(data, passwordSchema);
    setErrors(errors || {});
    try {
      const res = await authServices.ResetPassword(data);
      toastService.success("Your password has been successfully reset.");
      navigate("/");
      setLoading(false);
    } catch (ex) {
      toastService.error(ex);
      setLoading(false);
    }
  };

  return (
    <>
      {" "}
      <div className="LoginContainerForgot">
        <div className="leftSideFormBoxForgot">
          <div className="BrandfyLogoLoginForgot">
            <img src="brandfy_black.svg" alt="" />
          </div>

          {ForgotPassOpen && (
            <div className="loginFormBoxForgot">
              <p className="ForgotPasswordPara">Forgot your password?</p>
              <p className="enterOPTPara">
                {" "}
                Enter your Email and get OTP to verification.
              </p>

              <input
                type="text"
                placeholder="Email"
                name="email"
                value={email}
                className="EmailForgot"
                onCopy={(e) => {
                  e.preventDefault();
                }}
                onChange={handleChange(setEmail)}
              />
              {errors.email && (
                <span className="forgetPageError">{errors.email}</span>
              )}
              <input
                type="text"
                placeholder="Repeat email"
                value={confirm_email}
                name="confirm_email"
                className="EmailForgot"
                onChange={handleConfirmEmailChange}
              />
              {emailError && (
                <span className="forgetPageError">{emailError}</span>
              )}
              <button
                disabled={loading}
                onClick={() => {
                  sendOtp();
                }}
                className="OTPSend"
              >
                {loading ? (
                  <div className="spinner"></div>
                ) : (
                  <span className="login_text">GET OTP</span>
                )}
              </button>
              {getOtpError && (
                <span className="forgetpageErrorbtn">{getOtpError}</span>
              )}
            </div>
          )}

          {OTPOpen && (
            <div className="loginFormBoxTPO">
              <p className="ForgotPasswordOTPPara">Enter OTP</p>
              <p className="enterOPTPara">
                {" "}
                Send OTP on <span className="sendOPTSpan">{email}</span>{" "}
              </p>

              <div className="containerOTP">
                <form>
                  <div className="userInputOTP">
                    {otp.map((data, index) => (
                      <input
                        className="inputOTP"
                        placeholder="-"
                        type="text"
                        key={index}
                        value={data}
                        maxLength="1"
                        ref={otpInputRefs.current[index]}
                        onPaste={(e) => handleOTPChange(e, index)}
                        onChange={(e) => handleOTPChange(e, index)}
                      />
                    ))}
                  </div>
                  {otpError && (
                    <span className="forgetpageErrorbtn">{otpError}</span>
                  )}
                </form>
              </div>

              <button
                onClick={() => {
                  handelOtpSubmit();
                }}
                className="OTPSendSubmit"
              >
                {loading ? <div className="spinner"></div> : "SUBMIT"}
              </button>
            </div>
          )}

          {NewPasswordOpen && (
            <div className="loginFormBoxNewPassword">
              <p className="ForgotPasswordNewPara">Enter your new password</p>
              <p className="enterNewPara">
                This is the last step in recovering your password.{" "}
              </p>

              <input
                type="password"
                placeholder="Password"
                name="password"
                className="writePassword"
                onChange={handlePsdChange(setNewPassword)}
              />
              {errors.password && (
                <span className="forgetPageError">{errors.password}</span>
              )}
              <input
                type="password"
                placeholder="New password"
                name="confirm_password"
                className="EmailForgot"
                onChange={handleConfirmPsdChange}
              />
              {passwordError && (
                <span className="forgetPageError">{passwordError}</span>
              )}
              <button
                className="ResetPasswordSubmit"
                onClick={(e) => {
                  e.preventDefault();
                  submitPsd();
                }}
              >
                {loading ? <div className="spinner"></div> : "SUBMIT"}
              </button>
              {submitError && (
                <span className="forgetpageErrorbtn">{submitError}</span>
              )}
              <p className="thisEnd">This is the end!</p>
              <p className="afterEnterPara">
                After entering the new password you will gain access to your
                account.
              </p>
            </div>
          )}
        </div>

        <div className="RightSideFormBoxForgot">
          <img src="thinkPic.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
