import { AvatarGroup } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setInputValue } from "../../features/InputValue/Inputvalue";
import { useMemo, useState } from "react";
import React, { useEffect } from "react";

import "./dashboard.css";
import moment from "moment";
import RightBar from "./RightBar";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import authServices from "services/authServices";
import projectServices from "services/projectServices";
import CircularProgress from "@mui/material/CircularProgress";
import { saveActiveButton } from "app/reducers/activeButton";
import toastService from "services/toastService";
import memberServices from "services/memberServices";
import {
  addProject,
  deleteIteration,
  deleteSelectedProject,
  getProjectById,
  selectedIteration,
  updateCurrentProjectId,
} from "app/reducers/projectEditSlice";
import { EditItration } from "app/reducers/EditItration";

export function Dashboard() {
  const planDb = useSelector((state) => state?.planDb);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(deleteSelectedProject());
  dispatch(deleteIteration());

  dispatch(EditItration(false));
  const [completedProject, setCompletedProject] = useState([]);
  const [inProgressProject, setInProgressProject] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 8;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = authServices?.getCurrentUser();
    getProject(user?.id);
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchAndProcessProjects = async (userId) => {
    const personalDataRes = await projectServices?.getProjectByUserId(userId);
    const memberDataRes = await memberServices?.getProjectsAsMember(userId);
    const personalProjects = personalDataRes?.data?.data?.result;
    const memberProjects =
      memberDataRes &&
      memberDataRes?.data?.data?.result?.map((item) => ({
        ...item?.project,
      }));
    const combinedProjects = [...personalProjects, ...memberProjects];
    combinedProjects?.map((project) => {
      dispatch(addProject(project));
    });
    for (let project of combinedProjects) {
      const members = await memberServices?.getMemberByProject(project?._id);
      project.members =
        (members && members?.data && members?.data?.data?.result) || [];
    }
    return processProjectsData(combinedProjects);
  };

  const processProjectsData = (data) => {
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return data.map((project, index) => ({
      ...project,
      projectNo: index + 1,
    }));
  };

  const getProject = async (id) => {
    const projectsWithProjectNo = await fetchAndProcessProjects(id);
    const inProgressProjects = projectsWithProjectNo?.filter(
      (project) => project?.status === "inProgress"
    );
    setCompletedProject(projectsWithProjectNo);
    setInProgressProject(inProgressProjects);
    setLoading(false);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, x: -200, scale: 0.5 },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const totalPages = useMemo(() => {
    return Math.ceil(inProgressProject.length / projectsPerPage);
  }, [inProgressProject, projectsPerPage]);

  const projectsForPage = useMemo(() => {
    const start = (currentPage - 1) * projectsPerPage;
    const end = start + projectsPerPage;
    return inProgressProject.slice(start, end);
  }, [inProgressProject, currentPage, projectsPerPage]);

  const handelNewProjectClick = () => {
    if (planDb?.remaining_project + planDb?.extra_project > 0) {
      navigate("/dashboard/createnewproject");
    } else {
      toastService.error("Please subscribe to a plan or buy projects");
      navigate("/billing");
    }
  };

  const stepToRoute = {
    1: "projectUpdate",
    2: "designbriefing",
    3: "designtypography",
    4: "colorpalette",
    5: "symbolism",
    6: "symbolismlogo",
    7: "brand-assets",
    8: "mockups",
    9: "resultstarter",
  };

  const handelIterationClick = (projectid, iterationid, step) => {
    dispatch(updateCurrentProjectId(projectid));
    dispatch(
      getProjectById({ projectId: projectid, iteration_id: iterationid })
    );
    dispatch(selectedIteration(iterationid));
    navigate(`/${route(step)}`);
  };
  const route = (step) => stepToRoute[step];

  return (
    <div className="dashboardContainer">
      <div className="leftSideDashboard">
        <div>
          {loading ? (
            <div className="leftSideDashboardLoading">
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <motion.div
              className={
                projectsForPage?.length > 0 ? "projectsDivBox" : "anotherClass"
              }
              variants={containerVariants}
              initial="hidden"
              animate="show"
            >
              {projectsForPage?.length > 0 ? (
                projectsForPage.map((card) => {
                  const updatedAtTimestamp = card?.updatedAt;
                  const updatedAtDate = new Date(updatedAtTimestamp);

                  {
                    /* const day = updatedAtDate
                    .getDate()
                    .toString()
                    .padStart(2, "0");
                  const month = (updatedAtDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = updatedAtDate.getFullYear().toString().slice(-2);
                  const formattedDate = `${day}.${month}.${year}`; */
                  }

                  return (
                    <motion.div
                      key={card?._id}
                      className="projectNameCardBox"
                      variants={cardVariants}
                      onClick={() => {
                        dispatch(EditItration(true));
                        handelIterationClick(
                          card?._id,
                          card?.iteration?.[card?.iteration?.length-1]?._id,
                          card?.iteration?.[card?.iteration?.length-1]?.iterate_step
                        );
                      }}
                    >
                      <div
                        className="CardPic"
                        style={{
                          backgroundColor: `${card?.color_palette?.main[0]?.Hex}`,
                        }}
                      >
                        {card?.result?.fullLogo ? (
                          <img
                            className="projectSinnerImageLogo"
                            style={{
                              objectFit: "contain",
                              padding: "5px",
                              height: "200px",
                            }}
                            src={card?.result?.fullLogo}
                            alt=""
                          />
                        ) : (
                          <img src="BrandfySmallIcon.png" alt="" />
                        )}
                      </div>
                      <p className="ProjectNameCard">{card?.name}</p>
                      <p className="ClientNameCard">{card?.client_name}</p>

                      <div className="avatarBoxGround">
                        <AvatarGroup
                          style={{ display: "flex", flexDirection: "row" }}
                          className="avatarGround"
                        >
                          {card?.members?.map((member) =>
                            member.image ? (
                              <img
                                className="projectsAvatarBoxdivcircle"
                                src={member?.image}
                                alt="member"
                                key={member?._id}
                              />
                            ) : (
                              <div></div>
                            )
                          )}
                        </AvatarGroup>
                        <div className="UPdateAndIteration">
                          <p className="LastUpdatePara">
                            Last update:{" "}
                            {moment(updatedAtDate)?.format("DD-MM-YYYY")}
                            {/* {moment(formattedDate).format("DD-MM-YYYY")} */}
                          </p>
                          <p className="LastIterationPara">
                            Iteration:{" "}
                            <span className="IterationPoint">
                              {card?.iteration?.length}/10
                            </span>
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  );
                })
              ) : (
                <div className="dontHaveanyProjectDiv">
                  You don't have any project, yet.<br></br>
                  <span className=""> Go ahead and start your journey!</span>
                </div>
              )}
            </motion.div>
          )}
        </div>

        <button
          onClick={() => {
            handelNewProjectClick();
            dispatch(saveActiveButton("Projects"));
            dispatch(setInputValue(""));
          }}
          className="NewProjectCreate"
        >
          <svg
            style={{ width: "24px", height: "24px" }}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 4.5H4.5C3.96957 4.5 3.46086 4.71071 3.08579 5.08579C2.71071 5.46086 2.5 5.96957 2.5 6.5V20.5C2.5 21.0304 2.71071 21.5391 3.08579 21.9142C3.46086 22.2893 3.96957 22.5 4.5 22.5H18.5C19.0304 22.5 19.5391 22.2893 19.9142 21.9142C20.2893 21.5391 20.5 21.0304 20.5 20.5V13.5"
              stroke="#363538"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 3.00023C19.3978 2.6024 19.9374 2.37891 20.5 2.37891C21.0626 2.37891 21.6022 2.6024 22 3.00023C22.3978 3.39805 22.6213 3.93762 22.6213 4.50023C22.6213 5.06284 22.3978 5.6024 22 6.00023L12.5 15.5002L8.5 16.5002L9.5 12.5002L19 3.00023Z"
              stroke="#363538"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span
            style={{
              lineHeight: "28px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "-4px",
            }}
          >
            New Project
          </span>
        </button>

        <div className="pagination">
          {[...Array(totalPages)]?.map((e, i) => (
            <li className="pagination__item" key={i}>
              <button
                className={`pagination__link ${
                  currentPage === i + 1 ? "pagination__link--active" : ""
                }`}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </button>
            </li>
          ))}
        </div>
      </div>
      <div className="RightSideDashboard">
        <RightBar />
      </div>
    </div>
  );
}
