import { Home } from 'views/website/home';
import ForgotPassword from 'views/website/ForgotPassword'
import SignUp from 'views/website/SignUp'
import AccountVerification from 'components/ourComponents/verifyUser/accountVerification';
import { Terms } from 'views/website/terms';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: 'Brandfy'
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Brandfy'
  },
  {
    path: '/forgotpassword',
    view: ForgotPassword,
    layout: 'home',
    title: 'ForgotPassword'
  },
  {
    path: '/user/:id/verify/:token',
    view: AccountVerification,
    layout: 'home',
    title: 'ForgotPassword'
  },
  {
    path: '/signup',
    view: SignUp,
    layout: 'home',
    title: 'SignUp'
  },
]

export default Routes;