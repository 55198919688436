import React, { useEffect, useState } from "react";
import "./TypographyWeight.css";
import RightBar from "views/dashboard/RightBar";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import projectServices from "services/projectServices";
import toastService from "services/toastService";
import {
  getProjectById,
  selectedIteration,
  updateCompleteProject,
  updateProjectProperty,
} from "app/reducers/projectEditSlice";
import Loading from "../magic/loading";
import { EditItration } from "app/reducers/EditItration";

const TypographyWeight = () => {
  const dispatch = useDispatch();

  const SelectedProjectId = useSelector(
    (state) => state.projectEdit.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state.projectEdit.selectedIterationId
  );
  let projectRecordData = useSelector(
    (state) => state.projectEdit.singleProject
  );

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );

  let Weight = [];
  if (projectRecordData.selected_font_Provider === "pangram pangram") {
    Weight = projectRecordData.weight;
  } else {
    Weight = projectRecordData.weight2;
  }

  const [loading, setLoading] = useState(false);
  const [selectedWeight, setSelectedWeight] = useState(
    projectRecordData?.selected_weight || projectRecordData?.weight[0]
  );

  const itration = useSelector((state) => state.EditItrationNo.itration);
  const handelSubmit = async () => {
    try {
      setLoading(true);
      const body = {
        selected_weight: selectedWeight,
        iteration_id: SelectedIterationId,
        iterate_step: 3,
      };
      const res = await projectServices.updateProject(SelectedProjectId, body);
      dispatch(
        updateProjectProperty({
          _id: SelectedProjectId,
          key: "selected_weight",
          value: selectedWeight,
        })
      );
      if (itration) {
        const clr = await projectServices.generateColor(
          res.data.data.result._id,
          {
            ...res.data.data.result,
            iterate_step: 3,
            iteration_id: SelectedIterationId,
          }
        );
        const matchedIteration = clr.data.data.result.iteration.find(
          (iter) => iter._id === SelectedIterationId
        );

        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;

        const projectData = await projectServices.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 4 }
        );
        const sortedIterations = projectData.data.data.result.iteration.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        dispatch(updateCompleteProject(projectData.data.data.result));
        dispatch(selectedIteration(sortedIterations[0]._id));
        dispatch(EditItration(false));
        navigate("/colorpalette");
        setLoading(false);
      } else {
        const clr = await projectServices.generateColor(
          res.data.data.result._id,
          {
            ...res.data.data.result,
            iterate_step: 4,
            iteration_id: SelectedIterationId,
          }
        );
        dispatch(updateCompleteProject(clr.data.data.result));
        setLoading(false);
        navigate("/colorpalette");
      }
    } catch (ex) {
      toastService.error(ex);
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const [timeDifference, setTimeDifference] = useState("");

  const selectWeight = (weight) => {
    dispatch(
      updateProjectProperty({
        _id: SelectedProjectId,
        key: "selected_weight",
        value: weight,
      })
    );
    setSelectedWeight(weight);
    console.log(`Selected Weight: ${weight}`);
  };

  useEffect(() => {
    function timeSince(date) {
      const now = new Date();
      const secondsPast = (now.getTime() - date.getTime()) / 1000;

      if (secondsPast < 60) {
        return `${Math.round(secondsPast)} seconds ago`;
      }
      if (secondsPast < 3600) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
      }
      if (secondsPast <= 86400) {
        return `${Math.round(secondsPast / 3600)} hours ago`;
      }
      if (secondsPast > 86400) {
        const daysPast = Math.round(secondsPast / 86400);
        return `${daysPast} days ago`;
      }
    }

    if (projectRecordData?.updatedAt) {
      const lastUpdatedDate = new Date(projectRecordData.updatedAt);
      setTimeDifference(timeSince(lastUpdatedDate));
    }

    const intervalId = setInterval(() => {
      if (projectRecordData?.updatedAt) {
        const lastUpdatedDate = new Date(projectRecordData.updatedAt);
        setTimeDifference(timeSince(lastUpdatedDate));
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [projectRecordData]);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, x: -200, scale: 0.5 },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="designdesignTypographyContainer">
          <div className="leftBriefingBox1">
            <div className="timelineBoxTypography">
              <div className="timelineTypography">
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
                <div className="connectorTypography"></div>
              </div>
            </div>
            <div className="projectDesignTypographyBox">
              <p className="DesignTypographyPara">Design: Typography</p>
              <p className="DesignTypographylastSaved">
                Last saved iteration: {timeDifference}
              </p>
            </div>

            <div className="DesignTypographydDesignBox">
              <p className="TypographyPara">
                Select the weight of the selected font:{" "}
                {projectRecordData.selected_fonts}
                <br />
                <span className="TypographyParaSpan">
                  Click on the one you prefer and we will create a complete
                  range from it.
                </span>
              </p>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="show"
                className="fontsWeightBoxDiv"
              >
                {Weight &&
                  Weight?.map((Weight, index) => (
                    <motion.div
                      variants={cardVariants}
                      key={index}
                      className={`fontSWeightDivouter ${
                        Weight === selectedWeight ? "selectedWeight" : ""
                      }`}
                      onClick={() => selectWeight(Weight)}
                    >
                      <div className="position-relative">
                        <span className="font_weight">{Weight}</span>
                      </div>
{console.log(Weight, 'projectRecordData', projectRecordData )}
                      <div
                        className="fontSWeightDivinner"
                        style={{ fontFamily: projectRecordData.selected_fonts }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "start",
                          }}
                        >
                          {projectRecordData.selected_font_Provider ===
                          "pangram pangram" ? (
                            <style>
                              {`
                                 @font-face {
                                   font-family: "${projectRecordData.selected_fonts}-${Weight}";
                                       src: url("https://brandfy-dashboard.s3.ap-south-1.amazonaws.com/Updated_Fonts/${projectRecordData.selected_fonts}/${projectRecordData.selected_fonts}-${Weight}.ttf") format("truetype");
                                      }
                                   `}
                            </style>
                          ) : (
                            <style>
                              {`
                                 @font-face {
                                   font-family: "${projectRecordData.selected_fonts}-${Weight}";
                                       src: url("https://brandfy-dashboard.s3.ap-south-1.amazonaws.com/Updated_Fonts/${projectRecordData.selected_fonts}/${projectRecordData.selected_fonts}-${Weight}.ttf") format("truetype");
                                      }
                                   `}
                            </style>
                          )}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {projectRecordData.selected_font_Provider ===
                            "pangram pangram" ? (
                              <p
                                style={{
                                  fontFamily:
                                    projectRecordData.selected_fonts +
                                    "-" +
                                    Weight,
                                  fontSize: "40px",
                                }}
                              >
                                {projectRecordData?.name}
                              </p>
                            ) : (
                              <p
                              style={{
                                fontFamily:
                                  projectRecordData.selected_fonts +
                                  "-" +
                                  Weight,
                                fontSize: "40px",
                              }}
                            >
                              {projectRecordData?.name}
                            </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
              </motion.div>

              <div className="btnsDivBoxx">
                <button
                  onClick={() => navigate("/designtypography")}
                  className="GoBackBtnInTypography"
                >
                  <span>Go back</span>
                </button>
                <div className="btnsDivBoxxInner">
                  {/* <button
                    className="TryAgainBtnInTypography"
                    onClick={handelTryAgain}
                  >
                    Try again
                  </button> */}
                  <button
                    onClick={() => {
                      handelSubmit();
                      window.scrollTo(0, 0);
                    }}
                    className="NextStepBtnInTypography"
                  >
                    <span>Next step</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}
    </>
  );
};

export default TypographyWeight;
