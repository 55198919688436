import React, { useEffect } from "react";
import "./PlanStarterResult.css";
import RightBar from "views/dashboard/RightBar";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import UpperRightBar from "views/dashboard/UpperRightBar";
import { useDispatch, useSelector } from "react-redux";
import projectServices from "services/projectServices";
import PDFSlider from "./pdfSlider";
import { getProjectById } from "app/reducers/projectEditSlice";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { motion } from "framer-motion";

const PlanStarterResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pdfLoading, setPdfLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [placeLoading, setplaceLoading] = useState(false);
  const [mockupsLoading, setMockupsLoading] = useState(false);
  const [textureLoading, settextureLoading] = useState(false);
  const [peopleLoading, setpeopleLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [starterPlanHandle, SetstarterPlanHandle] = useState(false);
  const [isExpandedConcept, setIsExpandedConcept] = useState(false);
  const [conceptImageLoading, setconceptImageLoading] = useState(false);

  const contentStyle = isExpanded
    ? { whiteSpace: "pre-line" }
    : {
        whiteSpace: "pre-line",
        overflow: "hidden",
        WebkitLineClamp: "7",
      };

  const contentStyleConcept = isExpandedConcept
    ? { whiteSpace: "pre-line" }
    : {
        whiteSpace: "pre-line",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: "7",
      };

  const SelectedProjectId = useSelector(
    (state) => state?.projectEdit?.currentProject
  );

  const SelectedIterationId = useSelector(
    (state) => state?.projectEdit?.selectedIterationId
  );

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );

  let projectRecordData = useSelector(
    (state) => state?.projectEdit?.singleProject
  );

  const GptColor = projectRecordData?.color_palette;

  const conceptImageData = useSelector(
    (state) => state?.projectEdit?.conceptImage?.concept
  );
  const TexttureImageData = useSelector(
    (state) => state?.projectEdit?.textureImageData?.textures
  );
  const humnasImageData = useSelector(
    (state) => state?.projectEdit?.humnas?.persons
  );
  const placeImageData = useSelector(
    (state) => state?.projectEdit?.placeData?.places
  );
  const conceptWithLogo = useSelector(
    (state) => state?.projectEdit?.conceptWithLogo?.conceptWithLogo
  );
  const textureImageWithLogo = useSelector(
    (state) => state?.projectEdit?.textureWithLogo?.textureWithLogo
  );
  const humanImageWithLogo = useSelector(
    (state) => state?.projectEdit?.humanWithLogo?.personWithLogo
  );
  const placeImageWithLogo = useSelector(
    (state) => state?.projectEdit?.placeWithLogo?.placesWithLogo
  );
  const mockupsImageData = useSelector(
    (state) => state?.projectEdit?.mockups?.gptMockup
  );
  const getPdfBookURL = useSelector(
    (state) => state?.projectEdit?.pdfBook?.pdfBook
  );

  const getTextColor = (() => {
    const memo = {};
    return (hexColor) => {
      if (memo[hexColor]) {
        return memo[hexColor];
      }
      const hex = hexColor?.replace(/^#/, "") || "000000";
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      const result = luminance > 128 ? "text-black" : "text-white";
      memo[hexColor] = result;
      return result;
    };
  })();

  const renderDivPair = (id, color) => (
    <div className="ColorDivOuter">
      <div className="colorDivInner" style={{ background: color.Hex }}>
        <div style={{ padding: "10px" }} className="colorInfo">
          <p className={`${getTextColor(color?.Hex)}`}>
            Secondary Colour {Number(id) + 1}
          </p>
          <div className="colorInfoDetails">
            <span className={`${getTextColor(color?.Hex)}`}>cmyk</span>
            <span className={`${getTextColor(color?.Hex)}`}>
              {color?.CMYK?.[0] +
                " " +
                color?.CMYK?.[1] +
                " " +
                color?.CMYK?.[2] +
                " " +
                color?.CMYK?.[3]}
            </span>
          </div>
          <div className="colorInfoDetails">
            <span className={`${getTextColor(color?.Hex)}`}>rgb</span>
            <span className={`${getTextColor(color?.Hex)}`}>
              {color?.RGB?.[0] + " " + color?.RGB?.[1] + " " + color?.RGB?.[2]}
            </span>
          </div>
          <div className="colorInfoDetails">
            <span className={`${getTextColor(color?.Hex)}`}>HEX</span>
            <span className={`${getTextColor(color?.Hex)}`}>{color?.Hex}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderDivPairTertiary = (id, color) => (
    <div className="ColorDivOuter">
      <div className="colorDivInner1" style={{ background: color.Hex }}>
        <div style={{ padding: "10px" }} className="colorInfoT">
          <p className={`${getTextColor(color?.Hex)}`}>
            Third colour {Number(id) + 1}
          </p>
          <div className="colorInfoTDetails">
            <span className={`${getTextColor(color?.Hex)}`}>cmyk</span>
            <span className={`${getTextColor(color?.Hex)}`}>
              {color?.CMYK?.[0] +
                " " +
                color?.CMYK?.[1] +
                " " +
                color?.CMYK?.[2] +
                " " +
                color?.CMYK?.[3]}
            </span>
          </div>
          <div className="colorInfoTDetails">
            <span className={`${getTextColor(color?.Hex)}`}>rgb</span>
            <span className={`${getTextColor(color?.Hex)}`}>
              {color?.RGB?.[0] + " " + color?.RGB?.[1] + " " + color?.RGB?.[2]}
            </span>
          </div>
          <div className="colorInfoTDetails">
            <span className={`${getTextColor(color?.Hex)}`}>HEX</span>
            <span className={`${getTextColor(color?.Hex)}`}>{color?.Hex}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const cardVariants = {
    hidden: { opacity: 0, x: -200, scale: 0.5 },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const result = projectRecordData?.result;

  async function fetchPreSignedUrls(url) {
    try {
      const body = { s3Url: url };
      const response = await projectServices?.generatePreSignedUrl(body);
      return response?.data?.data?.result;
    } catch (error) {
      console.error(error);
    }
  }

  function findLightestColor(colors) {
    let lightestColorHex = "";
    let highestRGBSum = 0;
    Object?.keys(colors ?? {})?.forEach((colorType) => {
      colors[colorType ?? []]?.forEach((color) => {
        const rgbSum = color?.RGB.reduce((sum, val) => sum + val, 0);
        if (rgbSum > highestRGBSum) {
          highestRGBSum = rgbSum;
          lightestColorHex = color?.Hex;
        }
      });
    });
    return lightestColorHex;
  }

  async function downloadImage(url) {
    try {
      const preSignedUrl = await fetchPreSignedUrls(url);
      if (preSignedUrl) {
        const response = await fetch(preSignedUrl);
        const blob = await response?.blob();
        return blob;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadJpeg(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadJpg(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadJpgOne(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadJpgTwo(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadJpgThree(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadSvg(url) {
    try {
      const preSignedUrl = await fetchPreSignedUrls(url);
      if (preSignedUrl) {
        const response = await fetch(preSignedUrl);
        const svgText = await response?.text();
        const blob = new Blob([svgText], { type: "image/svg+xml" });
        return blob;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadPdf(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  function extractFilenameFromUrl(url) {
    console.log(url);
    const pathSegments = url.split("/");
    return pathSegments[pathSegments.length - 1];
  }

  async function downloadImages(urls, projectName, moduleName) {
    console.log(urls, 458546346458);
    const LoogBlobs = [];
    const MockupimageBlobs = [];
    const pdfBlobs = [];
    const conceptBlobs = [];
    const PeopleBlobs = [];
    const TextureBlobs = [];
    const PlacesBlobs = [];

    for (let i = 0; i < urls?.length; i++) {
      const url = urls[i];

      // Logo's
      if (url?.endsWith(".svg")) {
        if (moduleName === "Logo") {
          setLogoLoading(true);
        }
        const logoBlob = await downloadSvg(url);
        if (logoBlob) {
          LoogBlobs.push(logoBlob);
        }
      }

      // Mockups
      else if (url?.endsWith(".png")) {
        if (moduleName === "mockups") {
          setMockupsLoading(true);
        }
        const MockupimageBlob = await downloadJpeg(url);
        if (MockupimageBlob) {
          MockupimageBlobs.push(MockupimageBlob);
        }
      }

      // Concept
      else if (url?.endsWith(".jpg") || url?.endsWith(".png")) {
        if (moduleName === "Concept_image") {
          setconceptImageLoading(true);
        }
        const conceptBlob = await downloadJpg(url);
        if (conceptBlob) {
          conceptBlobs.push(conceptBlob);
        }
      }

      // Texture
      else if (url?.endsWith(".jpg") || url?.endsWith(".png")) {
        if (moduleName === "Texture_image") {
          settextureLoading(true);
        }
        const texturBlob = await downloadJpgOne(url);
        if (texturBlob) {
          TextureBlobs.push(texturBlob);
        }
      }

      // People Images
      else if (url?.endsWith(".jpg") || url?.endsWith(".png")) {
        if (moduleName === "People_image") {
          setpeopleLoading(true);
        }
        const personBlob = await downloadJpgTwo(url);
        if (personBlob) {
          PeopleBlobs.push(personBlob);
        }
      }

      // Place Images
      else if (url?.endsWith(".jpg") || url?.endsWith(".png")) {
        if (moduleName === "place_images") {
          setplaceLoading(true);
        }
        const placeImagesBlobs = await downloadJpgThree(url);
        if (placeImagesBlobs) {
          PlacesBlobs.push(placeImagesBlobs);
        }
      }

      // Brand Pdf
      else if (url?.endsWith(".pdf")) {
        if (moduleName === "PDF_book") {
          setPdfLoading(true);
        }
        const pdfBlob = await downloadPdf(url);
        if (pdfBlob) {
          pdfBlobs.push(pdfBlob);
        }
      } else {
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    const zip = new JSZip();
    for (let i = 0; i < MockupimageBlobs?.length; i++) {
      const downloadFileName = `${extractFilenameFromUrl(urls[i])}`;
      zip.file(downloadFileName, MockupimageBlobs[i]);
    }

    // New Added Start
    for (let i = 0; i < conceptBlobs?.length; i++) {
      const downloadFileName = `${extractFilenameFromUrl(urls[i])}`;
      zip.file(downloadFileName, conceptBlobs[i]);
    }

    for (let i = 0; i < TextureBlobs?.length; i++) {
      const downloadFileName = `${extractFilenameFromUrl(urls[i])}`;
      zip.file(downloadFileName, TextureBlobs[i]);
    }

    for (let i = 0; i < PeopleBlobs?.length; i++) {
      const downloadFileName = `${extractFilenameFromUrl(urls[i])}`;
      zip.file(downloadFileName, PeopleBlobs[i]);
    }

    for (let i = 0; i < PlacesBlobs?.length; i++) {
      const downloadFileName = `${extractFilenameFromUrl(urls[i])}`;
      zip.file(downloadFileName, PlacesBlobs[i]);
    }

    // New Added End

    for (let i = 0; i < LoogBlobs?.length; i++) {
      const downloadFileName = `${extractFilenameFromUrl(urls[i])}`;
      zip.file(downloadFileName, LoogBlobs[i]);
    }

    for (let i = 0; i < pdfBlobs?.length; i++) {
      const downloadFileName = `${extractFilenameFromUrl(urls[i])}`;
      zip.file(downloadFileName, pdfBlobs[i]);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      const zipFileName = `${projectName}_${moduleName}.zip`;
      saveAs(content, zipFileName);
      setPdfLoading(false);
      setMockupsLoading(false);
      setLogoLoading(false);
      setconceptImageLoading(false);
      settextureLoading(false);
      setpeopleLoading(false);
      setplaceLoading(false);
    });
  }

  useEffect(() => {
    if (
      projectRecordData?.plan_name === "Master Pro" ||
      projectRecordData?.plan_name === "Pro"
    ) {
      SetstarterPlanHandle(false);
    } else {
      SetstarterPlanHandle(true);
    }
  }, []);

  function timeSince(date) {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;
    if (secondsPast < 60) {
      return `${Math.round(secondsPast)} seconds ago`;
    }
    if (secondsPast < 3600) {
      return `${Math.round(secondsPast / 60)} minutes ago`;
    }
    if (secondsPast <= 86400) {
      return `${Math.round(secondsPast / 3600)} hours ago`;
    }
    if (secondsPast > 86400) {
      const daysPast = Math.round(secondsPast / 86400);
      return `${daysPast} days ago`;
    }
  }

  const lastUpdatedDate = new Date(projectRecordData?.updatedAt);
  const timeDifference = timeSince(lastUpdatedDate);

  return (
    <div className="designresultstarterContainer">
      <div className="leftBriefingBox">
        <div className="timelineBoxresultStarter">
          <div className="timelineresultStart">
            <div className="connecterresultStart"></div>
            <div className="connecterresultStart"></div>
            <div className="connecterresultStart"></div>
            <div className="connecterresultStart"></div>
            <div className="connecterresultStart"></div>
            <div className="connecterresultStart"></div>
          </div>
        </div>
        <div className="projectresultStarterBox">
          <p className="DesignresultStarterPara">Design: Results</p>
          <p className="DesignresultStarterlastSaved">
            Last saved iteration: {timeDifference}
          </p>
        </div>

        <div className="DesignresultStarterBox">
          <p className="brandAssetsPara">
            Et voilá! Here you have your brand assets:
          </p>

          <div className="row firsttwo_images mx-0 justify-center">
            <div className="col-md-6 p-2">
              <div className="d-flex backgroundColorsBox">
                <div
                  className="backgroundColorsBox1"
                  style={{
                    backgroundColor:
                      projectRecordData?.color_palette?.main[0]?.Hex,
                  }}
                >
                  <img src={result?.fullLogo} alt=""></img>
                </div>

                <div
                  className="backgroundColorsBox2"
                  style={{
                    backgroundColor:
                      projectRecordData?.color_palette?.secondary[0]?.Hex,
                  }}
                >
                  <img src={result?.fulColorLogo3} alt=""></img>
                </div>
              </div>
            </div>

            <div className="col-md-6 p-2">
              <div className="logoBoxresult">
                <img src={mockupsImageData?.[mockupsImageData?.length - 1]} alt=""></img>
              </div>
            </div>

            {/* New Design Added */}
            <div className="d-flex gap-1 pt-3">
              <div className="textture_images card w-50">
                <img
                  src={TexttureImageData[0]}
                  className="card-img-top card_image"
                  alt="..."
                />
              </div>
              <div className="textture_images card w-50">
                <img
                  src={humnasImageData[0]}
                  className="card-img-top card_image"
                  alt="..."
                />
              </div>
              <div className="textture_images card w-50">
                <img
                  src={placeImageData[0]}
                  className="card-img-top card_image"
                  alt="..."
                />
              </div>
            </div>

            <div className="d-flex gap-1 pt-3">
              <div className="textture_images card w-50">
                <img
                  src={mockupsImageData[0]}
                  className="card-img-top card_image"
                  alt="..."
                />
              </div>
              <div className="textture_images card w-50">
                <img
                  src={mockupsImageData[1]}
                  className="card-img-top card_image"
                  alt="..."
                />
              </div>
            </div>

            <div className="descriptionBoxresult" style={contentStyle}>
              {projectRecordData?.gptLogoStyle}
            </div>

            <div style={{ position: "relative" }}>
              {starterPlanHandle && (
                <div className="blur_button_container">
                  <h4>Let’s upgrade to access this features</h4>
                  <button
                    className="upgradeStarterResult"
                    onClick={() => {
                      navigate("/billing");
                    }}
                  >
                    <svg
                      className="upgradeStarterResultsvg"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1090_1537)">
                        <path
                          d="M9.5 22.5957C10.0523 22.5957 10.5 22.148 10.5 21.5957C10.5 21.0434 10.0523 20.5957 9.5 20.5957C8.94772 20.5957 8.5 21.0434 8.5 21.5957C8.5 22.148 8.94772 22.5957 9.5 22.5957Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.5 22.5957C21.0523 22.5957 21.5 22.148 21.5 21.5957C21.5 21.0434 21.0523 20.5957 20.5 20.5957C19.9477 20.5957 19.5 21.0434 19.5 21.5957C19.5 22.148 19.9477 22.5957 20.5 22.5957Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.5 1.5957H5.5L8.18 14.9857C8.27144 15.4461 8.52191 15.8597 8.88755 16.154C9.25318 16.4483 9.7107 16.6047 10.18 16.5957H19.9C20.3693 16.6047 20.8268 16.4483 21.1925 16.154C21.5581 15.8597 21.8086 15.4461 21.9 14.9857L23.5 6.5957H6.5"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1090_1537">
                          <rect
                            className="rectSVG"
                            fill="white"
                            transform="translate(0.5 0.595703)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Upgrade
                  </button>
                </div>
              )}
              <div className="conceptNameResultStarter">
                <span>
                  Brand
                  <br />
                </span>
                <span className="conceptNameResultStarterspan">Concept</span>
                <div className="conceptImageBoxStarter1">
                  <div
                    className="imageConceptStart"
                    style={{
                      backgroundColor: projectRecordData?.color_palette?.[0],
                    }}
                  >
                    {starterPlanHandle ? (
                      <img alt=""></img>
                    ) : (
                      <img
                        src={projectRecordData?.gptSymbol?.fulColorSymbol1}
                        alt=""
                      ></img>
                    )}
                  </div>
                </div>
                <p className="conceptPara2" style={contentStyleConcept}>
                  {projectRecordData?.gptLogoConcept}
                </p>
              </div>
            </div>

            <div style={{ position: "relative", marginBottom: "48px" }}>
              {starterPlanHandle && (
                <div className="blur_button_container">
                  <h4>Let’s upgrade to access this features</h4>
                  <button
                    className="upgradeStarterResult"
                    onClick={() => {
                      navigate("/billing");
                    }}
                  >
                    <svg
                      className="upgradeStarterResultsvg"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1090_1537)">
                        <path
                          d="M9.5 22.5957C10.0523 22.5957 10.5 22.148 10.5 21.5957C10.5 21.0434 10.0523 20.5957 9.5 20.5957C8.94772 20.5957 8.5 21.0434 8.5 21.5957C8.5 22.148 8.94772 22.5957 9.5 22.5957Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.5 22.5957C21.0523 22.5957 21.5 22.148 21.5 21.5957C21.5 21.0434 21.0523 20.5957 20.5 20.5957C19.9477 20.5957 19.5 21.0434 19.5 21.5957C19.5 22.148 19.9477 22.5957 20.5 22.5957Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.5 1.5957H5.5L8.18 14.9857C8.27144 15.4461 8.52191 15.8597 8.88755 16.154C9.25318 16.4483 9.7107 16.6047 10.18 16.5957H19.9C20.3693 16.6047 20.8268 16.4483 21.1925 16.154C21.5581 15.8597 21.8086 15.4461 21.9 14.9857L23.5 6.5957H6.5"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1090_1537">
                          <rect
                            className="rectSVG"
                            fill="white"
                            transform="translate(0.5 0.595703)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Upgrade
                  </button>
                </div>
              )}

              <div className="mainSecPriBox">
                <div className="mainTypoBox">
                  Main typography <br />
                  <style>
                    {`
              @font-face {
                font-family: "${projectRecordData?.fonts[0]}";
                src: url("https://brandfy-dashboard.s3.ap-south-1.amazonaws.com/Updated_Fonts/${projectRecordData?.fonts[0]}/${projectRecordData?.fonts[0]}-${projectRecordData?.selected_weight}.ttf") format("truetype");
              }
            `}
                  </style>
                  <span
                    className="mainTypoBoxSpan"
                    style={{ fontFamily: projectRecordData?.fonts[0] }}
                  >
                    {projectRecordData?.fonts[0]}
                  </span>
                  <p
                    className="agparamain"
                    style={{ fontFamily: projectRecordData?.fonts[0] }}
                  >
                    Ag
                  </p>
                  <p
                    className="characterMainTypo"
                    style={{ fontFamily: projectRecordData?.fonts[0] }}
                  >
                    ABCDEFG HIJKLMN OPQRSTa bcdefghi jklmnopq rst 123467 890!”·$
                    %&/()=? ¿*â
                  </p>
                </div>
                <div className="SecTypoBox">
                  Secondary typography <br />
                  <style>font-family: {projectRecordData?.fonts[1]};</style>
                  <span
                    className="secTypoBoxSpan"
                    style={{ fontFamily: projectRecordData?.fonts[1] }}
                  >
                    {projectRecordData?.fonts[1]}
                  </span>
                  <p
                    className="agparasec"
                    style={{ fontFamily: projectRecordData?.fonts[1] }}
                  >
                    Ag
                  </p>
                  <p
                    className="charactersecTypo"
                    style={{ fontFamily: projectRecordData?.fonts[1] }}
                  >
                    ABCDEFG HIJKLMN OPQRSTa bcdefghi jklmnopq rst 123467 890!”·$
                    %&/()=? ¿*â
                  </p>
                </div>
                <div className="verticalLine"></div>

                <div className="primaryPaletteInresult">
                  <motion.div
                    variants={cardVariants}
                    className="mainColor"
                    style={{
                      backgroundColor: GptColor?.main[0]?.Hex,
                      height: "250px",
                    }}
                  >
                    <div className="colorInfo">
                      <p className={`${getTextColor(GptColor?.main[0]?.Hex)}`}>
                        Primary colour
                      </p>
                      <div className="colorInfoDetails">
                        <span
                          className={`${getTextColor(GptColor?.main[0]?.Hex)}`}
                        >
                          cmyk
                        </span>
                        <span
                          className={`${getTextColor(GptColor?.main[0]?.Hex)}`}
                        >
                          {GptColor?.main[0]?.CMYK.toString()}
                        </span>
                      </div>
                      <div className="colorInfoDetails">
                        <span
                          className={`${getTextColor(GptColor?.main[0]?.Hex)}`}
                        >
                          rgb
                        </span>
                        <span
                          className={`${getTextColor(GptColor?.main[0]?.Hex)}`}
                        >
                          {GptColor?.main[0]?.RGB.toString()}
                        </span>
                      </div>
                      <div className="colorInfoDetails">
                        <span
                          className={`${getTextColor(GptColor?.main[0]?.Hex)}`}
                        >
                          HEX
                        </span>
                        <span
                          className={`${getTextColor(GptColor?.main[0]?.Hex)}`}
                        >
                          {GptColor?.main[0]?.Hex}
                        </span>
                      </div>
                    </div>
                  </motion.div>

                  <motion.div variants={cardVariants} className="colorBoxDiv">
                    {GptColor &&
                      GptColor?.secondary?.map((color, index) =>
                        renderDivPair(`${index}`, color)
                      )}
                  </motion.div>

                  <motion.div variants={cardVariants} className="colorBoxDiv1">
                    {GptColor &&
                      GptColor?.tertiary?.map((color, index) =>
                        renderDivPairTertiary(`${index}`, color)
                      )}
                  </motion.div>
                </div>
              </div>
              <p className="clickhereresult">
                * If you want to buy fonts click
                <span className="clickhereresultspan">here. </span>
              </p>
            </div>

            <div className="horizentalLIne"></div>
            <div style={{ position: "relative" }}>
              {starterPlanHandle && (
                <div className="blur_button_container">
                  <h4>Let’s upgrade to access this features</h4>
                  <button
                    className="upgradeStarterResult"
                    onClick={() => {
                      navigate("/billing");
                    }}
                  >
                    <svg
                      className="upgradeStarterResultsvg"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1090_1537)">
                        <path
                          d="M9.5 22.5957C10.0523 22.5957 10.5 22.148 10.5 21.5957C10.5 21.0434 10.0523 20.5957 9.5 20.5957C8.94772 20.5957 8.5 21.0434 8.5 21.5957C8.5 22.148 8.94772 22.5957 9.5 22.5957Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.5 22.5957C21.0523 22.5957 21.5 22.148 21.5 21.5957C21.5 21.0434 21.0523 20.5957 20.5 20.5957C19.9477 20.5957 19.5 21.0434 19.5 21.5957C19.5 22.148 19.9477 22.5957 20.5 22.5957Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.5 1.5957H5.5L8.18 14.9857C8.27144 15.4461 8.52191 15.8597 8.88755 16.154C9.25318 16.4483 9.7107 16.6047 10.18 16.5957H19.9C20.3693 16.6047 20.8268 16.4483 21.1925 16.154C21.5581 15.8597 21.8086 15.4461 21.9 14.9857L23.5 6.5957H6.5"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1090_1537">
                          <rect
                            className="rectSVG"
                            fill="white"
                            transform="translate(0.5 0.595703)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Upgrade
                  </button>
                </div>
              )}

              {/* New Design Added */}

              {/* Image Concept */}
              <div div className="conceptNameResultStarter">
                <div className="conceptinnergroup">
                  <div className="image-group">
                    <span>
                      Images
                      <br />
                    </span>
                    <span className="conceptNameResultStarterspan">
                      {" "}
                      Concept{" "}
                    </span>
                  </div>

                  {/* <div className="image_wrap">
                    <Link to="/billing">
                      <button className="upgradeStarterResultmore">
                        More deliverables
                      </button>
                    </Link>
                  </div> */}
                </div>

                <div className="d-flex gap-1 pt-3 imagesconcepr">
                  {conceptImageData?.slice(0, 1)?.map((con, idx) => {
                    return (
                      <div key={idx} className="textture_images card w-50">
                        <img
                          src={con}
                          className="card-img-top card_image"
                          alt="..."
                        />
                      </div>
                    );
                  })}

                  <div className="textture_images card w-50">
                    <img
                      src={conceptWithLogo[0]}
                      className="card-img-top card_image"
                      alt="..."
                    />
                  </div>
                </div>
              </div>

              {/* Image Texture */}
              <div className="innertexture">
                <div div className="conceptNameResultStarter">
                  <div className="image-group">
                    <span>
                      Images
                      <br />
                    </span>

                    <span className="conceptNameResultStarterspan">
                      Texture{" "}
                    </span>
                  </div>

                  {/* <div className="image_wrap">
                    <Link to="/billing">
                      <button className="upgradeStarterResultmore">
                        More deliverables
                      </button>
                    </Link>
                  </div> */}
                </div>

                <div className="d-flex gap-1 pt-3">
                  {TexttureImageData?.slice(0, 1)?.map((texture, idx) => {
                    return (
                      <div className="textture_images card w-50">
                        <img
                          src={texture}
                          className="card-img-top card_image"
                          alt="..."
                        />
                      </div>
                    );
                  })}
                  <div className="textture_images card w-50">
                    <img
                      src={textureImageWithLogo[0]}
                      className="card-img-top card_image"
                      alt="..."
                    />
                  </div>
                </div>
              </div>

              {/* Image Poeple */}
              <div className="innertexture">
                <div div className="conceptNameResultStarter">
                  <div className="image-group">
                    <span>
                      Images
                      <br />
                    </span>
                    <span className="conceptNameResultStarterspan">
                      People{" "}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                    }}
                  >
                    <Link to="/billing">
                      <button className="upgradeStarterResultmore">
                        More deliverables
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="d-flex gap-1 pt-3">
                  {humnasImageData?.slice(0, 1)?.map((person, idx) => {
                    return (
                      <div key={idx} className="textture_images card w-50">
                        <img
                          src={person}
                          className="card-img-top card_image"
                          alt="..."
                        />
                      </div>
                    );
                  })}

                  <div className="textture_images card w-50">
                    <img
                      src={humanImageWithLogo[0]}
                      className="card-img-top card_image"
                      alt="..."
                    />
                  </div>
                </div>
              </div>

              {/* Image Places */}
              <div className="innertexture">
                <div div className="conceptNameResultStarter">
                  <div className="image-group">
                    <span>
                      Images
                      <br />
                    </span>
                    <span className="conceptNameResultStarterspan">
                      Places{" "}
                    </span>
                  </div>
                  {/* <div className="image_wrap">
                    <Link to="/billing">
                      <button className="upgradeStarterResultmore">
                        More deliverables
                      </button>
                    </Link>
                  </div> */}
                </div>

                <div className="d-flex gap-1 pt-3">
                  {placeImageData?.slice(0, 1)?.map((place, idx) => {
                    return (
                      <div key={idx} className="textture_images card w-50">
                        <img
                          src={place}
                          className="card-img-top card_image"
                          alt="..."
                        />
                      </div>
                    );
                  })}

                  <div className="textture_images card w-50">
                    <img
                      src={placeImageWithLogo[0]}
                      className="card-img-top card_image"
                      alt="..."
                    />
                  </div>
                </div>
              </div>

              {/* Brand Mockups */}
              <div className="innertexture">
                <div className="conceptNameResultStarter">
                  <div className="image-group">
                    <span>
                      Brand
                      <br />
                    </span>
                    <span className="conceptNameResultStarterspan">
                      {" "}
                      Mockups{" "}
                    </span>
                  </div>
                  {/* <div className="image_wrap">
                    <Link to="/billing">
                      <button className="upgradeStarterResultmore">
                        More deliverables
                      </button>
                    </Link>
                  </div> */}
                </div>

                <div className="row mockup-group">
                  {mockupsImageData?.map((mock, idx) => {
                    return (
                      <div
                        key={idx}
                        className="col-6 mt-2"
                        style={{ height: "500px" }}
                      >
                        <img
                          style={{ height: "100%" }}
                          src={mock}
                          className="card-img-top card_image"
                          alt="..."
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* New Design Added End */}
            </div>

            <div style={{ position: "relative" }}>
              {starterPlanHandle && (
                <div className="blur_button_container">
                  <h4>Let’s upgrade to access this features</h4>
                  <button
                    className="upgradeStarterResult"
                    onClick={() => {
                      navigate("/billing");
                    }}
                  >
                    <svg
                      className="upgradeStarterResultsvg"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1090_1537)">
                        <path
                          d="M9.5 22.5957C10.0523 22.5957 10.5 22.148 10.5 21.5957C10.5 21.0434 10.0523 20.5957 9.5 20.5957C8.94772 20.5957 8.5 21.0434 8.5 21.5957C8.5 22.148 8.94772 22.5957 9.5 22.5957Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20.5 22.5957C21.0523 22.5957 21.5 22.148 21.5 21.5957C21.5 21.0434 21.0523 20.5957 20.5 20.5957C19.9477 20.5957 19.5 21.0434 19.5 21.5957C19.5 22.148 19.9477 22.5957 20.5 22.5957Z"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.5 1.5957H5.5L8.18 14.9857C8.27144 15.4461 8.52191 15.8597 8.88755 16.154C9.25318 16.4483 9.7107 16.6047 10.18 16.5957H19.9C20.3693 16.6047 20.8268 16.4483 21.1925 16.154C21.5581 15.8597 21.8086 15.4461 21.9 14.9857L23.5 6.5957H6.5"
                          stroke="#363538"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1090_1537">
                          <rect
                            className="rectSVG"
                            fill="white"
                            transform="translate(0.5 0.595703)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Upgrade
                  </button>
                </div>
              )}

              <p className="BrandPara">
                Brand book
                <br />
                <span className="BrandParaspan">Pdf</span>
              </p>

              <div className="sliderdib">
                {starterPlanHandle ? (
                  <img alt=""></img>
                ) : (
                  <PDFSlider pdfUrl={getPdfBookURL}></PDFSlider>
                )}
              </div>
            </div>

            {!starterPlanHandle && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <button
                  disabled={pdfLoading}
                  onClick={() => {
                    if (projectRecordData?.plan_name === "Starter") {
                      const resultURLS = [
                        result?.fulColorLogo1,
                        result?.fulColorLogo2,
                        result?.fulColorLogo3,
                        result?.fullLogo,
                      ];
                      downloadImages(
                        resultURLS,
                        projectRecordData?.name,
                        "Logo"
                      );
                    } else if (projectRecordData?.plan_name === "Pro") {
                    } else {
                      const resultURLS = [
                        result?.fulColorLogo1,
                        result?.fulColorLogo2,
                        result?.fulColorLogo3,
                        result?.fullLogo,
                      ];
                      downloadImages(
                        resultURLS,
                        projectRecordData?.name,
                        "Logo"
                      );
                      const urls = [
                        conceptImageData?.[0],
                        conceptWithLogo?.[0],
                      ];

                      downloadImages(
                        urls,
                        projectRecordData?.name,
                        "Concept_image"
                      );

                      downloadImages(
                        [TexttureImageData?.[0], textureImageWithLogo?.[0]],
                        projectRecordData?.name,
                        "Texture_image"
                      );

                      downloadImages(
                        [humnasImageData?.[0], humanImageWithLogo?.[0]],
                        projectRecordData?.name,
                        "People_image"
                      );

                      downloadImages(
                        [placeImageData?.[0], placeImageWithLogo?.[0]],
                        projectRecordData?.name,
                        "place_image"
                      );

                      downloadImages(
                        mockupsImageData,
                        projectRecordData?.name,
                        "mockups"
                      );

                      downloadImages(
                        [getPdfBookURL],
                        projectRecordData?.name,
                        "PDF_book"
                      );
                    }
                  }}
                  className="upgradeStarterResultmore"
                >
                  {mockupsLoading || pdfLoading
                    ? "Downloading..."
                    : "Download All"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="RightSideDashboard">
        <UpperRightBar />
        <RightBar />
      </div>
    </div>
  );
};

export default PlanStarterResult;
