/* eslint-disable import/no-anonymous-default-export */
import Cookies from "universal-cookie";
import http from "./httpService";
import config from "../config";
const { apiUrl } = config;
const apiEndpoint = `${apiUrl}/auth`;
const cookies = new Cookies();

async function login(user) {
  return await http.post(`${apiEndpoint}`, user);
}

async function register(userBody) {
  return http.post(`${apiEndpoint}/register`, userBody);
}

function getCurrentToken() {
  const accessToken = cookies.get("accessToken");
  return accessToken;
}

function getCurrentUser() {
  const user = cookies.get("user");
  return user;
}

function getCurrentUserToken() {
  const token = cookies.get("accessToken");
  return token;
}

function generateOtp(body) {
  return http.post(`${apiEndpoint}/request/otp`, body);
}
function storeLoginData(access_token, user) {
  cookies.set("accessToken", access_token, { path: "/" });
  cookies.set("user", JSON.stringify(user), { path: "/" });
}
function updateClientProperty(newUserClientValue) {
  // Get the current user object from cookies
  let currentUser;
  try {
    currentUser = cookies.get("user");
  } catch (error) {
    console.error("Error parsing user data from cookie:", error);
    // default to an empty object or handle the error as needed
  }
  // Update the client property
  const updatedUser = {
    ...currentUser,
    client: newUserClientValue,
  };

  // Store the updated user object back into cookies
  cookies.set("user", JSON.stringify(updatedUser), { path: "/" });
}

async function SignOut(body, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  http.setJwt(token);

  try {
    const response = await http.delete(apiEndpoint, { headers }, body);
  } catch (ex) {}

  cookies.remove("accessToken", { path: "/" });
  cookies.remove("user", { path: "/" });
}

function updateUserData(user) {
  cookies.remove("user", { path: "/" });
  cookies.set("user", JSON.stringify(user), { path: "/" });
}
function ResetPassword(body) {
  return http.post(`${apiEndpoint}/password/reset/request`, body);
}

export default {
  login,
  register,
  storeLoginData,
  getCurrentUser,
  generateOtp,
  getCurrentToken,
  ResetPassword,
  SignOut,
  getCurrentUserToken,
  updateUserData,
  updateClientProperty,
};
