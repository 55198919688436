import "./updateProject.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
// import { RxCross2 } from "react-icons/rx";

// Components
import CategoryJson from "../../../../src/Categories.json";
import RightBar from "views/dashboard/RightBar";
import DoingMagic from "../magic/DoingMagic";
import Joi from "joi";

// API Services
import validationService from "services/validationService";
import projectServices from "services/projectServices";
import clientServices from "services/clientServices";
import authServices from "services/authServices";
import toastService from "services/toastService";

// Reducers
import {
  getNonIterationProjectById,
  getProjectById,
  selectedIteration,
  updateCompleteProject,
  updateProjectProperty,
} from "app/reducers/projectEditSlice";
import { EditItration } from "app/reducers/EditItration";
import { saveClientDb } from "app/reducers/clientDbSlice";

const ProjectUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = authServices.getCurrentUser();

  const itration = useSelector((state) => state.EditItrationNo.itration);
  const SelectedProjectId = useSelector(
    (state) => state.projectEdit.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state.projectEdit.selectedIterationId
  );
  let projectRecordData = useSelector(
    (state) => state.projectEdit.singleProject
  );

  const [showCategorySuggestion, setshowCategorySuggestion] = useState(false);
  const [shwowSubCategorySuggestion, setshwowSubCategorySuggestion] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState({
    industry: "",
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suggestionsClient, setSuggestionsClient] = useState([]);
  const [valueClient, setValueClient] = useState("");
  const [imageError, setImageError] = useState("");
  const [showSuggestionsClient, setShowSuggestionsClient] = useState(false);

  const [projectRecord, setProjectRecord] = useState({
    user_id: user?.id,
    client_name: "",
    name: "",
    brand_claim_text: "",
    goal: "",
    target_audience: "",
    geographic_reach: "",
    usp: "",
    logoSymbol: "",
    industryCategory: "",
    industrySubCategory: "",
    core_value: "",
    brand_personality: "",
    color_preferences: "",
    primary_competitor: "",
    secondary_competitor: "",
    inspirational_images: "",
    inspirational_images_one: "",
    inspirational_images_Two: "",
    image_avoidance: "",
    image_avoidanceOne: "",
    image_avoidanceTwo: "",
    clientId: "",
    applications: "",
    plan_name: "",
  });

  const schema = Joi.object().keys({
    user_id: Joi.string().required(),
    client_name: Joi.string().required().label("client_name"),
    name: Joi.string().required().label("name"),
    brand_claim_text: Joi.string().label("brand_claim_text").allow(""),
    logoSymbol: Joi.string().allow("").label("logoSymbol"),
    goal: Joi.string().required().label("goal"),
    target_audience: Joi.string().required().label("target_audience"),
    geographic_reach: Joi.string().required().label("geographic_reach"),
    usp: Joi.string().required().label("usp").allow(""),
    industryCategory: Joi.string().required().label("industryCategory"),
    industrySubCategory: Joi.string().required().label("industrySubCategory"),
    core_value: Joi.string().label("core_value").required().allow(""),
    brand_personality: Joi.string().label("brand_personality").allow(""),
    color_preferences: Joi.string().label("color_preferences").allow(""),
    primary_competitor: Joi.string().label("primary_competitor").required(""),
    secondary_competitor: Joi.string().label("secondary_competitor").allow(""),
    inspirational_images: Joi.string().label("inspirational_images").allow(""),
    inspirational_images_one: Joi.string()
      .label("inspirational_images_one")
      .allow(""),
    inspirational_images_Two: Joi.string()
      .label("inspirational_images_Two")
      .allow(""),
    image_avoidance: Joi.string().allow(""),
    image_avoidanceOne: Joi.string().allow(""),
    image_avoidanceTwo: Joi.string().allow(""),
    clientId: Joi.string().allow(""),
    applications: Joi.string().label("applications").allow(""),
    plan_name: Joi.string().allow(""),
  });

  const clientId = useRef("");

  if (itration) {
    dispatch(getNonIterationProjectById({ projectId: SelectedProjectId }));
  } else {
    dispatch(
      getProjectById({
        projectId: SelectedProjectId,
        iteration_id: SelectedIterationId,
      })
    );
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const allowedMimeTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];
    if (allowedMimeTypes.includes(file.type)) {
      handelUploadFile(file);
    } else {
      toastService.error(
        "An error occurred while uploading the file. Please try again.",
        2
      );
    }
  }, []);

  const { isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (projectRecordData) {
      setProjectRecord((prevState) => ({
        ...prevState,
        ...projectRecordData,
      }));
    }
  }, [projectRecordData]);

  const handleDeleteImage = (imageKey) => {
    const updatedProjectRecord = { ...projectRecord };
    updatedProjectRecord[imageKey] = "";
    setProjectRecord(updatedProjectRecord);
  };

  const handleSuggestionClickClient = (selectedClient) => {
    setProjectRecord((prevRecord) => ({
      ...prevRecord,
      client_name: selectedClient?.client_name,
    }));
    dispatch(
      updateProjectProperty({
        _id: projectRecordData._id,
        key: "client_name",
        value: selectedClient?.client_name,
      })
    );
    const selectedClientId = selectedClient?._id;
    clientId.current = selectedClientId;
    setShowSuggestionsClient(false);
  };

  const filteredSuggestionsClient = suggestionsClient.filter((suggestion) =>
    suggestion.client_name.toLowerCase().includes(valueClient.toLowerCase())
  );

  const handleYellowBoxClickClient = (id) => {
    if (id === user.id) {
      setShowSuggestionsClient(!showSuggestionsClient);
    }
  };

  const handelUploadFile = async (file, type) => {
    if (!file) {
      return;
    }

    const validFileTypes = ["image/png", "image/jpeg"];
    if (!validFileTypes.includes(file.type)) {
      setImageError("Only .png or .jpeg files are allowed");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await projectServices.uploadImage(formData);
      if (type === "ins") {
        setProjectRecord((prevState) => ({
          ...prevState,
          inspirational_images: res.data.data.result,
        }));
      } else if (type === "avd") {
        setProjectRecord((prevState) => ({
          ...prevState,
          image_avoidance: res.data.data.result,
        }));
      } else if (type === "inspiration_one") {
        setProjectRecord((prevState) => ({
          ...prevState,
          inspirational_images_one: res.data.data.result,
        }));
      } else if (type === "inspiration_two") {
        setProjectRecord((prevState) => ({
          ...prevState,
          inspirational_images_Two: res.data.data.result,
        }));
      } else if (type === "avdOne") {
        setProjectRecord((prevState) => ({
          ...prevState,
          image_avoidanceOne: res.data.data.result,
        }));
      } else if (type === "avdTwo") {
        setProjectRecord((prevState) => ({
          ...prevState,
          image_avoidanceTwo: res.data.data.result,
        }));
      }
    } catch (ex) {
      toastService.error(
        "An error occurred while uploading the file. Please try again.",
        2
      );
    }
  };

  const handleChange =
    (setData) =>
    ({ currentTarget: input }) => {
      const updatedData = { ...projectRecord, [input.name]: input.value };
      const errorMessage = validationService.validateProperty(input, schema);
      setProjectRecord(updatedData);
      dispatch(
        updateProjectProperty({
          _id: projectRecordData._id,
          key: input.name,
          value: input.value,
        })
      );
      setErrors((prevErrors) => ({
        ...prevErrors,
        [input.name]: errorMessage || "",
      }));
    };

  const handleSubmit = () => {
    const errors = validationService.validate(projectRecord, schema);
    setErrors(errors || {});
    // if (errors) return;
    doSubmit();
  };

  const doSubmit = async () => {
    try {
      const body = {
        client_name: projectRecord.client_name,
        name: projectRecord.name,
        brand_claim_text: projectRecord.brand_claim_text,
        goal: projectRecord.goal,
        inspirational_images: projectRecord.inspirational_images,
        inspirational_images_one: projectRecord.inspirational_images_one,
        inspirational_images_Two: projectRecord.inspirational_images_Two,
        image_avoidance: projectRecord.image_avoidance,
        image_avoidanceOne: projectRecord.image_avoidanceOne,
        image_avoidanceTwo: projectRecord.image_avoidanceTwo,
        target_audience: projectRecord?.target_audience,
        geographic_reach: projectRecord.geographic_reach,
        usp: projectRecord.usp,
        logoSymbol: projectRecord.logoSymbol,
        industry: projectRecord.industry,
        core_value: projectRecord.core_value,
        brand_personality: projectRecord.brand_personality,
        color_preferences: projectRecord.color_preferences,
        primary_competitor: projectRecord.primary_competitor,
        secondary_competitor: projectRecord.secondary_competitor,
        applications: projectRecord.applications,
        plan_name: projectRecord.plan_name,
        iterate_step: 1,
      };
      setLoading(true);
      if (itration) {
        const iterationRes = await projectServices.updateProjectIteration(
          SelectedProjectId,
          { ...body, project_id: SelectedProjectId }
        );

        const sortedIterations = iterationRes.data.data.result.iteration.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        dispatch(selectedIteration(sortedIterations[0]?._id));
        const briefingBody = {
          ...iterationRes?.data?.data?.result,
          iteration_id: sortedIterations[0]?._id,
        };

        const bre = await projectServices.generateBriefing(
          SelectedProjectId,
          briefingBody
        );

        dispatch(updateCompleteProject(bre?.data?.data?.result));
        dispatch(EditItration(false));
        toastService.success("The iteration has been created successfully.", 2);
      } else {
        const newBody = { ...body, iteration_id: SelectedIterationId };
        const result = await projectServices.updateProject(
          SelectedProjectId,
          newBody
        );
        const body2 = {
          _id: result?.data?.data?.result?._id,
        };

        const client = await clientServices?.updateClientProject(
          projectRecordData?.clientId,
          body2
        );

        const briefingBody = {
          ...result?.data?.data?.result,
          iteration_id: SelectedIterationId,
        };
        const bre = await projectServices.generateBriefing(
          result?.data?.data?.result?._id,
          briefingBody
        );
        dispatch(updateCompleteProject(bre?.data?.data?.result));
      }
      const getClient = await clientServices?.getClient(
        projectRecordData?.user_id
      );

      dispatch(saveClientDb(getClient?.data?.data?.result));
      navigate("/designbriefing");
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const {
    getRootProps: getInspirationRootProps,
    getInputProps: getInspirationInputProps,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        alert("Please select a file.");
        return;
      }
      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      if (allowedMimeTypes.includes(file.type)) {
        handelUploadFile(file, "ins");
      } else {
        toastService.error(
          "An error occurred while uploading the file. Please try again.",
          2
        );
      }
    }, []),
  });

  const {
    getRootProps: getAvoidanceRootProps,
    getInputProps: getAvoidanceInputProps,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        alert("Please select a file.");
        return;
      }
      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      if (allowedMimeTypes.includes(file.type)) {
        handelUploadFile(file, "avd");
      } else {
        toastService.error(
          "An error occurred while uploading the file. Please try again.",
          2
        );
      }
    }, []),
  });

  // Update New Three Images
  const {
    getRootProps: getInspirationRootPropsOne,
    getInputProps: getInspirationInputPropsOne,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file?.size > 625000)
        return toastService.error("Max. upload file size: 5MB");

      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      if (allowedMimeTypes.includes(file.type)) {
        handelUploadFile(file, "inspiration_one");
      } else {
      }
    }, []),
  });

  const {
    getRootProps: getInspirationRootPropsTwo,
    getInputProps: getInspirationInputPropsTwo,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file?.size > 625000)
        return toastService.error("Max. upload file size: 5MB");
      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      if (allowedMimeTypes.includes(file.type)) {
        handelUploadFile(file, "inspiration_two");
      } else {
      }
    }, []),
  });

  const {
    getRootProps: getAvoidanceRootPropsOne,
    getInputProps: getAvoidanceInputPropsOne,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file?.size > 625000)
        return toastService.error("Max. upload file size: 5MB");
      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      if (allowedMimeTypes.includes(file.type)) {
        handelUploadFile(file, "avdOne");
      } else {
      }
    }, []),
  });

  const {
    getRootProps: getAvoidanceRootPropsTwo,
    getInputProps: getAvoidanceInputPropsTwo,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file?.size > 625000)
        return toastService.error("Max. upload file size: 5MB");

      const allowedMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];
      if (allowedMimeTypes.includes(file.type)) {
        handelUploadFile(file, "avdTwo");
      } else {
      }
    }, []),
  });

  function timeSince(date) {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;

    if (secondsPast < 60) {
      return `${Math.round(secondsPast)} seconds ago`;
    }

    if (secondsPast < 3600) {
      return `${Math.round(secondsPast / 60)} minutes ago`;
    }

    if (secondsPast <= 86400) {
      return `${Math.round(secondsPast / 3600)} hours ago`;
    }

    if (secondsPast > 86400) {
      const daysPast = Math.round(secondsPast / 86400);
      return `${daysPast} days ago`;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user_id = projectRecordData?.user_id;
        const client = await clientServices.getClient(user_id);
        const clientData = client?.data?.data?.result?.map((item) => ({
          _id: item?._id,
          client_name: item?.client_name,
        }));
        const filteredClients = clientData.filter(
          (item) => item?.client_name === projectRecordData?.client_name
        );

        setSuggestionsClient(clientData);
        dispatch(
          updateProjectProperty({
            _id: projectRecordData?._id,
            key: "clientId",
            value: filteredClients[0]?._id,
          })
        );
      } catch (ex) {}
    };
    fetchData();
  }, []);

  const lastUpdatedDate = new Date(projectRecordData?.updatedAt);
  const timeDifference = timeSince(lastUpdatedDate);

  // Industry DropDown Functionality
  const handleCategoryYellowBox = () => {
    setshowCategorySuggestion(!showCategorySuggestion);
  };

  const handleSubCategryYellowBox = () => {
    setshwowSubCategorySuggestion(!shwowSubCategorySuggestion);
  };

  const handleChangeCategories = (selectcat) => {
    setProjectRecord((prevRecord) => ({
      ...prevRecord,
      industryCategory: selectcat,
      industrySubCategory: "",
    }));
    setshowCategorySuggestion(false);
  };

  const handleSelectSubcategories = (selectedSubcat) => {
    setProjectRecord((prevRecord) => ({
      ...prevRecord,
      industrySubCategory: selectedSubcat,
    }));
    setshwowSubCategorySuggestion(false);
  };
  return (
    <>
      <div className="CreateNewProjectContainer">
        <div className="rightone">
          <div className="timelineBox">
            <div className="timeline">
              <div className="connector"></div>
              <div className="connector"></div>
              <div className="connector"></div>
              <div className="connector"></div>
              <div className="connector"></div>
              <div className="connector"></div>
            </div>
          </div>
          <div className="projectDataBox">
            <p className="ProjectDataPara">Project Data</p>
          </div>
          <div className="DataProjectBox">
            <div className="clientNameInputBox">
              <p className="clientNameParaNew">
                Client name <span style={{ color: "#FD5D5D" }}>*</span>
              </p>
              <p className="Business_Explain" style={{ marginTop: "-13px" }}>
                Select the name of the client to assign the project.
              </p>

              <div
                className={`boxme2 ${
                  showSuggestionsClient ? "show-suggestions2" : ""
                }`}
              >
                <input
                  className="inputForClientBox2"
                  type="text"
                  value={projectRecord.client_name}
                  onClick={handleYellowBoxClickClient}
                  name="client_name"
                  readOnly
                  placeholder="New client"
                />

                {valueClient ? (
                  <svg
                    onClick={() => {
                      setValueClient("");
                    }}
                    className="search-iconClientName"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="#FD5D5D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="#FD5D5D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : null}

                {showSuggestionsClient &&
                  filteredSuggestionsClient.length > 0 && (
                    <ul className="suggestion-list2Data">
                      {filteredSuggestionsClient.map((suggestion) => (
                        <li
                          key={suggestion._id}
                          onClick={() =>
                            handleSuggestionClickClient(suggestion)
                          }
                        >
                          {suggestion.client_name}
                        </li>
                      ))}
                    </ul>
                  )}

                <div
                  className="yellowBox2"
                  onClick={handleYellowBoxClickClient}
                >
                  <svg
                    onClick={handleYellowBoxClickClient}
                    className={`arrow-icon ${
                      showSuggestionsClient ? "up-arrow" : "down-arrow"
                    }`}
                    width="22"
                    height="14"
                    viewBox="0 0 22 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                      fill="black"
                    />
                  </svg>
                </div>
                {errors.client_name && (
                  <small className="text-danger mx-1">
                    Client name is not allowed to be empty
                  </small>
                )}
              </div>
            </div>

            <div className="ProjectNameInputBox">
              <p className="ProjectNamePara">
                Brand Name / Project Name{" "}
                <span style={{ color: "#FD5D5D" }}>*</span>
              </p>
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                This is the brand name that will be used to generate your logo
                and that will be displayed using the selected typography.
              </p>

              <input
                className="changeinputProject"
                value={projectRecord?.name}
                name="name"
                onChange={handleChange(setProjectRecord)}
                placeholder="Add Project Name"
                type="text"
                maxLength={48}
              />
              {errors.name && (
                <small className="text-danger mx-1">
                  Project name is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 48
              </p>
            </div>

            <div className="clientNameInputBoxchange">
              <p className="clientNameParaNew">
                Brand claim
                {/* <span style={{ color: "#FD5D5D" }}>*</span> */}
              </p>
              <input
                className="changeinputProject"
                name="brand_claim_text"
                onChange={handleChange(setProjectRecord)}
                value={projectRecord?.brand_claim_text}
                placeholder="Name of the brand we are going to create"
                type="text"
                maxLength={240}
              />
              {/* {errors.brand_claim_text && (
                <small className="text-danger mx-1">
                  Brand claim is not allowed to be empty
                </small>
              )} */}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 240
              </p>
            </div>

            <div className="GoalsThreediv">
              <p className="Business_Goals">
                Business Goals & Objectives
                <span style={{ color: "#FD5D5D" }}>*</span>
              </p>
              <p className="Business_Explain">
                Explain the purpose and long-term goals of the business. Are you
                aiming to establish authority in a particular niche, increase
                sales, or create a unique market presence? Understanding the
                bigger picture will help align the brand identity with the
                business objectives.
              </p>
              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="goal"
                value={projectRecord?.goal}
                placeholder="Write text please"
                maxLength={7200}
              />
              {errors.goal && (
                <small className="text-danger mx-1">
                  Goal is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 7200
              </p>
            </div>

            <div className="GoalsThreediv">
              <p className="Business_Goals">
                Target Audience<span style={{ color: "#FD5D5D" }}>*</span>
              </p>
              <p className="Business_Explain">
                Describe the ideal customers. Include demographic information
                like age, gender, location, interests, and behaviors. It's
                important to know who the brand will be catering to in order to
                create a design that resonates with the intended audience.
              </p>

              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="target_audience"
                value={projectRecord?.target_audience}
                placeholder="Write text please"
                maxLength={4800}
              />
              {errors.target_audience && (
                <small className="text-danger mx-1">
                  Target audience is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 4800
              </p>
            </div>

            <div className="GoalsThreediv">
              <p className="Business_Goals">
                Geographic Reach<span style={{ color: "#FD5D5D" }}>*</span>
              </p>
              <p className="Business_Explain">
                Describe where this brand will be launched and have its
                operations and customers. This is important to know which style
                of the logo symbol, concept, and brand will be created, as
                different cultures and markets have their own characteristics.
              </p>

              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="geographic_reach"
                value={projectRecord.geographic_reach}
                placeholder="Write text please"
                maxLength={2400}
              />
              {errors.geographic_reach && (
                <small className="text-danger mx-1">
                  Geographic reach is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 2400
              </p>
            </div>

            <div className="GoalsThreediv">
              <p className="Business_Goals">Unique Selling Proposition (USP)</p>
              <p className="Business_Explain">
                What sets the brand apart from competitors? It could be a unique
                product feature, exceptional customer service, or a specific
                value it offers.
              </p>
              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="usp"
                value={projectRecord.usp}
                placeholder="Write text please"
                maxLength={4800}
              />
              {errors.usp && (
                <small className="text-danger mx-1">
                  Unique Selling Proposition is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 4800
              </p>
            </div>

            <div className="GoalsThreediv">
              <p className="Business_Goals">
                Industry<span style={{ color: "#FD5D5D" }}>*</span>
              </p>
              <p className="Business_Explain">
                The industry in which the business operates plays a significant
                role in shaping your brand identity. Different industries have
                distinct visual language, design trends, and expectations.
                Communicating with the industry helps understand the competitive
                landscape and create a brand identity that fits within industry
                norms while still standing out.
              </p>
              {/* DropDown Added */}

              <div
                className={`boxme2 ${
                  showCategorySuggestion ? "show-suggestions2" : ""
                }`}
              >
                <input
                  className="inputForClientBox2"
                  type="text"
                  value={projectRecord?.industryCategory}
                  onClick={handleCategoryYellowBox}
                  name="industryCategory"
                  placeholder="Select industry category"
                />

                {showCategorySuggestion && (
                  <ul className="suggestion-list2Data">
                    {CategoryJson.map((suggestion, idx) => (
                      <li
                        key={idx}
                        onClick={() => handleChangeCategories(suggestion.name)}
                      >
                        {suggestion.name}
                      </li>
                    ))}
                  </ul>
                )}

                <div className="yellowBox2" onClick={handleCategoryYellowBox}>
                  <svg
                    className={`arrow-icon ${
                      showCategorySuggestion ? "up-arrow" : "down-arrow"
                    }`}
                    width="22"
                    height="14"
                    viewBox="0 0 22 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>

              {projectRecord?.industryCategory !== "" &&
                projectRecord?.industryCategory !== "Other" && (
                  <div className="clientNameInputBox">
                    <div
                      className={`boxme2 ${
                        shwowSubCategorySuggestion ? "show-suggestions2" : ""
                      }`}
                    >
                      <input
                        className="inputForClientBox2"
                        type="text"
                        value={projectRecord?.industrySubCategory}
                        onClick={handleSubCategryYellowBox}
                        name="industrySubCategory"
                        readOnly
                        placeholder="Select sub-category"
                      />

                      {shwowSubCategorySuggestion && (
                        <ul className="suggestion-list2Data">
                          {CategoryJson.find(
                            (category) =>
                              category.name === projectRecord?.industryCategory
                          ).subcategories.map((subcategory, idx) => (
                            <li
                              key={idx}
                              onClick={() =>
                                handleSelectSubcategories(subcategory)
                              }
                            >
                              {subcategory}
                            </li>
                          ))}
                        </ul>
                      )}

                      <div
                        className="yellowBox2"
                        onClick={handleSubCategryYellowBox}
                      >
                        <svg
                          onClick={handleSubCategryYellowBox}
                          className={`arrow-icon ${
                            shwowSubCategorySuggestion
                              ? "up-arrow"
                              : "down-arrow"
                          }`}
                          width="22"
                          height="14"
                          viewBox="0 0 22 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      {errors.industryCategory && (
                        <small className="text-danger mx-1">
                          Industry is not allowed to be empty
                        </small>
                      )}
                    </div>
                  </div>
                )}

              {projectRecord?.industryCategory === "Other" && (
                <>
                  <textarea
                    type="text"
                    className="describeInputchange"
                    onChange={handleChange(setProjectRecord)}
                    name="industrySubCategory"
                    value={projectRecord.industrySubCategory}
                    placeholder="Please specify the category"
                  />
                  {errors.industrySubCategory && (
                    <small className="text-danger mx-1">
                      Industry is not allowed to be empty
                    </small>
                  )}
                </>
              )}
            </div>

            <div className="GoalsThreediv">
              <p className="Business_Goals">Core Values</p>
              <p className="Business_Explain">
                Core values are the fundamental beliefs that guide the
                business’s decisions, actions, and interactions. They form the
                moral and ethical compass of your brand. Communicating your core
                values is essential because your brand identity should align
                with and reflect these values.
              </p>
              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="core_value"
                value={projectRecord.core_value}
                placeholder="Write text please"
                maxLength={4800}
              />
              {errors.core_value && (
                <small className="text-danger mx-1">
                  Core values is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 4800
              </p>
            </div>

            <div className="GoalsThreediv">
              <p className="Business_Goals">Brand Personality & Tone</p>
              <p className="Business_Explain">
                Describe the personality traits and tone of the brand. Is it
                playful, serious, modern, traditional, friendly, or
                authoritative? This will impact design choices like color
                palette, typography, and visual style.
              </p>
              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="brand_personality"
                value={projectRecord.brand_personality}
                placeholder="Write text please"
                maxLength={4800}
              />
              {errors.brand_personality && (
                <small className="text-danger mx-1">
                  Brand Personality & Tone is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 4800
              </p>
            </div>

            <div className="GoalsThreediv">
              <p className="Business_Goals">Color Preferences </p>
              <p className="Business_Explain">
                Share any preferences for colors. Explain why certain colors
                resonate with the brand, keeping in mind the psychological
                associations that different colors have.
              </p>
              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="color_preferences"
                value={projectRecord.color_preferences}
                placeholder="Write text please"
                maxLength={960}
              />
              {errors.color_preferences && (
                <small className="text-danger mx-1">
                  Color Preferences is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 960
              </p>
            </div>
            {/* 
            <div className="GoalsThreediv">
              <p className="Business_Goals">Visual Inspiration</p>
              <p className="Business_Explain">
                Upload up to 3 images that inspire Brandfy with a maximum of 5mb
                in jpg or png format
              </p>
              <div
                className="describeButtonchange"
                style={{ marginTop: "10px" }}
              >
                <div className="d-flex align-items-center gp_clss">

                  <div className="position-relative">
                    {projectRecord?.inspirational_images && (
                      <div
                        onClick={() =>
                          handleDeleteImage("inspirational_images")
                        }
                        className="delete_imageIcon"
                      >
                        <RxCross2 />
                      </div>
                    )}

                    <div {...getInspirationRootProps()} className="upld">
                      {projectRecord?.inspirational_images ? (
                        <>
                          <div className="image_upld">
                            {projectRecord.inspirational_images && (
                              <img
                                src={projectRecord.inspirational_images}
                                alt="User"
                              />
                            )}
                          </div>

                          <div className="input_upload">
                            <input
                              name="inspirational_images"
                              {...getInspirationInputProps()}
                              type="file"
                            />
                          </div>
                        </>
                      ) : (
                        <div className="flx_fls">
                          <p>
                            {isDragActive ? (
                              "Drop the files here ..."
                            ) : (
                              <div className="isUplaod_images">
                                <span>Add Images</span>
                                <input
                                  name="inspirational_images"
                                  {...getInspirationInputProps()}
                                  type="file"
                                  className="checkForImage visually-hidden"
                                />
                              </div>
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="position-relative">
                    {projectRecord?.inspirational_images_one && (
                      <div
                        onClick={() =>
                          handleDeleteImage("inspirational_images_one")
                        }
                        className="delete_imageIcon"
                      >
                        <RxCross2 />
                      </div>
                    )}

                    <div {...getInspirationRootPropsOne()} className="upld">
                      {projectRecord.inspirational_images_one && (
                        <>
                          <div className="image_upld">
                            {projectRecord.inspirational_images_one && (
                              <img
                                src={projectRecord.inspirational_images_one}
                                alt="User"
                              />
                            )}
                          </div>

                          <div className="input_upload">
                            <input
                              name="inspirational_images_one"
                              {...getInspirationInputPropsOne()}
                              type="file"
                            />
                          </div>
                        </>
                      )}

                      {projectRecord?.inspirational_images &&
                        !projectRecord.inspirational_images_one && (
                          <div className="flx_fls">
                            <p>
                              {isDragActive ? (
                                "Drop the files here ..."
                              ) : (
                                <div className="isUplaod_images">
                                  <span>Add Images</span>
                                  <input
                                    name="inspirational_images_one"
                                    {...getInspirationInputPropsOne()}
                                    type="file"
                                    className="checkForImage visually-hidden"
                                  />
                                </div>
                              )}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="position-relative">
                    {projectRecord?.inspirational_images_Two && (
                      <div
                        onClick={() =>
                          handleDeleteImage("inspirational_images_Two")
                        }
                        className="delete_imageIcon"
                      >
                        <RxCross2 />
                      </div>
                    )}

                    <div {...getInspirationRootPropsTwo()} className="upld">
                      {projectRecord.inspirational_images_Two && (
                        <>
                          <div className="image_upld">
                            {projectRecord.inspirational_images_Two && (
                              <img
                                src={projectRecord.inspirational_images_Two}
                                alt="User"
                              />
                            )}
                          </div>

                          <div className="input_upload">
                            <input
                              name="inspirational_images_Two"
                              {...getInspirationInputPropsTwo()}
                              type="file"
                            />
                          </div>
                        </>
                      )}

                      {projectRecord?.inspirational_images &&
                        projectRecord?.inspirational_images_one &&
                        !projectRecord.inspirational_images_Two && (
                          <div className="flx_fls">
                            <p>
                              {isDragActive ? (
                                "Drop the files here ..."
                              ) : (
                                <div className="isUplaod_images">
                                  <span>Add Images</span>
                                  <input
                                    name="inspirational_images_Two"
                                    {...getInspirationInputPropsTwo()}
                                    type="file"
                                    className="checkForImage visually-hidden"
                                  />
                                </div>
                              )}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="GoalsThreediv">
              <p className="Business_Goals">
                Essential Conditions For Logosymbol{" "}
              </p>
              <p className="Business_Explain">
                Share information about if there are specific conditions of how
                the logo should look or not look like. This applies for example
                when trying to create a logo that is not similar to the
                competition.
              </p>

              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="logoSymbol"
                value={projectRecord.logoSymbol}
                placeholder="Write text please"
                maxLength={4800}
              />
              {errors.logoSymbol && (
                <small className="text-danger mx-1">
                  Logo symbol is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 4800
              </p>
            </div>

            {/* <div className="GoalsThreediv">
              <p className="Business_Goals">Contrarian Visual Inspiration</p>
              <p className="Business_Explain">
                Upload up to 3 images that inspire Brandfy with a maximum of 5mb
                in jpg or png format
              </p>
              <div
                className="describeButtonchange"
                style={{ marginTop: "10px" }}
              >
                <div className="d-flex align-items-center gp_clss">
                  <div className="position-relative">
                    {projectRecord?.image_avoidance && (
                      <div
                        onClick={() => handleDeleteImage("image_avoidance")}
                        className="delete_imageIcon"
                      >
                        <RxCross2 />
                      </div>
                    )}

                    <div {...getAvoidanceRootProps()} className="upld">
                      {projectRecord.image_avoidance ? (
                        <>
                          <div className="image_upld">
                            {projectRecord.image_avoidance && (
                              <img
                                src={projectRecord.image_avoidance}
                                alt="User"
                              />
                            )}
                          </div>

                          <div className="input_upload">
                            <input
                              name="image_avoidance"
                              {...getAvoidanceInputProps()}
                              type="file"
                            />
                          </div>
                        </>
                      ) : (
                        <div className="flx_fls">
                          <p>
                            {isDragActive ? (
                              "Drop the files here ..."
                            ) : (
                              <div className="isUplaod_images">
                                <span>Add Images</span>
                                <input
                                  name="image_avoidance"
                                  {...getAvoidanceInputProps()}
                                  type="file"
                                  className="checkForImage visually-hidden"
                                />
                              </div>
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="position-relative">
                    {projectRecord?.image_avoidanceOne && (
                      <div
                        onClick={() => handleDeleteImage("image_avoidanceOne")}
                        className="delete_imageIcon"
                      >
                        <RxCross2 />
                      </div>
                    )}

                    <div {...getAvoidanceRootPropsOne()} className="upld">
                      {projectRecord?.image_avoidanceOne && (
                        <>
                          <div className="image_upld">
                            {projectRecord.image_avoidanceOne && (
                              <img
                                src={projectRecord.image_avoidanceOne}
                                alt="User"
                              />
                            )}
                          </div>

                          <div className="input_upload">
                            <input
                              name="image_avoidanceOne"
                              {...getAvoidanceInputPropsOne()}
                              type="file"
                            />
                          </div>
                        </>
                      )}

                      {projectRecord?.image_avoidance &&
                        !projectRecord.image_avoidanceOne && (
                          <div className="flx_fls">
                            <p>
                              {isDragActive ? (
                                "Drop the files here ..."
                              ) : (
                                <div className="isUplaod_images">
                                  <span>Add Images</span>
                                  <input
                                    name="image_avoidanceOne"
                                    {...getAvoidanceInputPropsOne()}
                                    type="file"
                                    className="checkForImage visually-hidden"
                                  />
                                </div>
                              )}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>


                  <div className="position-relative">
                    {projectRecord?.image_avoidanceTwo && (
                      <div
                        onClick={() => handleDeleteImage("image_avoidanceTwo")}
                        className="delete_imageIcon"
                      >
                        <RxCross2 />
                      </div>
                    )}

                    <div {...getAvoidanceRootPropsTwo()} className="upld">
                      {projectRecord?.image_avoidanceTwo && (
                        <>
                          <div className="image_upld">
                            {projectRecord.image_avoidanceTwo && (
                              <img
                                src={projectRecord.image_avoidanceTwo}
                                alt="User"
                              />
                            )}
                          </div>

                          <div className="input_upload">
                            <input
                              name="image_avoidanceTwo"
                              {...getAvoidanceInputPropsTwo()}
                              type="file"
                            />
                          </div>
                        </>
                      )}
                      {projectRecord?.image_avoidance &&
                        projectRecord.image_avoidanceOne &&
                        !projectRecord.image_avoidanceTwo && (
                          <div className="flx_fls">
                            <p>
                              {isDragActive ? (
                                "Drop the files here ..."
                              ) : (
                                <div className="isUplaod_images">
                                  <span>Add Images</span>
                                  <input
                                    name="image_avoidanceOne"
                                    {...getAvoidanceInputPropsTwo()}
                                    type="file"
                                    className="checkForImage visually-hidden"
                                  />
                                </div>
                              )}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="GoalsThreediv">
              <p className="Business_Goals">
                Competitors <span style={{ color: "#FD5D5D" }}>*</span>
              </p>
              <p className="Business_Explain">
                Share information about the competitors, their brand identities,
                and any differences. This will help ensure the brand stands out
                and doesn’t get lost in the crowd.
              </p>

              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="primary_competitor"
                value={projectRecord.primary_competitor}
                placeholder="Write text please"
                maxLength={5760}
              />
              {errors.primary_competitor && (
                <small className="text-danger mx-1">
                  Main Competitors is not allowed to be empty
                </small>
              )}
              <p style={{ marginBottom: "15px" }} className="Business_Explain">
                Maximum Characters Allowed: 5760
              </p>
            </div>

            {/* <div className="GoalsThreediv">
              <p className="Business_Goals">Competitors (optional) </p>
              <p className="Business_Explain">
                Share information about the competitors, their brand identities,
                and any differences. This will help ensure the brand stands out
                and doesn’t get lost in the crowd.
              </p>
              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="secondary_competitor"
                value={projectRecord.secondary_competitor}
                placeholder="Write text please"
              />
            </div> */}

            <div className="GoalsThreediv">
              <p className="Business_Goals">Applications </p>
              <p className="Business_Explain">
                Inform about the various platforms and mediums where the brand
                identity will be used—business cards, websites, social media,
                merchandise, etc. This will influence how the brand identity is
                adapted for different contexts.
              </p>

              <textarea
                type="text"
                className="describeInputchange"
                onChange={handleChange(setProjectRecord)}
                name="applications"
                value={projectRecord.applications}
                placeholder="Write text please"
              />
              {errors.applications && (
                <small className="text-danger mx-1">
                  Applications is not allowed to be empty
                </small>
              )}
            </div>

            <div className="GobackNextBtn">
              <button
                onClick={() => navigate("/dashboard")}
                className="goBackBtn"
              >
                <span>Go back</span>
              </button>
              <button
                onClick={() => {
                  handleSubmit();
                }}
                className="NextBtn"
              >
                <span>Next step</span>
                {/* <svg
                  className="questionMark"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.09 8.99959C9.3251 8.33126 9.78915 7.7677 10.4 7.40873C11.0108 7.04975 11.7289 6.91853 12.4272 7.0383C13.1255 7.15808 13.7588 7.52112 14.2151 8.06312C14.6713 8.60512 14.9211 9.29112 14.92 9.99959C14.92 11.9996 11.92 12.9996 11.92 12.9996"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 17H12.01"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg> */}
              </button>
            </div>
          </div>
        </div>
        <div className="RightSideDashboard">
          <RightBar />
        </div>
      </div>

      {loading && (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <DoingMagic />
        </div>
      )}
    </>
  );
};

export default ProjectUpdate;
