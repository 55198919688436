// reducers/projectRecordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  _id: "",
  user_id: "",
  client_name: "",
  name: "",
  brand_claim_text: "",
  goal: "",
  target_audience: '',
  geographic_reach: "",
  usp: '',
  logoSymbol: "",
  industry: '',
  core_value: "",
  brand_personality: '',
  color_preferences: '',
  primary_competitor: "",
  secondary_competitor: "",
  inspirational_images: '',
  image_avoidance: '',
  clientId: "",
  applications: '',
  selected_fonts: "",
  selected_color_palette: "",
  selected_symbol: "",
  clientId: "",
  briefing: {},
  fonts: [],
  color_palette: [],
  symbol: [],
  logo: [],
  iteration: [],
  update: false,
  result: {},
  updatedAt: "",
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    saveProject: (state, action) => {
      const updatedProps = action.payload;
      Object.keys(updatedProps).forEach((key) => {
        if (state.hasOwnProperty(key)) {
          state[key] = updatedProps[key];
        }
      });
    },
    updateProject: (state, action) => {
      const { property, value } = action.payload;
      state[property] = value;
    },
    deleteProject(state, action) {
      const itemId = action.payload;
      state.items = state.items.filter((item) => item.id !== itemId);
    },
    deleteProjectRecord: (state) => {
      return initialState;
    },
  },
});

export const { deleteData } = projectSlice.actions;
export const { saveProject } = projectSlice.actions;
export const { updateProject } = projectSlice.actions;
export const { deleteProjectRecord } = projectSlice.actions;
export default projectSlice.reducer;