import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useSearchParams } from "components/lib";

import "./Home.css";
import GoogleLogin from "@leecheuk/react-google-login";
import Joi from "joi";
import validationService from "services/validationService";
import toastService from "services/toastService";
import authServices from "services/authServices";
import { gapi } from "gapi-script";
import clientServices from "services/clientServices";
import projectServices from "services/projectServices";
import memberServices from "services/memberServices";
import { useDispatch } from "react-redux";
import { addProject } from "app/reducers/projectEditSlice";
import { CircularProgress } from "@mui/material";
import { saveRememberMe } from "app/reducers/rememberMe";
import TagManager from 'react-gtm-module'

export function Home() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const plan = searchParams.get('plan');
  if(plan) {
    localStorage?.setItem("plan", plan)
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loginError, setLoginerror] = useState("");
  const [loading, setLoading] = useState(false);
  const schema = Joi.object().keys({
    email: Joi.string()
      .email({ tlds: { tlds: false } })
      .label("Email"),
    password: Joi.string().required().label("Password"),
    provider: Joi.string().required(),
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [googleLoading, setGoogleLoading] = useState(false);
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "628243039684-36j7aqhcvkr1fg6bfr5m37c4nrr4hptm.apps.googleusercontent.com",
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);
  const handleSubmit = () => {
    setLoading(true);
    let data = {
      email,
      password,
    };

    const errors = validationService.validate(data, schema);
    setErrors(errors || {});
    if (errors) {
    }

    const isValidEmialCharLength = email.split("@")[0];
    const isUsernameValid = isValidEmialCharLength?.length < 4;
    if (isUsernameValid) {
      toastService.error("Email Name Length Should be Greater than 4");
    } else {
      doSubmit();
    }
  };

  const handleChange =
    (setData) =>
    ({ currentTarget: input }) => {
      const errorMessage = validationService.validateProperty(input, schema);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
      setErrors(errors);
      setData(input.value);
    };

  const dispatch = useDispatch();
  const doSocialSubmit = async (account) => {
    const data = {
      email: account.profileObj.email,
      provider: "google",
      userName: account.profileObj.name,
      picture: account.profileObj.imageUrl,
      googleId: account.googleId,
      firstName: account.profileObj.givenName,
      lastName: account.profileObj.familyName,
      phoneNo: "",
    };

    try {
      let response = await authServices.login(data);
      response.data.data.provider = "google";
      const client = await clientServices.getClient(response.data.data.id);
      if (client.data.data.result.length > 0) {
        const data = {
          ...response.data.data,
          client: true,
        };
        const personalDataRes = await projectServices.getProjectByUserId(
          response.data.data.id
        );
        const memberDataRes = await memberServices.getProjectsAsMember(
          response.data.data.id
        );
        const personalProjects = personalDataRes.data.data.result;
        const memberProjects = memberDataRes.data.data.result.map((item) => ({
          ...item.project,
        }));
        const combinedProjects = [...personalProjects, ...memberProjects];
        combinedProjects.map((project) => {
          dispatch(addProject(project));
        });
        dispatch(saveRememberMe(true));
        authServices.storeLoginData(response.data.token, data);
      } else {
        const data = {
          ...response.data.data,
          client: false,
        };
        const personalDataRes = await projectServices.getProjectByUserId(
          response.data.data.id
        );
        const memberDataRes = await memberServices.getProjectsAsMember(
          response.data.data.id
        );
        const personalProjects = personalDataRes.data.data.result;
        const memberProjects = memberDataRes.data.data.result.map((item) => ({
          ...item.project,
        }));
        const combinedProjects = [...personalProjects, ...memberProjects];
        combinedProjects.map((project) => {
          dispatch(addProject(project));
        });
        setLoading(false);
        authServices.storeLoginData(response.data.token, data);
      }
      toastService.success(
        `Successfully logged in. Welcome back, ${response.data.data.userName}.`
      );
      setGoogleLoading(false);
      navigate("/dashboard");
      dispatch(saveRememberMe(isChecked));
      authServices.getCurrentUser();
    } catch (ex) {
      setGoogleLoading(false);
      toastService.error(ex);
    }
  };

  const doSubmit = async () => {
    const data = {
      email,
      password,
      provider: "app",
    };
    try {
      let response = await authServices.login(data);
      response.data.data.provider = "app";
      const client = await clientServices.getClient(response.data.data.id);
      if (client.data.data.result.length > 0) {
        const data = {
          ...response.data.data,
          client: true,
        };
        const personalDataRes = await projectServices.getProjectByUserId(
          response.data.data.id
        );
        const memberDataRes = await memberServices.getProjectsAsMember(
          response.data.data.id
        );
        const personalProjects = personalDataRes.data.data.result;
        const memberProjects = memberDataRes.data.data.result.map((item) => ({
          ...item.project,
        }));
        const combinedProjects = [...personalProjects, ...memberProjects];
        combinedProjects.map((project) => {
          dispatch(addProject(project));
        });
      
        authServices.storeLoginData(response.data.token, data);
      } else {
        const data = {
          ...response.data.data,
          client: false,
        };
        const personalDataRes = await projectServices.getProjectByUserId(
          response.data.data.id
        );
        const memberDataRes = await memberServices.getProjectsAsMember(
          response.data.data.id
        );
        const personalProjects = personalDataRes.data.data.result;
        const memberProjects = memberDataRes.data.data.result.map((item) => ({
          ...item.project,
        }));
        const combinedProjects = [...personalProjects, ...memberProjects];
        combinedProjects.map((project) => {
          dispatch(addProject(project));
        });
        authServices.storeLoginData(response.data.token, data);
      }
      toastService.success(
        `Successfully logged in. Welcome back, ${response.data.data.userName}.`
      );
      setLoading(false);

      window.dataLayer.push({
        'event': 'login',
        'userId': response?.data?.data?.id,
        'plan': response?.data?.data?.plan
      })

      navigate("/dashboard");
      // }
      authServices.getCurrentToken();
      authServices.getCurrentUser();
    } catch (ex) {
      toastService.error(ex);
      setLoading(false);
    }
  };

  const renderButton = (renderProps) => (
    <button
      onClick={renderProps.onClick}
      disabled={renderProps.disabled}
      className="googleLoginButton"
    >
      <img
        style={{
          height: "29.952px",
          width: "29.952px",
          marginLeft: "176.256px",
          marginRight: "9.936px",
        }}
        src="GoogleLoginIcon.png"
        alt=""
      />
      Google
    </button>
  );
  const onFailure = (error) => {};
  const onsuccess = (response) => {
    setGoogleLoading(true);
    doSocialSubmit(response);
  };

  useEffect(() => {
    if (authServices.getCurrentUser() && authServices.getCurrentToken()) {
      navigate("/dashboard");
    }
  });

  return (
    <>
      <div className="LoginContainer">
        <div className="leftSideFormBox">
          {googleLoading && (
            <div className="login-loading">
              <CircularProgress style={{ color: "#f5ff01" }} />
            </div>
          )}

          <div className="BrandfyLogoLogin">
            <img src="brandfy_black.svg" alt="" />
          </div>

          <div className="loginFormBox">
            <p className="loginYourPara">Login to your account</p>
            <p className="welcomeBackPara">
              Welcome back! Select method to log in:
            </p>
            <GoogleLogin
              cookiePolicy="single_host_origin"
              onFailure={onFailure}
              prompt="select_account"
              onSuccess={onsuccess}
              render={renderButton}
              clientId="628243039684-36j7aqhcvkr1fg6bfr5m37c4nrr4hptm.apps.googleusercontent.com"
            />
            <div className="continueEmail">
              <div className="line"></div>
              <p className="continuePara">or continue with email</p>
              <div className="line"></div>
            </div>

            <input
              type="text"
              placeholder="Email"
              name="email"
              className="LoginInput"
              autoCorrect="new-email"
              required
              value={email}
              onChange={handleChange(setEmail)}
            />
            {errors.email && (
              <span className="HomepageError">{errors.email}</span>
            )}
            <input
              type="password"
              placeholder="Password"
              name="password"
              className="LoginInput"
              required
              value={password}
              onChange={handleChange(setPassword)}
            />
            {errors.password && (
              <span className="HomepageError">{errors.password}</span>
            )}

            <div className="rememForgetBox">
              <div className="rememberDiv">
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "19.872px",
                      height: "20px",
                    },
                    color: "red",
                    "&.Mui-checked": {
                      color: "red",
                    },
                  }}
                />

                <p className="RememberPara">Remember me</p>
              </div>
              <p
                onClick={() => {
                  navigate("/forgotpassword");
                }}
                className="forgetPara"
              >
                Forgot Password?
              </p>
            </div>

            <button
              className="LoginButtonForm"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={loading}
            >
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <span className="login_text">LOG IN</span>
              )}
            </button>

            {loginError && (
              <span className="HomepageErrorbtn">{loginError}</span>
            )}
            <div className="dontHaveCreateBox">
              <p className="haveAccountPara">Don’t have account?</p>
              <p
                className="createAccountPara"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Create an account
              </p>
            </div>
          </div>
        </div>
        <div className="RightSideFormBox">
          <img src="thinkPic.png" alt="" />
        </div>
      </div>
    </>
  );
}
