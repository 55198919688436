import React from "react";

import RightBar from "views/dashboard/RightBar";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import data from "../ManageClients/data";
import Joi from "joi";
import { useDispatch } from "react-redux";
import { saveClient } from "../../../app/reducers/client";
import authServices from "services/authServices";
import validationService from "services/validationService";
import clientServices from "services/clientServices";
import { saveClientDb } from "app/reducers/clientDbSlice";
import { saveActiveButton } from "app/reducers/activeButton";
import { CircularProgress } from "@mui/material";
import toastService from "services/toastService";

function NewClient() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [spaceError, setspaceError] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [showSuggestionsClient, setShowSuggestionsClient] = useState(false);
  const [client, setClient] = useState({
    user_id: "",
    client_name: "",
    company_name: "",
    city: "",
    // zip_code: "",
    country: "",
    vat_no: "1234",
  });

  dispatch(saveActiveButton("Clients"));

  const schema = Joi.object().keys({
    user_id: Joi.string().required().allow(""),
    client_name: Joi.string().required().label("client_name"),
    company_name: Joi.string().required().label("company_name"),
    city: Joi.string().label("city").required(),
    // zip_code: Joi.string().label("zip_code").required(),
    country: Joi.string().required(),
    vat_no: Joi.string().label("vat_no").required(),
  });

  const handleChange =
    () =>
      ({ currentTarget: input }) => {
        const updatedData = { ...client, [input.name]: input.value };
        const errorMessage = validationService.validateProperty(input, schema);
        setClient(updatedData);
        setErrors((prevErrors) => ({
          ...prevErrors,
          [input.name]: errorMessage || "",
        }));
      };

  const handleSubmit = () => {
    const errors = validationService.validate(client, schema);
    setErrors(errors || {});
    if (spaceError)
      return toastService.error("Fields are not allowed to be empty!!");
    if (errors) return;
    doSubmit();
  };

  const doSubmit = async () => {
    const user = authServices.getCurrentUser();
    const data = { ...client, user_id: user.id };

    try {
      setisLoading(true);
      const res = await clientServices.CreateClient(data);
      dispatch(saveClient(res.data.data.result));
      const client = await clientServices.getClient(user.id);
      dispatch(saveClientDb(client.data.data.result));
      authServices.updateClientProperty(true);
      navigate("/manageclients");
    } catch (ex) {
      setisLoading(false);
    }
  };

  const handleInputChangeClient = (event) => {
    if (errors.country) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["country"]: "",
      }));
    }
    setShowSuggestionsClient(true);
    setClient({ ...client, country: event.target.value });
  };

  const handleSuggestionClickClient = (suggestion) => {
    setClient({ ...client, country: suggestion });
    if (errors.country) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["country"]: "",
      }));
    }
    setShowSuggestionsClient(false);
  };

  const handleYellowBoxClickClient = () => {
    setShowSuggestionsClient(!showSuggestionsClient);
  };

  const filteredSuggestionsClient = data.filter((suggestion) =>
    suggestion.toLowerCase().includes(client.country.toLowerCase())
  );

  const validateSpace = (value) => {
    const trimmedValue = value.trim();
    const containsNonSpace = trimmedValue.length > 0;
    setspaceError(!containsNonSpace);
    return containsNonSpace;
  };

  return (
    <div className="ManageClientsContainer">
      <div className="leftSideManageClients">
        <div className="newClientFormDiv">
          <p className="currentManageClientsPara">New client</p>
          <div className="newClientFormDiv1">
            <p className="companyNamepara">Client name</p>
            <input
              placeholder="Client name"
              name="client_name"
              value={client.client_name}
              onChange={handleChange(setClient)}
              className="companyaddresspara"
              type="text"
              onKeyUp={(e) => validateSpace(e.target.value)}
            />
            {errors.client_name && (
              <small className="text-danger mx-1">
                Client name is not allowed to be empty
              </small>
            )}

            <p className="companyNamepara">Company name</p>
            <input
              placeholder="Company name"
              name="company_name"
              value={client.company_name}
              onChange={handleChange(setClient)}
              className="companyaddresspara"
              type="text"
              onKeyUp={(e) => validateSpace(e.target.value)}
            />
            {errors.company_name && (
              <small className="text-danger mx-1 block">
                Company name is not allowed to be empty
              </small>
            )}

            <div style={{ display: "flex" }}>
              <div className="clientCItyDiv">
                <div className="cityDivBox">
                  <p className="companyNameparaCity">City</p>
                  <input
                    placeholder="City"
                    name="city"
                    value={client.city}
                    onChange={handleChange(setClient)}
                    className="companyaddressparacity"
                    type="text"
                    onKeyUp={(e) => validateSpace(e.target.value)}
                  />
                  {errors.city && (
                    <small className="text-danger  block">
                      City name is not allowed to be empty
                    </small>
                  )}
                </div>

                {/* <div className="cityDivBox">
                  <p className="companyNameparaCity">Zip code</p>
                  <input
                    placeholder="000 000 000"
                    name="zip_code"
                    value={client.zip_code}
                    onChange={handleChange(setClient)}
                    className="companyaddressparacity"
                    type="text"
                  />
                  {errors.zip_code && (
                    <small className="text-danger  block">
                      Zip code is not allowed to be empty
                    </small>
                  )}
                </div> */}
              </div>
            </div>
            <div className="forSuggestionBox">
              <div className="clientNameInputBox221">
                <p className="companyNameparaCity">Country</p>
                <div
                  className={`boxme2ClientCountry ${showSuggestionsClient ? "show-suggestions2Client" : ""
                    }`}
                >
                  <input
                    className="inputForClientBox2Client"
                    type="text"
                    name="country"
                    value={client.country}
                    onClick={handleYellowBoxClickClient}
                    onChange={handleInputChangeClient}
                    placeholder="Select country"
                    onKeyUp={(e) => validateSpace(e.target.value)}
                  />
                  {client.country ? (
                    <svg
                      onClick={() => {
                        setClient({ ...client, country: "" });
                      }}
                      className="search-iconCountryName"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 6L6 18"
                        stroke="#FD5D5D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 6L18 18"
                        stroke="#FD5D5D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    ""
                  )}

                  {showSuggestionsClient &&
                    filteredSuggestionsClient.length > 0 && (
                      <ul className="suggestion-list2CountryTeam">
                        {filteredSuggestionsClient.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              handleSuggestionClickClient(suggestion)
                            }
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}

                  <div
                    className="yellowBox2Client"
                    onClick={handleYellowBoxClickClient}
                  >
                    <svg
                      onClick={handleYellowBoxClickClient}
                      className={`arrow-icon ${showSuggestionsClient ? "up-arrow" : "down-arrow"
                        }`}
                      width="22"
                      height="14"
                      viewBox="0 0 22 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                {errors.country && (
                  <small className="text-danger mx-1 block">
                    Country is not allowed to be empty
                  </small>
                )}
              </div>
            </div>
            <div className="dottedLine"></div>

            <div className="buttonsBtn">
              <button
                onClick={() => {
                  handleSubmit();
                }}
                className="saveNewClient"
                disabled={isLoading}
              >
                <svg
                  className="saveNewClientsvg"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.5 21.5H5.5C4.96957 21.5 4.46086 21.2893 4.08579 20.9142C3.71071 20.5391 3.5 20.0304 3.5 19.5V5.5C3.5 4.96957 3.71071 4.46086 4.08579 4.08579C4.46086 3.71071 4.96957 3.5 5.5 3.5H16.5L21.5 8.5V19.5C21.5 20.0304 21.2893 20.5391 20.9142 20.9142C20.5391 21.2893 20.0304 21.5 19.5 21.5Z"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.5 21.5V13.5H7.5V21.5"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 3.5V8.5H15.5"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {isLoading ? (
                  <CircularProgress
                    style={{ width: 24, height: 24, color: "white" }}
                    color="primary"
                    variant="indeterminate"
                  />
                ) : (
                  <span className="pt-1">Save</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="RightSideDashboard">
        <RightBar />
      </div>
    </div>
  );
}
export default NewClient;
