import React from "react";
import "./Faq.css";
import authServices from "services/authServices";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveActiveButton } from "app/reducers/activeButton";
import { Link } from "feather-icons-react/build/IconComponents";
const Faq = () => {
  const user = authServices.getCurrentUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMailClick = () => {
    const mailto =
      "mailto:hello@brandfy.ai?subject=Need Help&body=Your message here";
    window.open(mailto, "_self");
  };

  return (
    <div className="FAQContainer">
      <div className="LeftSideFAQ">
        <span className="FAQParaBox">FAQ</span>

        <div className="FAQDetailBox">
          <p className="headingParaFAQ">1. What is Brandfy?</p>
          <p className="headingParaDetail">
            Brandfy is an innovative AI-powered design platform that assists
            designers of all types in creating captivating and visually
            appealing brand assets. It leverages the power of artificial
            intelligence to generate inspiration moodboards, logos, brand
            universes, typography, color palettes, and more. With Brandfy's
            user-friendly web dashboard, designers can enhance their design
            processes and achieve stunning results efficiently.
          </p>
          <p className="headingParaFAQ">2. How does Brandfy work?</p>
          <p className="headingParaDetail">
            Brandfy utilizes advanced AI algorithms to analyze extensive design
            databases and generate brand assets based on user preferences. The
            platform's machine learning capabilities allow it to understand and
            adapt to individual design styles, resulting in personalized and
            high-quality design outputs. By inputting specific requirements and
            parameters, designers can generate inspiring visual elements that
            align with their brand vision.
          </p>
          <p className="headingParaFAQ">
            3. What types of brand assets can Brandfy generate?
          </p>
          <p className="headingParaDetail">
            Brandfy can generate a wide range of brand assets, including but not
            limited to:
            <ul className="liStyle">
              <li>
                Inspiration Moodboards: Curated collections of images, color
                schemes, and typography that evoke the desired brand aesthetics
                and mood.
              </li>
              <li>
                Logos: Unique and visually striking logo designs that capture
                the essence of a brand.
              </li>
              <li>
                Brand Universes: Comprehensive visual representations of a
                brand's personality, values, and identity, including imagery,
                typography, color schemes, and design elements.
              </li>
              <li>
                Typography: Customized typography suggestions that match the
                brand's style and create visual harmony.
              </li>
              <li>
                Color Palettes: Harmonious color combinations that evoke the
                desired emotions and reinforce brand identity.
              </li>
              <li>
                Design Templates: Pre-designed templates for various marketing
                collaterals, such as social media posts, banners, brochures, and
                more.
              </li>
            </ul>
          </p>
          <p className="headingParaFAQ">
            4. Can Brandfy be used by designers of all skill levels?
          </p>
          <p className="headingParaDetail">
            Absolutely! Brandfy is designed to be accessible and beneficial to
            designers of all skill levels. Whether you're a beginner looking for
            design inspiration or an experienced professional seeking to
            streamline your design process, Brandfy's intuitive web dashboard
            provides a seamless experience. The platform's AI-generated
            suggestions can serve as a starting point or a source of
            inspiration, allowing designers to save time and enhance their
            creative workflows.
          </p>
          <p className="headingParaFAQ">
            5. Is Brandfy suitable for specific industries or design styles?
          </p>
          <p className="headingParaDetail">
            Yes, Brandfy caters to a wide range of industries and design styles.
            Its AI algorithms have been trained on diverse design samples,
            enabling the platform to adapt and generate assets suitable for
            various sectors, such as technology, fashion, food and beverage,
            healthcare, and more. Whether you're aiming for minimalistic, bold,
            vintage, or contemporary designs, Brandfy can assist you in creating
            assets tailored to your specific industry or design preferences.
          </p>
          <p className="headingParaFAQ">
            6. Can Brandfy integrate with other design tools or software?
          </p>
          <p className="headingParaDetail">
            Brandfy is designed to seamlessly integrate into your existing
            design workflow. While it offers a comprehensive set of design
            capabilities, it also allows you to export and import assets in
            various formats compatible with popular design software. This
            ensures that you can easily incorporate Brandfy-generated assets
            into your preferred design tools and continue working on them
            without any disruption.
          </p>
          <p className="headingParaFAQ">
            7. Is the AI-generated content by Brandfy unique?
          </p>
          <p className="headingParaDetail">
            Brandfy's AI algorithms generate content based on an extensive
            analysis of design databases and user preferences. While the output
            may share similarities with existing designs, the generated content
            is designed to be unique and tailored to each user's specifications.
            However, it is important to note that Brandfy does not claim
            ownership or exclusive rights over the generated content, and
            designers should exercise their own judgment and creativity in
            refining the assets according to their brand requirements.
          </p>
          <p className="headingParaFAQ">
            8. Can Brandfy be used for both personal and commercial design
            projects?
          </p>
          <p className="headingParaDetail">
            Yes, Brandfy can be used for both personal and commercial design
            projects. Whether you're designing for your own brand, a client's
            brand, or exploring personal creative endeavors, Brandfy's
            AI-powered design platform provides valuable tools and inspiration
            to elevate your design projects. It enables you to generate
            professional-grade brand assets efficiently, allowing you to focus
            more on the creative aspects of your work.
          </p>
          <p className="headingParaFAQ">
            9. How can I get started with Brandfy?
          </p>
          <p className="headingParaDetail">
            To get started with Brandfy, simply visit our website and sign up
            for an account. Once registered, you'll have access to the web
            dashboard, where you can begin exploring the various features and
            generating brand assets powered by AI. The platform provides
            intuitive guidance throughout the process, ensuring a user-friendly
            experience for designers of all backgrounds and expertise levels.
          </p>
          <p className="headingParaFAQ">
            10. What compliance measures does Brandfy have in place to ensure
            design assets are legally compliant?
          </p>
          <p className="headingParaDetail">
            Brandfy takes compliance seriously and strives to provide design
            assets that adhere to legal and ethical standards. The platform's AI
            algorithms are trained to generate content that is original and does
            not infringe upon existing copyrights or trademarks. However, it is
            important to note that Brandfy does not assume responsibility for
            any legal issues that may arise from the use or modification of the
            generated assets. Users are advised to exercise due diligence and
            consult legal professionals if they have concerns regarding
            compliance and intellectual property rights.
          </p>
          <p className="headingParaFAQ">
            11. Who owns the designs and brand assets generated using Brandfy?
          </p>
          <p className="headingParaDetail">
            The ownership of the designs and brand assets generated using
            Brandfy resides with the users who create them. Brandfy does not
            claim ownership or any exclusive rights over the generated content.
            As a user, you retain full ownership and control over the assets you
            create. It is important to review and understand the terms of
            service and licensing agreements associated with Brandfy to ensure a
            clear understanding of ownership rights.
          </p>
          <p className="headingParaFAQ">
            12. Can users purchase additional projects or assets within each
            plan?
          </p>
          <p className="headingParaDetail">
            Yes, Brandfy offers flexible subscription plans that allow users to
            purchase additional projects or assets beyond the included limits.
            Depending on the subscription level, users can typically choose to
            upgrade or add-on more projects, access premium design features, or
            unlock additional design assets. The pricing and availability of
            these options may vary, so it's recommended to review the
            subscription details on the Brandfy website or dashboard for
            specific information on purchasing additional projects or assets.
          </p>
          <p className="headingParaFAQ">
            13. Are there any limitations on the usage of the designs and assets
            generated by Brandfy?
          </p>
          <p className="headingParaDetail">
            Brandfy allows users to utilize the designs and assets generated
            through the platform for both personal and commercial purposes.
            However, it is important to adhere to the terms of service and
            licensing agreements associated with Brandfy. While you have
            ownership of the designs, certain restrictions may apply, such as
            limitations on reselling or distributing the assets as standalone
            products. It's advisable to carefully review the licensing terms and
            restrictions to ensure compliance with the agreed-upon usage
            guidelines.
          </p>
          <p className="headingParaFAQ">
            14. What happens if a user cancels their Brandfy subscription?
          </p>
          <p className="headingParaDetail">
            If a user decides to cancel their Brandfy subscription, their access
            to the platform and its features will be limited according to the
            terms of the cancellation policy. Typically, users will retain
            access to their previously generated designs and assets but may lose
            the ability to create new projects or access certain premium
            features. It is recommended to review the cancellation policy and
            understand the implications before proceeding with the cancellation
            to ensure a smooth transition and backup of any required design
            assets.
          </p>
          <p className="headingParaFAQ">
            15. How does Brandfy handle user data and privacy?
          </p>
          <p className="headingParaDetail">
            Brandfy prioritizes the protection of user data and follows industry
            best practices to safeguard user privacy. The platform collects and
            stores user data in accordance with its privacy policy, which
            outlines the type of data collected, how it is used, and the
            measures taken to ensure its security. Brandfy does not share user
            data with third parties for marketing purposes without explicit
            consent. It's advisable to review the privacy policy to gain a
            comprehensive understanding of how user data is handled within the
            platform.
          </p>
          <p className="headingParaFAQ">
            16. What support options are available to users if they encounter
            any issues or have further questions?
          </p>
          <p className="headingParaDetail">
            Brandfy offers comprehensive support options to assist users with
            any issues or inquiries they may have. This typically includes a
            knowledge base or FAQ section on the website that addresses common
            questions and concerns. Additionally, users can reach out to the
            Brandfy support team directly through email or a support ticket
            system. The support team is dedicated to providing timely and
            helpful responses to ensure a smooth user experience and address any
            concerns promptly.
          </p>
          <p className="headingParaFAQ">
            17. Can Brandfy be used collaboratively by teams or multiple
            designers?
          </p>
          <p className="headingParaDetail">
            Yes, Brandfy is designed to support collaborative work among teams
            and multiple designers. The platform provides features to facilitate
            teamwork, such as shared project folders, the ability to invite team
            members, and collaborative editing capabilities. With Brandfy, teams
            can work together seamlessly, share feedback, and maintain a
            cohesive brand identity throughout their design projects.
          </p>
        </div>
      </div>

      <div className="RightSideFAQ">
        <span className="contactUsPara">Contact us</span>
        <button
          onClick={() => {
            window.open("mailto:hello@brandfy.ai", "_blank");
          }}
          className="mailFAQ"
        >
          <svg
            className="mailFAQsvg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5 3.00023C18.8978 2.6024 19.4374 2.37891 20 2.37891C20.5626 2.37891 21.1022 2.6024 21.5 3.00023C21.8978 3.39805 22.1213 3.93762 22.1213 4.50023C22.1213 5.06284 21.8978 5.6024 21.5 6.00023L12 15.5002L8 16.5002L9 12.5002L18.5 3.00023Z"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Mail
        </button>

        <button
          className="mailFAQ"
          onClick={() =>
            window.open("https://discord.com/invite/jCzkaEUWNJ", "_blank")
          }
        >
          <svg
            style={{ width: "30px", height: "25px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-discord"
            viewBox="0 0 16 16"
          >
            <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
          </svg>
          Discord
        </button>

        <button
          className="UpgradeBtnsFAQ"
          onClick={() => {
            dispatch(saveActiveButton("Billing"));
            navigate("/billing");
          }}
        >
          <svg
            className="mailFAQsvg"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1386_2574)">
              <path
                d="M9 22.5C9.55228 22.5 10 22.0523 10 21.5C10 20.9477 9.55228 20.5 9 20.5C8.44772 20.5 8 20.9477 8 21.5C8 22.0523 8.44772 22.5 9 22.5Z"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 22.5C20.5523 22.5 21 22.0523 21 21.5C21 20.9477 20.5523 20.5 20 20.5C19.4477 20.5 19 20.9477 19 21.5C19 22.0523 19.4477 22.5 20 22.5Z"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1.5H5L7.68 14.89C7.77144 15.3504 8.02191 15.764 8.38755 16.0583C8.75318 16.3526 9.2107 16.509 9.68 16.5H19.4C19.8693 16.509 20.3268 16.3526 20.6925 16.0583C21.0581 15.764 21.3086 15.3504 21.4 14.89L23 6.5H6"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1386_2574">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          Upgrade
        </button>
        <img className="FAQImagePage" src="EmployeeDoing.png" alt="" />
      </div>
    </div>
  );
};

export default Faq;
