import Axios from "axios";

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { AuthProvider } from "./auth";
import { View } from "components/lib";
import "react-toastify/dist/ReactToastify.css";

import { NotFound } from "views/error/404";

import "../css/output.css";
import { ToastContainer } from "react-toastify";
import authServices from "services/authServices";
import { useDispatch } from "react-redux";
import { saveActiveButton } from "./reducers/activeButton";

import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'
const Settings = require("settings.json");

// const tagManagerArgs = {
//   gtmId: 'GTM-NWD8WDLK'
// }
const tagManagerArgs = {
  gtmId: 'GTM-K6TT66BB'
}

TagManager.initialize(tagManagerArgs)

const routes = [
  ...require("routes/app").default,
  ...require("routes/website").default,
];
function PrivateRoute({ children }) {
  const user = authServices.getCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const pathsToCheck = [
    "/dashboard/createnewproject",
    "/projectUpdate",
    "/designtypography",
    "/designbriefing",
    "/colorpalette",
    "/brand-assets",
    "/mockups",
    "/symbolism",
    "/symbolismlogo",
    "/resultstarter",
  ];

  if (!user) {
    setTimeout(() => navigate("/"), 0);
    return null;
  } else if (
    user &&
    user.client === false &&
    location.pathname !== "/NewClient" &&
    pathsToCheck.includes(location.pathname)
  ) {
    const dispatch = useDispatch;
    setTimeout(() => navigate("/NewClient"), 0);
    dispatch(saveActiveButton("Clients"));
    return null;
  }

  return children;
}
export default function App(props) {  
  const user = JSON.parse(localStorage.getItem("user"));
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;
  if (user?.token) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }
  return (
    <>
      <Helmet>
        <title>
          Brandfy - Create Unique, Professional Brands in Record Time with AI
        </title>
        <meta
          name="description"
          content="Discover Brandfy, the AI branding tool that empowers you to craft distinctive and professional brands quickly and cost-effectively. Elevate your design game today!"
        />
      </Helmet>
      <AuthProvider>
        <ToastContainer></ToastContainer>
        <BrowserRouter>
          <Routes>
            {routes.map((route) => {
              if (route.private) {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <PrivateRoute>
                        <View
                          display={route.view}
                          layout={route.layout}
                          title={route.title}
                        />
                      </PrivateRoute>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <View
                        display={route.view}
                        layout={route.layout}
                        title={route.title}
                      />
                    }
                  />
                );
              }
            })}
            <Route
              path="*"
              element={
                <View display={NotFound} layout="home" title="404 Not Found" />
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}
