/* eslint-disable import/no-anonymous-default-export */
import http from "./httpService";
import config from "../config";
const { apiUrl } = config;
const apiEndpoint = `${apiUrl}/analytics`;

async function getAnalytics(user_id) {
  return await http.get(`${apiEndpoint}/${user_id}`);
}
async function getMonthlyAnalytics(user_id, body) {
  return await http.post(`${apiEndpoint}/${user_id}`, body);
}
export default {
  getAnalytics,
  getMonthlyAnalytics,
};
