/* eslint-disable import/no-anonymous-default-export */
import http from "./httpService";
import config from "../config";
const { apiUrl } = config;
const apiEndpoint = `${apiUrl}/client`;

async function CreateClient(body) {
  return await http.post(`${apiEndpoint}`, body);
}
async function getClient(user_id) {
  return await http.get(`${apiEndpoint}/user/${user_id}`);
}
async function getClientById(client_id) {
  return await http.get(`${apiEndpoint}/${client_id}`);
}
async function updateClientProject(clientId, body) {
  return await http.put(`${apiEndpoint}/assignProject/${clientId}`, body);
}
async function updateClient(clientId, body) {
  return await http.put(`${apiEndpoint}/${clientId}`, body);
}
async function deleteClient(clientId) {
  return await http.delete(`${apiEndpoint}/${clientId}`);
}

async function assingProject(body) {
  return await http.post(`${apiEndpoint}/assign_project`, body);
}

export default {
  CreateClient,
  getClient,
  updateClientProject,
  deleteClient,
  getClientById,
  updateClient,
  assingProject,
};
