/* eslint-disable import/no-anonymous-default-export */
import http from "./httpService";
import config from "../config";
const { apiUrl } = config;
const apiEndpoint = `${apiUrl}/team`;

async function CreateTeam(body) {
  return await http.post(`${apiEndpoint}`, body);
}
async function DeleteTeam(teamId) {
  return await http.delete(`${apiEndpoint}/${teamId}`);
}
async function getTeamByUser(userId) {
  return await http.get(`${apiEndpoint}/user/${userId}`);
}

async function getTeamProjectByUser(userId) {
  return await http.get(`${apiEndpoint}/project/${userId}`);
}
async function getTeamById(teamId) {
  return await http.get(`${apiEndpoint}/${teamId}`);
}
async function updateTeamProject(teamId, body) {
  return await http.put(`${apiEndpoint}/assignProject/${teamId}`, body);
}
async function updateTeamMembers(teamId, body) {
  return await http.put(`${apiEndpoint}/addMembers/${teamId}`, body);
}
async function getTeamAndMemberByUserId(userId) {
  return await http.get(`${apiEndpoint}/populateMember/${userId}`);
}
export default {
  CreateTeam,
  getTeamAndMemberByUserId,
  getTeamByUser,
  updateTeamProject,
  DeleteTeam,
  getTeamById,
  updateTeamMembers,
  getTeamProjectByUser,
};
