// reducers/projectRecordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // cardNo: "",
    // expiryDate: "",
    // country: "",
    // cvc: "",

    plan_name: "",
    price: 0,
    remaining_project: 0,
    remaining_collaborator: 0,
    extra_project: 0,
    priceId: ""
};
const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        savePayment: (state, action) => {
            const updatedProps = action.payload;
            Object.keys(updatedProps).forEach((key) => {
                if (state.hasOwnProperty(key)) {
                    state[key] = updatedProps[key];
                }
            });
        },
        updatePayment: (state, action) => {
            const { property, value } = action.payload;
            state[property] = value;
        },
        deletePayment(state, action) {
            const itemId = action.payload;
            state.items = state.items.filter((item) => item.id !== itemId);
        },
        deletePaymentRecord: (state) => {
            return initialState;
        },
    },
});
export const { deletePayment } = paymentSlice.actions;
export const { savePayment } = paymentSlice.actions;
export const { updatePayment } = paymentSlice.actions;
export const { deletePaymentRecord } = paymentSlice.actions;
export default paymentSlice.reducer;