import React, { useEffect, useState } from "react";
import "./SignUp.css";
import { useNavigate } from "components/lib";
import Checkbox from "@mui/material/Checkbox";
import Joi, { isError } from "joi";
import validationService from "services/validationService";
import userServices from "services/userServices";
import authServices from "services/authServices";
import GoogleLogin from "@leecheuk/react-google-login";
import toastService from "services/toastService";
import { gapi } from "gapi-script";
import { useLocation, useSearchParams } from "react-router-dom";
import clientServices from "services/clientServices";
import projectServices from "services/projectServices";
import memberServices from "services/memberServices";
import { useDispatch } from "react-redux";
import { addProject } from "app/reducers/projectEditSlice";
import { CircularProgress } from "@mui/material";

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_Password] = useState("");
  const [errors, setErrors] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [showPage, setShowPage] = useState(false);
  const [disableResend, setDisabledResend] = useState(true);
  const [countdown, setCountdown] = useState(120);
  const [params] = useSearchParams();
  const location = useLocation();
  const [googleLoading, setGoogleLoading] = useState(false);
  const [termAndCondition, setTermAndCondition] = useState(false);
  const [isCharError, setisCharError] = useState(false);

  const plan = params.get("plan");

  if (plan) {
    localStorage?.setItem("plan", plan);
  }

  const schema = Joi.object().keys({
    userName: Joi.string().required().label("User name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    password: Joi.string()
      .required()
      .label("Password")
      .custom((value, helpers) => {
        if (
          !value.match(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])[a-zA-Z\d\S]{8,30}$/
          )
        ) {
          return helpers.message(
            "{{#label}} should be minimum 8 and maximum 30 characters, at least one uppercase letter, one lowercase letter, one number, and one special character"
          );
        }
        return value;
      }),

    confirm_password: Joi.string().required(),
    provider: Joi.string(),
    firstName: Joi.string().allow(""),
    lastName: Joi.string().allow(""),
    PhoneNo: Joi.string().allow(""),
  });

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "628243039684-36j7aqhcvkr1fg6bfr5m37c4nrr4hptm.apps.googleusercontent.com",
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const handleSubmit = () => {
    let data = {
      userName,
      email,
      password,
      confirm_password,
      provider: "app",
      firstName: "",
      lastName: "",
      PhoneNo: "",
    };

    const errors = validationService.validate(data, schema);
    setErrors(errors || {});

    if (errors) return;

    const isValidEmialCharLength = email.split("@")[0];
    const isUsernameValid = isValidEmialCharLength?.length < 4;
    if (isUsernameValid)
      return toastService.error("Email Name Length Should be Greater than 4");

    const isValidUserName = userName.length < 4;
    if (isValidUserName)
      return toastService.error("User Name Should be Greater than 4");
    if (password !== confirm_password) return;
    if (!termAndCondition) {
      toastService.error("Please accept terms and conditions before signup", 1);
    } else {
      doSubmit();
    }
  };

  const handleChange =
    (setData) =>
    ({ currentTarget: input }) => {
      const errorMessage = validationService.validateProperty(input, schema);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
      setErrors(errors);
      setData(input.value);
    };

  const handleConfirmPasswordChange = (e) => {
    setConfirm_Password(e.target.value);
    if (e.target.value !== password) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const doSubmit = async () => {
    setLoading(true);

    let data = {
      userName,
      email,
      password,
      confirm_password,
      provider: "app",
      lastName: "",
      firstName: "",
      phoneNo: "",
      plan: plan?.toString(),
    };

    try {
      let response = await userServices.createUser(data);

      if (response) {
        window.dataLayer.push({
          event: "signup",
          userId: response?.data?.data?.userData?.id,
          plan: response?.data?.data?.userData?.plan,
        });
        setShowPage(true);
        const queryString = `verifyemail=${email}`;
        navigate(`/signup?${queryString}`);
        toastService.success(
          "Activate your account to unlock all features.",
          2
        );

        setLoading(false);
      }
    } catch (ex) {
      toastService.error(ex);
      setLoading(false);
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderButton = (renderProps) => (
    <button
      onClick={renderProps.onClick}
      disabled={renderProps.disabled}
      className="googleSignUpButton"
    >
      <img
        style={{
          height: "29.952px",
          width: "29.952px",
          marginLeft: "176.256px",
          marginRight: "9.936px",
        }}
        src="GoogleLoginIcon.png"
        alt=""
      />
      Google
    </button>
  );

  const onFailure = (error) => {};

  const onsuccess = (response) => {
    setGoogleLoading(true);
    doSocialSubmit(response);
  };

  const doSocialSubmit = async (account) => {
    const data = {
      email: account.profileObj.email,
      provider: "google",
      userName: account.profileObj.name,
      picture: account.profileObj.imageUrl,
      googleId: account.googleId,
      firstName: account.profileObj.givenName,
      lastName: account.profileObj.familyName,
      plan: plan,
    };
    try {
      let response = await authServices.login(data);
      const client = await clientServices.getClient(response.data.data.id);
      if (client.data.data.result.length > 0) {
        const data = {
          ...response.data.data,
          client: true,
        };

        const personalDataRes = await projectServices.getProjectByUserId(
          response.data.data.id
        );

        const memberDataRes = await memberServices.getProjectsAsMember(
          response.data.data.id
        );

        const personalProjects = personalDataRes.data.data.result;
        const memberProjects = memberDataRes.data.data.result.map((item) => ({
          ...item.project,
        }));

        const combinedProjects = [...personalProjects, ...memberProjects];
        combinedProjects.map((project) => {
          dispatch(addProject(project));
        });

        authServices.storeLoginData(response.data.token, data);
      } else {
        const data = {
          ...response.data.data,
          client: false,
        };
        authServices.storeLoginData(response.data.token, data);
      }
      toastService.success(
        `Successfully logged in. Welcome back, ${response.data.data.userName}.`
      );
      setGoogleLoading(false);
      navigate("/dashboard");
      authServices.getCurrentToken();
      authServices.getCurrentUser();
    } catch (ex) {
      setGoogleLoading(false);
      toastService.error(ex);
    }
  };

  const resendEmailHandler = async () => {
    if (disableResend) return;
    try {
      setDisabledResend(true);
      const queryParams = new URLSearchParams(location.search);
      const email = queryParams.get("verifyemail");

      const res = await userServices.resendEmail({ email: email });
      if (res) {
        setShowPage(true);
        const queryString = `verifyemail=${email}`;
        navigate(`/signup?${queryString}`);
        setShowPage(true);
        toastService.success("Resend Activation Email");
        setDisabledResend(true);
        setCountdown(120);
      }
    } catch (ex) {
      toastService.error(ex);
    }
  };

  function convertSecondsToMinutesFormat(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return (
      String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0")
    );
  }

  const validateSpecilaChar = (value) => {
    const specialCharRegex = /[-*!@#$%^&*()_+={}[\]:;<>,.?~\\]/;
    const hasSpecialChar = specialCharRegex.test(value);
    const containsSpace = /\s/.test(value);
    setisCharError(hasSpecialChar || containsSpace);
    return !hasSpecialChar && !containsSpace;
  };

  useEffect(() => {
    if (params.get("verifyemail")) {
      setShowPage(true);
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setDisabledResend(false);
            return 0;
          } else {
            return prevCountdown - 1;
          }
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setShowPage(false);
    }
  }, [params.get("verifyemail"), countdown]);

  return !showPage ? (
    <>
      <div className="SignUpContainer">
        <div className="SignUpleftSideFormBox">
          {googleLoading && (
            <div className="login-loading">
              <CircularProgress style={{ color: "#f5ff01" }} />
            </div>
          )}

          <div className="BrandfyLogoSignUp">
            <img src="brandfy_black.svg" alt="" />
          </div>
          <div className="SignUpFormBox">
            <p className="SignUpYourPara">Create your account</p>
            <p className="SignUpwelcomeBackPara">Unlock all Features!</p>

            <GoogleLogin
              className="googleIcon"
              cookiePolicy="single_host_origin"
              onFailure={onFailure}
              prompt="select_account"
              onSuccess={onsuccess}
              render={renderButton}
              clientId="628243039684-36j7aqhcvkr1fg6bfr5m37c4nrr4hptm.apps.googleusercontent.com"
            />
            <div className="SignUpcontinueEmail">
              <div className="SingUpline"></div>
              <p className="SignUpcontinuePara">or continue with email</p>
              <div className="SingUpline"></div>
            </div>

            <input
              type="text"
              placeholder="Username"
              name="userName"
              className="SignUpInput"
              value={userName}
              onChange={handleChange(setUserName)}
              onKeyUp={(e) => {
                validateSpecilaChar(e.target.value);
              }}
            />
            {errors.userName && (
              <span className="signUpPageError">{errors.userName}</span>
            )}
            {isCharError && (
              <span className="signUpPageError">
                Special characters and Space are not allowed !!
              </span>
            )}

            <input
              type="text"
              placeholder="Email"
              name="email"
              className="SignUpInput"
              value={email}
              onChange={handleChange(setEmail)}
            />
            {errors.email && (
              <span className="signUpPageError">{errors.email}</span>
            )}
            <input
              type="password"
              placeholder="Password"
              name="password"
              className="SignUpInput"
              value={password}
              onChange={handleChange(setPassword)}
            />
            {errors.password && (
              <span className="signUpPageError">{errors.password}</span>
            )}
            <input
              type="password"
              placeholder="Confirm password"
              value={confirm_password}
              className="SignUpInput"
              onChange={handleConfirmPasswordChange}
            />
            <span className="signUpPageError">{passwordError}</span>

            <div className="SignUprememForgetBox">
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: "9.872 px",
                    height: "20px",
                  },
                  color: "red",
                  "&.Mui-checked": {
                    color: "red",
                  },
                }}
                onChange={(e) => {
                  setTermAndCondition(e.target.checked);
                }}
              />

              <p className="SignUpRememberPara">
                {" "}
                Accept{" "}
                <span
                  style={{
                    marginLeft: "4px",
                    color: "#fd5d5d",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/terms");
                  }}
                >
                  Terms And Conditions
                </span>{" "}
              </p>
            </div>
            {/* {showMessage && (
              <div className="verification">
                <p
                  style={{
                    color: "white",
                    "font-size": "16px",
                  }}
                >
                  An email sent to your account please verify
                </p>
              </div>
            )} */}

            <button
              className="LoginButtonForm"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={loading || isCharError}
            >
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <span className="login_text">{"SIGN UP"}</span>
              )}
            </button>

            <div className="SignUpdontHaveCreateBox">
              <p className="SignUphaveAccountPara">You have account?</p>
              <p
                className="SignUpAccountPara"
                onClick={() => {
                  navigate("/");
                }}
              >
                Login now
              </p>
            </div>
          </div>
        </div>
        <div className="SignUpRightSideFormBox">
          <img src="thinkPic.png" alt="" />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="resendContainer">
        <div className="resendContent">
          <img
            width={"50%"}
            style={{ marginBottom: "30px" }}
            src="brandfy_black.svg"
            alt=""
          />
          <h2 className="resendHeading">Check your </h2>
          <p className="text">
            <p className="font-medium">
              We are glad that you{"'"}re with us. We{"'"}ve sent you a
              verification link to your email address.
            </p>
            <p className=" text">
              If you have not received the email, please click the button below
              to resend the email
            </p>
          </p>
          <button
            className="resend-button"
            onClick={() => {
              resendEmailHandler();
            }}
            disabled={disableResend}
          >
            Resend Email →
          </button>

          {disableResend && (
            <p className="countdown-text">
              You can resend the email in{" "}
              {convertSecondsToMinutesFormat(countdown)} seconds.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default SignUp;
