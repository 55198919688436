// reducers/projectRecordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    itration: false
}
const EditItrationNo = createSlice({
    name: 'itration',
    initialState,
    reducers: {
        EditItration: (state, action) => {
            if (action.payload !== undefined) {
                state.itration = action.payload;
            }
        },

        deleteIteration: () => {
            return initialState;
        }
    }
});

export const { EditItration, deleteIteration } = EditItrationNo.actions;

export default EditItrationNo.reducer;
