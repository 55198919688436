/* eslint-disable import/no-anonymous-default-export */

import Cookies from "universal-cookie";
import http from "./httpService";
import config from "../config";
const { apiUrl } = config;
const apiEndpoint = `${apiUrl}/member`;
const cookies = new Cookies();

async function createMember(body) {
  return await http.post(`${apiEndpoint}`, body);
}

async function getMemberById(memberId) {
  return await http.get(`${apiEndpoint}/${memberId}`);
}
async function getProjectsAsMember(memberId) {
  return await http.get(`${apiEndpoint}/project/${memberId}`);
}
async function getMemberByProject(ProjectId) {
  return await http.get(`${apiEndpoint}/Profile/project/${ProjectId}`);
}
async function updateMemberById(memberId, memberBody) {
  return await http.put(`${apiEndpoint}/${memberId}`, memberBody);
}
async function deleteMemberById(memberId) {
  return await http.delete(`${apiEndpoint}/${memberId}`);
}
export default {
  createMember,
  getMemberById,
  deleteMemberById,
  updateMemberById,
  getProjectsAsMember,
  getMemberByProject,
};
