import React, { useEffect } from "react";
import { useContext } from "react";
import { SearchContext } from "components/layout/app/app";

import RightBar from "views/dashboard/RightBar";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import data from "./data";
import Joi from "joi";
import { useSelector, useDispatch } from "react-redux";
import authServices from "services/authServices";
import validationService from "services/validationService";
import clientServices from "services/clientServices";
import projectServices from "services/projectServices";
import { saveClientDb } from "app/reducers/clientDbSlice";
import toastService from "services/toastService";
import { CircularProgress } from "@mui/material";

function UpdateClient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [valueClient, setValueClient] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [assignLoading, setassignLoading] = useState(false);
  const [spaceError, setspaceError] = useState(false);
  const [selectedProjectId, setselectedProjectId] = useState(null);
  const [toggleModal, settoggleModal] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [showSuggestionsClient, setShowSuggestionsClient] = useState(false);
  const [projectData, setProjectsData] = useState([]);

  const clientRecordData = useSelector((state) => state?.client);
  const [client, setClient] = useState({
    user_id: "",
    client_name: "",
    company_name: "",
    city: "",
    zip_code: "",
    country: "",
    vat_no: "",
    project: [],
  });

  const schema = Joi.object().keys({
    user_id: Joi.string().required().allow(""),
    client_name: Joi.string().required().label("client_name"),
    company_name: Joi.string().required().label("company_name"),
    city: Joi.string().label("city").allow(""),
    zip_code: Joi.string().label("zip_code").allow(""),
    country: Joi.string().label("country").allow(""),
    vat_no: Joi.string().label("vat_no").allow(""),
    project: Joi.array().allow(""),
  });

  const handleChange =
    () =>
    ({ currentTarget: input }) => {
      const updatedData = { ...client, [input.name]: input.value };
      setClient(updatedData);
    };

  const handleSubmit = () => {
    const errors = validationService.validate(client, schema);
    if (errors) return;
    if (spaceError)
      return toastService.error("Fields are not allowed to be empty!!");
    doSubmit();
  };

  const doSubmit = async () => {
    try {
      setisLoading(true);
      const res = await clientServices.updateClient(
        clientRecordData._id,
        client
      );
      const clientdb = await clientServices.getClient(
        res.data.data?.result.user_id
      );
      dispatch(saveClientDb(clientdb.data.data.result));
      navigate("/manageclients");
    } catch (ex) {
      setisLoading(false);
      toastService.error(ex);
    }
  };

  const handelDelete = async () => {
    const res = await clientServices.deleteClient(clientRecordData._id);
    navigate("/manageclients");
  };

  useEffect(() => {
    setClient({ ...client, project: selectedProjects });
  }, [selectedProjects]);

  useEffect(() => {
    async function fetchProjectData() {
      try {
        const user = authServices.getCurrentUser();
        const pro = await projectServices.getProjectByUserId(user.id);

        const projectData = pro.data.data.result.filter(
          (item) => item.status === "inProgress"
        );
        setProjectsData(projectData);

        const projectselecteddata = clientRecordData.project
          .filter((project) => project.status === "inProgress")
          .map((project) => ({
            _id: project._id,
            name: project.name,
          }));

        setSelectedProjects(projectselecteddata.map((project) => project._id));
      } catch (ex) {}
    }
    fetchProjectData();
  }, [clientRecordData]);

  const clientDb = useSelector((state) => state?.clientDb);
  const searchValue = useContext(SearchContext);

  const filteredClients = clientDb?.filter((client) =>
    client.client_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleInputChangeClient = (event) => {
    setShowSuggestionsClient(true);
    setClient({ ...client, country: event.target.value });
  };

  const handleSuggestionClickClient = (suggestion) => {
    setClient({ ...client, country: suggestion });
    setShowSuggestionsClient(false);
  };

  const handleYellowBoxClickClient = () => {
    setShowSuggestionsClient(!showSuggestionsClient);
  };

  // Old Function to Assign Project
  const handleClick = () => {
    if (selectedProjectId) {
      const projectId = selectedProjectId._id;
      if (selectedProjects.includes(projectId)) {
        setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
      } else {
        setSelectedProjects([...selectedProjects, projectId]);
      }
      settoggleModal(false);
    }
  };

  const handleToggle = (project) => {
    setselectedProjectId(project);
    settoggleModal(true);
  };

  const filteredSuggestionsClient = data.filter((suggestion) =>
    suggestion.toLowerCase().includes(client.country.toLowerCase())
  );

  useEffect(() => {
    if (clientRecordData) {
      const { createdAt, updatedAt, _id, ...filteredData } = clientRecordData;
      setClient((prevState) => ({
        ...prevState,
        ...filteredData,
      }));
    }
  }, [clientRecordData]);

  const validateSpace = (value) => {
    const trimmedValue = value.trim();
    const containsNonSpace = trimmedValue.length > 0;
    setspaceError(!containsNonSpace);
    return containsNonSpace;
  };

  const [assignRecordData, setassignRecordData] = useState({
    client_name: "",
  });
  const [getCurrentClientid, setgetCurrentClientid] = useState("");
  const [showAssignSuggestion, setshowAssignSuggestion] = useState(false);

  const handleSuggestionAssignClient = (selectedClient) => {
    setassignRecordData((prevRecord) => ({
      ...prevRecord,
      client_name: selectedClient.client_name,
      currentClient: selectedClient._id,
    }));
    const selectedClientId = selectedClient._id;
    setgetCurrentClientid(selectedClientId);
    setshowAssignSuggestion(false);
  };

  const handleYellowSuggesions = () => {
    setshowAssignSuggestion(!showAssignSuggestion);
  };

  const handleSubmitAssignProject = async () => {
    try {
      setassignLoading(true);
      const data1 = await clientServices.assingProject({
        previousClinet: clientRecordData?._id,
        project_id: selectedProjectId?._id,
        currentClient: getCurrentClientid,
      });
      settoggleModal(false);
      navigate("/manageclients");
    } catch (error) {
      setassignLoading(false);
      toastService.error(error);
    }
  };

  return (
    <div className="ManageClientsContainer">
      <div className="leftSideManageClients">
        <div className="newClientFormDiv">
          <p className="currentManageClientsPara">Manage client</p>
          <div className="manageClientForm1">
            <p className="companyNamepara">Client name</p>

            <input
              placeholder="Client name"
              name="client_name"
              value={client.client_name}
              onChange={handleChange(setClient)}
              className="companyaddresspara"
              type="text"
              onKeyUp={(e) => validateSpace(e.target.value)}
            />
            <p className="companyNamepara">Company name</p>
            <input
              placeholder="Company name"
              name="company_name"
              value={client.company_name}
              onChange={handleChange(setClient)}
              className="companyaddresspara"
              type="text"
              onKeyUp={(e) => validateSpace(e.target.value)}
            />

            <div className="clientCItyDiv">
              <div className="cityDivBox">
                <p className="companyNameparaCity">City</p>
                <input
                  placeholder="City"
                  name="city"
                  value={client.city}
                  onChange={handleChange(setClient)}
                  className="companyaddressparacity"
                  type="text"
                  onKeyUp={(e) => validateSpace(e.target.value)}
                />
              </div>
              {/* <div className="cityDivBox">
                <p className="companyNameparaCity">Zip code</p>
                <input
                  placeholder="000 000 000"
                  name="zip_code"
                  value={client.zip_code}
                  onChange={handleChange(setClient)}
                  className="companyaddressparacity"
                  type="text"
                />
              </div> */}
            </div>

            <div className="forSuggestionBox">
              <div className="clientCItyDiv">
                <div className="clientNameInputBox11">
                  <p className="companyNameparaCity">Country</p>
                  <div
                    className={`boxme2ClientCountry ${
                      showSuggestionsClient ? "show-suggestions2Client" : ""
                    }`}
                  >
                    <input
                      className="inputForClientBox2Client"
                      type="text"
                      value={client.country}
                      onClick={handleYellowBoxClickClient}
                      onChange={handleInputChangeClient}
                      placeholder="Select Country"
                      onKeyUp={(e) => validateSpace(e.target.value)}
                    />
                    {client.country ? (
                      <svg
                        onClick={() => {
                          setClient({ ...client, country: "" });
                        }}
                        className="search-iconCountryName"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 6L6 18"
                          stroke="#FD5D5D"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L18 18"
                          stroke="#FD5D5D"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                    {showSuggestionsClient &&
                      filteredSuggestionsClient.length > 0 && (
                        <ul className="suggestion-list2CountryTeam">
                          {filteredSuggestionsClient.map(
                            (suggestion, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleSuggestionClickClient(suggestion)
                                }
                              >
                                {suggestion}
                              </li>
                            )
                          )}
                        </ul>
                      )}

                    <div
                      className="yellowBox2Client"
                      onClick={handleYellowBoxClickClient}
                    >
                      <svg
                        onClick={handleYellowBoxClickClient}
                        className={`arrow-icon ${
                          showSuggestionsClient ? "up-arrow" : "down-arrow"
                        }`}
                        width="22"
                        height="14"
                        viewBox="0 0 22 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p className="assignedProjectsBoxPara">
              Assigned projects
              <span className="assignedProjectsBoxParaSpan">
                {projectData.length === 0
                  ? "(There are no projects created to assign to the client)"
                  : "(Click to assign projects)"}
              </span>
            </p>

            <div className="assignedProjectDivBox">
              {clientRecordData?.project?.map((project) => (
                <span
                  key={project._id}
                  className={`projectNumassigne ${
                    selectedProjects.includes(project._id) ? "active" : ""
                  }`}
                  // onClick={() => handleClick(project._id)}
                  onClick={() => handleToggle(project, project._id)}
                >
                  {project.name}
                </span>
              ))}
            </div>

            <div className="dottedLine"></div>

            <div className="buttonsBtn">
              <button
                onClick={() => {
                  handleSubmit();
                }}
                className="saveNewClient"
              >
                <svg
                  className="saveNewClientsvg"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.5 21.5H5.5C4.96957 21.5 4.46086 21.2893 4.08579 20.9142C3.71071 20.5391 3.5 20.0304 3.5 19.5V5.5C3.5 4.96957 3.71071 4.46086 4.08579 4.08579C4.46086 3.71071 4.96957 3.5 5.5 3.5H16.5L21.5 8.5V19.5C21.5 20.0304 21.2893 20.5391 20.9142 20.9142C20.5391 21.2893 20.0304 21.5 19.5 21.5Z"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.5 21.5V13.5H7.5V21.5"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 3.5V8.5H15.5"
                    stroke="#484848"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {isLoading ? (
                  <CircularProgress
                    style={{ width: 24, height: 24, color: "white" }}
                    color="primary"
                    variant="indeterminate"
                  />
                ) : (
                  <span className="">Save</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {toggleModal && (
        <div className="assign_project">
          <div className="modal_box border-none">
            <div className="areYouSure">
              Do you really want to assign this project to a new client?
            </div>
            <p className="allprojectItration"></p>

            <div
              className={`boxme2 ${
                showAssignSuggestion ? "show-suggestions2" : ""
              }`}
            >
              <input
                className="inputForClientBox2"
                type="text"
                value={assignRecordData.client_name}
                onClick={handleYellowSuggesions}
                name="client_name"
                readOnly
                placeholder="New client"
              />
              {valueClient ? (
                <svg
                  onClick={() => {
                    setValueClient("");
                  }}
                  className="search-iconClientName"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 6L6 18"
                    stroke="#FD5D5D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#FD5D5D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : null}
              {showAssignSuggestion && (
                <ul className="suggestion-list2Data">
                  {filteredClients
                    ?.filter((fl) => fl?._id !== clientRecordData?._id)
                    ?.map((cl, idx) => (
                      <li
                        key={idx}
                        onClick={() => handleSuggestionAssignClient(cl)}
                      >
                        {cl.client_name}
                      </li>
                    ))}
                </ul>
              )}

              <div className="yellowBox2" onClick={handleYellowSuggesions}>
                <svg
                  onClick={handleYellowSuggesions}
                  className={`arrow-icon ${
                    showAssignSuggestion ? "up-arrow" : "down-arrow"
                  }`}
                  width="22"
                  height="14"
                  viewBox="0 0 22 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2031 12.9062L1.0625 3.8125C0.640625 3.34375 0.640625 2.64062 1.0625 2.21875L2.14062 1.14062C2.5625 0.71875 3.26562 0.71875 3.73438 1.14062L11 8.35938L18.2188 1.14062C18.6875 0.71875 19.3906 0.71875 19.8125 1.14062L20.8906 2.21875C21.3125 2.64062 21.3125 3.34375 20.8906 3.8125L11.75 12.9062C11.3281 13.3281 10.625 13.3281 10.2031 12.9062Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>

            <div className="divbuttonbtns">
              <button
                //  onClick={handleClick}
                onClick={handleSubmitAssignProject}
                className="DeleteMemberTeam"
              >
                {assignLoading ? (
                  <CircularProgress
                    style={{ width: 24, height: 24, color: "white" }}
                    color="primary"
                    variant="indeterminate"
                  />
                ) : (
                  "Yes"
                )}
              </button>
              <button
                onClick={() => {
                  settoggleModal(false);
                }}
                className="cancelMemberTeam"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="RightSideDashboard">
        <RightBar />
      </div>
    </div>
  );
}
export default UpdateClient;
