// reducers/projectRecordSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    picture: "",
    name: "",
    role: "",
    member: [],
    project: []
};

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        saveTeam: (state, action) => {
            const updatedProps = action.payload;
            Object.keys(updatedProps).forEach((key) => {
                if (state.hasOwnProperty(key)) {
                    state[key] = updatedProps[key];
                }
            });
        },
        updateTeam: (state, action) => {
            const { property, value } = action.payload;
            state[property] = value;
        },
        deleteTeam(state, action) {
            const itemId = action.payload;
            state.items = state.items.filter((item) => item.id !== itemId);
        },
        deleteTeamRecord: (state) => {
            return initialState;
        },
    },
});

export const { saveTeam } = teamSlice.actions;
export const { updateTeam } = teamSlice.actions;
export const { deleteTeamRecord } = teamSlice.actions;
export default teamSlice.reducer;