import React, { useEffect, useState, useCallback } from "react";
import "../Symbolism/Symbolism.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getProjectById,
  saveMockups,
  selectedIteration,
  updateCompleteProject,
  saveHumnas,
  savePlace,
  saveTextured,
  saveConceptImage,
} from "app/reducers/projectEditSlice";
import projectServices from "services/projectServices";
import EditItration from "app/reducers/EditItration";

// Components
import Loading from "../magic/loading";
import RightBar from "views/dashboard/RightBar";
import TryagainModal from "components/TryAgainModal/TryagainModal";

const BrandAssetsImages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [toggleTryAgain, settoggleTryAgain] = useState(false);
  const [tryagainText, settryagainText] = useState(false);
  const [liketext, setliketext] = useState({
    dontLike: "",
    extraInstruction: "",
  });

  const SelectedProjectId = useSelector(
    (state) => state?.projectEdit?.currentProject
  );
  const SelectedIterationId = useSelector(
    (state) => state?.projectEdit?.selectedIterationId
  );

  const conceptImageData = useSelector(
    (state) => state?.projectEdit?.conceptImage?.concept
  );
  const TexttureImageData = useSelector(
    (state) => state?.projectEdit?.textureImageData?.textures
  );
  const humnasImageData = useSelector(
    (state) => state?.projectEdit?.humnas?.persons
  );

  const placeImageData = useSelector(
    (state) => state?.projectEdit?.placeData?.places
  );

  dispatch(
    getProjectById({
      projectId: SelectedProjectId,
      iteration_id: SelectedIterationId,
    })
  );

  const itration = useSelector((state) => state?.EditItrationNo?.itration);
  let projectRecordData = useSelector(
    (state) => state?.projectEdit?.singleProject
  );

  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    function timeSince(date) {
      const now = new Date();
      const secondsPast = (now.getTime() - date.getTime()) / 1000;

      if (secondsPast < 60) {
        return `${Math.round(secondsPast)} seconds ago`;
      }
      if (secondsPast < 3600) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
      }
      if (secondsPast <= 86400) {
        return `${Math.round(secondsPast / 3600)} hours ago`;
      }
      if (secondsPast > 86400) {
        const daysPast = Math.round(secondsPast / 86400);
        return `${daysPast} days ago`;
      }
    }

    if (projectRecordData?.updatedAt) {
      const lastUpdatedDate = new Date(projectRecordData?.updatedAt);
      setTimeDifference(timeSince(lastUpdatedDate));
    }

    const intervalId = setInterval(() => {
      if (projectRecordData?.updatedAt) {
        const lastUpdatedDate = new Date(projectRecordData?.updatedAt);
        setTimeDifference(timeSince(lastUpdatedDate));
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [projectRecordData]);

  const handelSubmitClick = async () => {
    try {
      setLoading(true);
      const mockupsData = await projectServices?.generateMockup({
        svgUrl: projectRecordData?.result?.fullLogo,
        id: SelectedProjectId,
        iteration_id: SelectedIterationId,
      });
      if (mockupsData?.statusText === "OK") {
        dispatch(saveMockups(mockupsData?.data?.data?.result));
        navigate("/mockups");
      }
      console.log(mockupsData, "mockupsData");
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const handleChangeLikeText = useCallback(
    (name) => (evt) => {
      setliketext((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );

  const handelTryAgain = async (type) => {
    try {
      setLoading(true);
      settryagainText(true);
      let formData = {
        ...projectRecordData,
        iterate_step: 7,
        iteration_id: SelectedIterationId,
      };
      if (type === "like") {
        formData = {
          ...formData,
          dontLike: liketext?.dontLike,
        };
      } else if (type === "instruction") {
        formData = {
          ...formData,
          extraInstruction: liketext?.extraInstruction,
        };
      }

      const [res, res1, res2, res3] = await Promise.all([
        projectServices?.postGenerateHumanTryAgain(SelectedProjectId, formData),
        projectServices?.postGenerateConceptImagesTryAgain(
          SelectedProjectId,
          formData
        ),
        projectServices?.postGeneratePlaceTryAgain(SelectedProjectId, formData),
        projectServices?.postGenerateTextureTryAgain(
          SelectedProjectId,
          formData
        ),
      ]);

      dispatch(saveHumnas(res?.data && res?.data?.data?.result));
      dispatch(saveConceptImage(res1?.data && res1?.data?.data?.result));
      dispatch(savePlace(res2?.data && res2?.data?.data?.result));
      dispatch(saveTextured(res3?.data && res3?.data?.data?.result));

      setliketext({
        ...liketext,
        dontLike: "",
        extraInstruction: "",
      });

      if (itration) {
        const matchedIteration = res.data.data.result.iteration.find(
          (iter) => iter._id === SelectedIterationId
        );
        const {
          _id,
          createdAt,
          updatedAt,
          __v,
          iterate_step,
          ...filteredData
        } = matchedIteration;
        const projectData = await projectServices.updateProjectIteration(
          SelectedProjectId,
          { ...filteredData, project_id: SelectedProjectId, iterate_step: 7 }
        );
        const sortedIterations =
          projectData?.data?.data?.result?.iteration?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
        dispatch(updateCompleteProject(projectData?.data?.data?.result));
        dispatch(selectedIteration(sortedIterations?.[0]?._id));
        dispatch(EditItration(false));
        setLoading(false);
        settoggleTryAgain(false);
        settryagainText(false);
      } else {
        dispatch(updateCompleteProject(res?.data?.data?.result));
        const index=res.data.data.result.iteration.length>0?res.data.data.result.iteration[res.data.data.result.iteration.length-1]:0
        dispatch(selectedIteration(index._id));
        settryagainText(false);
      }
      setLoading(false);
      settoggleTryAgain(false);
      settryagainText(false);
    } catch {
      setLoading(false);
      settoggleTryAgain(false);
      settryagainText(false);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: "10000",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Loading tryagainText={tryagainText} />
        </div>
      ) : (
        <div className="designsymbolismLogoContainer">
          <div className="leftBriefingBox1">
            <div className="timelineBoxsymbolLogo">
              <div className="timelinesymbolLogo">
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
                <div className="connecterLogo"></div>
              </div>
            </div>

            <div className="projectDesignsymbolLogoBox">
              <p className="DesignsymbolLogoPara">Brand Assets</p>
              <p className="DesignsymbolLogolastSaved">
                Last saved iteration: {timeDifference}
              </p>
            </div>

            <div className="DesignsymbolLogoDesignBox">
              <p className="symbolsParaLogo">
                Here is your Brand Images.
                <span className="symbolsParaspanLogo">
                  If you like the result continue with the next step.
                </span>
              </p>

              <div className="SymbolismBoxLogo">
                <div
                  className="textture_images card w-100"
                  style={{ height: "100%" }}
                >
                  <img
                    style={{ height: "100%" }}
                    src={conceptImageData?.[0]}
                    className="card-img-top card_image"
                    alt="..."
                  />
                </div>

                <div
                  className="textture_images card w-100"
                  style={{ height: "100%" }}
                >
                  <img
                    style={{ height: "100%" }}
                    src={TexttureImageData?.[0]}
                    className="card-img-top card_image"
                    alt="..."
                  />
                </div>

                <div
                  className="textture_images card w-100"
                  style={{ height: "100%" }}
                >
                  <img
                    style={{ height: "100%" }}
                    src={humnasImageData?.[0]}
                    className="card-img-top card_image"
                    alt="..."
                  />
                </div>

                <div
                  className="textture_images card w-100"
                  style={{ height: "100%" }}
                >
                  <img
                    style={{ height: "100%" }}
                    src={placeImageData?.[0]}
                    className="card-img-top card_image"
                    alt="..."
                  />
                </div>

                {/* <div className="d-flex gap-1 pt-3" style={{ height: "600px" }}>
                  <div
                    className="textture_images card w-50"
                    style={{ height: "100%" }}
                  >
                    <img
                      style={{ height: "100%" }}
                      src={conceptImageData[0]}
                      className="card-img-top card_image"
                      alt="..."
                    />
                  </div>

                  <div
                    className="textture_images card w-50"
                    style={{ height: "100%" }}
                  >
                    <img
                      style={{ height: "100%" }}
                      src={TexttureImageData[0]}
                      className="card-img-top card_image"
                      alt="..."
                    />
                  </div>
                </div> */}

                {/* <div className="d-flex gap-1 pt-3" style={{ height: "600px" }}>
                  <div
                    className="textture_images card w-50"
                    style={{ height: "100%" }}
                  >
                    <img
                      style={{ height: "100%" }}
                      src={humnasImageData[0]}
                      className="card-img-top card_image"
                      alt="..."
                    />
                  </div>

                  <div
                    className="textture_images card w-50"
                    style={{ height: "100%" }}
                  >
                    <img
                      style={{ height: "100%" }}
                      src={placeImageData[0]}
                      className="card-img-top card_image"
                      alt="..."
                    />
                  </div>
                </div> */}
              </div>

              <div className="btnsDivBoxx">
                <button
                  onClick={() => {
                    navigate("/symbolismlogo");
                  }}
                  className="GoBackBtnInsymbolLogo"
                >
                  <span>Go back</span>
                </button>
                <div className="btnsDivBoxxInner">
                  <button
                    className="TryAgainBtnInsymbolLogo"
                    onClick={() => {
                      settoggleTryAgain(true);
                    }}
                  >
                    <span>Try again</span>
                  </button>
                  <button
                    onClick={() => {
                      handelSubmitClick();
                    }}
                    className="NextStepBtnInsymbolLogo"
                  >
                    <span>Next step</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="RightSideDashboard">
            <RightBar />
          </div>
        </div>
      )}

      {toggleTryAgain && (
        <TryagainModal
          settoggleTryAgain={settoggleTryAgain}
          handleSubmit={handelTryAgain}
          handleChangeLikeText={handleChangeLikeText}
          liketext={liketext}
          setliketext={setliketext}
        />
      )}
    </>
  );
};

export default BrandAssetsImages;
