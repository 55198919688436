import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import counterReducer from '../features/counter/counterSlice';
import counterReducerinput from '../features/InputValue/Inputvalue';
import projectReducers from './reducers/projectSclice';
import resultSlice from './reducers/generateResult'
import clientSlice from './reducers/client';
import clientDbSlice from './reducers/clientDbSlice';
import teamSlice from './reducers/team';
import paymentSlice from './reducers/payment';
import planSlice from './reducers/payment';
import planDbSlice from './reducers/planDbSlice';
import ActiveButtonSlice from './reducers/activeButton';
import EditItrationNo from './reducers/EditItration'
import projectEditSlice from './reducers/projectEditSlice';
import rememberMe from './reducers/rememberMe';

const persistConfig = {
    key: 'root',
    storage,
};
const rootReducer = combineReducers({
    counter: counterReducer,
    app: counterReducerinput,
    project: projectReducers,
    result: resultSlice,
    client: clientSlice,
    team: teamSlice,
    payment: paymentSlice,
    plan: planSlice,
    planDb: planDbSlice,
    clientDb: clientDbSlice,
    activeButton: ActiveButtonSlice,
    EditItrationNo: EditItrationNo,
    projectEdit: projectEditSlice,
    RememberMe: rememberMe
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const ignoredActions = ['persist/PERSIST'];

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [...ignoredActions],
        },
    }),
});
export const persistor = persistStore(store);
export default store;
