import { Dashboard } from "views/dashboard/dashboard";
import DoingMagic from "components/ourComponents/magic/DoingMagic";
import DesignBriefing from "components/ourComponents/designBriefing/DesignBriefing";
import DesignMotherBoard from "components/ourComponents/designMotherBoard/DesignMotherBoard";
import DesignTypography from "components/ourComponents/DesignTypography/DesignTypography";
import ColorPalette from "components/ourComponents/ColorPalette/ColorPalette";
import Symbolism from "components/ourComponents/Symbolism/Symbolism";
import SymbolismLogo from "components/ourComponents/Symbolism/SymbolismLogo";
import PlanStarterResult from "components/ourComponents/Results/PlanStarterResult";
import { Projects } from "components/ourComponents/Projects/Projects";
import { ManageClients } from "components/ourComponents/ManageClients/ManageClients";

import ManageTeams from "components/ourComponents/ManageTeams/ManageTeams";
import Faq from "components/ourComponents/FAQ/Faq";
import Settings from "components/ourComponents/Settings/Settings";
import Analytics from "components/ourComponents/Analytics/Analytics";
// import Billing from "components/ourComponents/Billing/BillingTestMode";
import Billing from "components/ourComponents/Billing/BillingLiveMode";
import ProjectUpdate from "components/ourComponents/UpdateProject/updateProject";
import NewClient from "components/ourComponents/NewClient/NewClient";
import UpdateClient from "components/ourComponents/ManageClients/UpdateClient";
import createMember from "components/ourComponents/ManageTeams/createMember";
import WorkingMagic from "components/ourComponents/magic/WorkingMagic";
import Success from "components/ourComponents/Billing/success";
import Failed from "components/ourComponents/Billing/failed";
import NewProject from "components/ourComponents/CreateNewProject/NewProject";
import TypographyWeight from "components/ourComponents/typogtraphyWeight/TypographyWeight";
import MockupsImage from "components/ourComponents/Mockups/MockupsImage";
import BrandAssetsImages from "components/ourComponents/BrandAssets/brandAssets";
import LogoEditingTool from "components/ourComponents/EditingTool/logoEditingTool";
import GooglePay from "components/ourComponents/GooglePay/GooglePay";

const Routes = [
  {
    path: "/dashboard",
    view: Dashboard,
    layout: "app",
    private: true,
    title: "Brandfy",
  },

  {
    path: "/edit-tool",
    view: LogoEditingTool,
    // layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/dashboard/createnewproject",
    view: NewProject,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/projectUpdate",
    view: ProjectUpdate,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/designbriefing",
    view: DesignBriefing,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/createMember",
    view: createMember,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/newclient",
    view: NewClient,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/designtypography",
    view: DesignTypography,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/typographyweight",
    view: TypographyWeight,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/colorpalette",
    view: ColorPalette,
    layout: "app",
    private: true,
    title: "Brandfy",
  },

  {
    path: "/symbolism",
    view: Symbolism,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/symbolismlogo",
    view: SymbolismLogo,
    layout: "app",
    private: true,
    title: "Brandfy",
  },

  // New Step Added Start
  {
    path: "/brand-assets",
    view: BrandAssetsImages,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/mockups",
    view: MockupsImage,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  // New Step Added End

  {
    path: "/resultstarter",
    view: PlanStarterResult,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/designmotherboard",
    view: DesignMotherBoard,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/projects",
    view: Projects,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/manageclients",
    view: ManageClients,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/updateclients",
    view: UpdateClient,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/manageteam",
    view: ManageTeams,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/faq",
    view: Faq,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/settings",
    view: Settings,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/analytics",
    view: Analytics,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/billing",
    view: Billing,
    layout: "app",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/doingmagic",
    view: DoingMagic,
    layout: "home",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/paymentSuccess",
    view: Success,
    private: true,
    layout: "home",
    title: "Brandfy",
  },
  {
    path: "/paymentFailed",
    view: Failed,
    private: true,
    layout: "home",
    title: "Brandfy",
  },

  {
    path: "/workingonmagic",
    view: WorkingMagic,
    layout: "home",
    private: true,
    title: "Brandfy",
  },
  {
    path: "/.well-known/apple-developer-merchantid-domain-association",
    view: GooglePay,
    layout: "home",
    private: false,
    title: "Brandfy",
  },
];

export default Routes;
