import React, { useEffect, useState } from "react";
import "./BillingRightBar.css";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";

// API Services
import authServices from "services/authServices";
import subscriptionServices from "services/subscriptionServices";
import { savePlanDb } from "app/reducers/planDbSlice";
import toastService from "services/toastService";

const BillingRightBar = ({ activeProductData }) => {
  const dispatch = useDispatch();

  const [activeProducts, setActiveProduct] = useState(null);
  const [plan, setPlan] = useState("");

  const planDb = useSelector((state) => state?.planDb);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await authServices?.getCurrentUser();
        const plan = await subscriptionServices?.getPlan(user?.id);
        dispatch(savePlanDb(plan?.data?.data?.result[0]));
        console.log(plan?.data?.data?.result[0], 4555555555555555555555);
        if (plan) {
          setPlan(plan?.data?.data?.result?.[0]?.plan_name);
        }
      } catch (error) {
        toastService.error(error);
      }
    };
    fetchData();
  }, [activeProductData, plan, activeProducts]);

  function capitalizeFirstLetter(string) {
    if (string && typeof string === "string") {
      for (let i = 0; i < string.length; i++) {
        if (string[i].match(/[a-z]/i)) {
          return (
            string.slice(0, i) +
            string[i].toUpperCase() +
            string.slice(i + 1).toLowerCase()
          );
        }
      }
    }
    return string;
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: -100 },
    show: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    const filteredActualPlan = activeProductData?.filter((filterProduct) => {
      return filterProduct.metadata.db_name === planDb.plan_name;
    });

    setActiveProduct(filteredActualPlan);
  }, [activeProductData, plan]);

  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="show"
        className="RightSideBillingContainer"
      >
        <p className="yourActualPlan">Your Actual Plan</p>

        {plan !== "" ? (
          <>
            {activeProducts &&
              activeProducts?.map((product, idx) => (
                <>
                  <motion.div
                    key={idx}
                    variants={cardVariants}
                    className={`BillingCard activeplan`}
                  >
                    {product.name !== "Brandfy @ Pro Plan" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <rect width="26" height="50" fill="#F5FF01" />
                        <rect x="38" width="12" height="50" fill="#F5FF01" />
                      </svg>
                    ) : (
                      <svg
                        className="BillingCardsvg"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 0H12V10L38 10V0H50V50H38V40H12V50H0V40V10V0Z"
                          fill="#F5FF01"
                        />
                      </svg>
                    )}

                    <div className="ProTextBilling">
                      {product.name.split("@")[1]}
                      <br />
                      <span className="ProTextBillingspn">
                        {capitalizeFirstLetter(product.description)}
                      </span>
                    </div>

                    <div className="biilingamountPara">
                      <del className="biilingamountParaspan">
                        ${product.metadata.del_price}
                      </del>
                      {product.metadata.price}
                    </div>

                    {/* <p className="unlimitedParaAccess">
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Unlimited access to:
                    </p> */}

                    <br />

                    <div className="unlimitedAccessBox">
                      {product?.function_list &&
                        product.function_list.map((functionItem, index) => (
                          <span key={index} className="allStarterPack">
                            {product.name.split("@")[1].trim() ===
                            "Pro Plan" ? (
                              functionItem === "All Starter Pack" ? (
                                <svg
                                  className="unlimitedParaAccesssvg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20 6L9 17L4 12"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="unlimitedParaAccesssvg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 5V19"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M5 12H19"
                                    stroke="#D1D1D1"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )
                            ) : (
                              <svg
                                className="unlimitedParaAccesssvg"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20 6L9 17L4 12"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                            {capitalizeFirstLetter(functionItem)}
                          </span>
                        ))}
                    </div>
                    <p
                      className={`OneUserNoCollaboration ${
                        product.name.split("@")[1].trim() === "Master Plan"
                          ? "OneUserNoCollaboration1"
                          : ""
                      }`}
                    >
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {capitalizeFirstLetter(
                        product.metadata.collaborative_users
                      )}
                    </p>
                    {/* <p className="monthInclude">
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {capitalizeFirstLetter(product.metadata.projects)}
                    </p>
                    <span className="MoreProjectBilling">
                      <svg
                        className="unlimitedParaAccesssvg"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 5V19"
                          stroke="#F5FF01"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 12H19"
                          stroke="#F5FF01"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {capitalizeFirstLetter(product.metadata.more_project)}
                    </span> */}
                  </motion.div>
                </>
              ))}
          </>
        ) : (
          <p className="noplanClas">No paid plan has been purchased yet.</p>
        )}
        <div>
          <img
            className="SettingsImagePageana1"
            src="DataSettings.png"
            alt=""
          />
        </div>
      </motion.div>
    </>
  );
};

export default BillingRightBar;
