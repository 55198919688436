import React, { useRef, useEffect } from "react";
import "./Settings.css";
import { useState } from "react";
import authServices from "services/authServices";
import projectServices from "services/projectServices";
import validationService from "services/validationService";
import Joi from "joi";
import userServices from "services/userServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveActiveButton } from "app/reducers/activeButton";
import toastService from "services/toastService";
const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const user = authServices.getCurrentUser();
  const [error, setErrors] = useState([]);

  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNo: user.phoneNo,
    picture: user.picture,
    email: user.email,
  });

  const schema = Joi.object().keys({
    firstName: Joi.string().label("firstName").allow(""),
    lastName: Joi.string().label("lastName").allow(""),
    phoneNo: Joi.string().label("phoneNo").allow(""),
    picture: Joi.string().label("picture").allow(""),
    email: Joi.string().label("email").allow(""),
  });

  const handleChange =
    (setData) =>
    ({ currentTarget: input }) => {
      const updatedData = { ...userData, [input.name]: input.value };
      const errorMessage = validationService.validateProperty(input, schema);
      setUserData(updatedData);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [input.name]: errorMessage || "",
      }));
    };

  const handleSubmit = () => {
    const errors = validationService.validate(userData, schema);
    setErrors(errors || {});
    if (errors) return;
    doSubmit();
  };

  const doSubmit = async (e) => {
    try {
      const res = await userServices.UpdateUser(user.id, userData);
      authServices.updateUserData(res?.data?.data?.user);
      toastService.success("The information has been updated successfully.");
    } catch (ex) {
      toastService.error(ex);
    }
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handelUploadFile = async (file, type) => {
    if (!file) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await projectServices.uploadImage(formData);
      setUserData((prevState) => ({
        ...prevState,
        picture: res.data.data.result,
      }));
    } catch (ex) {
      toastService.error(ex);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }

    const allowedMimeTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];

    if (allowedMimeTypes.includes(selectedFile.type)) {
      handelUploadFile(selectedFile);
    } else {
      toastService.error(
        "An error occurred while uploading the file. Please try again.",
        2
      );
    }
  };

  return (
    <div className="SettingsContainer">
      <div className="LeftSideSettings">
        <span className="SettingsParaBox">Settings</span>
        <div className="settingsFormImageBox">
          <div className="SettingsIMageBox">
            <div className="SettingsIMage">
              <img src={userData.picture} alt="" />
            </div>

            <div className="editDeleteBoxSettings">
              <svg
                onClick={handleSvgClick}
                className="editDeleteBoxSettingsvgg"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <svg
                onClick={() => {
                  setUserData((prevState) => ({
                    ...prevState,
                    picture: "",
                  }));
                }}
                className="editDeleteBoxSettingsvgg"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 6H5H21"
                  stroke="#D1D1D1"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                  stroke="#D1D1D1"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 11V17"
                  stroke="#D1D1D1"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 11V17"
                  stroke="#D1D1D1"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="settingsForm">
            <div className="firstNameDivBoxSettings">
              <p className="companyNameparaCitySettings">First name</p>
              <input
                name="firstName"
                onChange={handleChange(setUserData)}
                placeholder="Robert"
                value={userData.firstName}
                className="companyaddressparacitySettings"
                type="text"
              />
            </div>

            <div className="LastNameDivBoxSettings">
              <p className="companyNameparaCitySettings">Last name</p>
              <input
                placeholder="Lemans"
                name="lastName"
                onChange={handleChange(setUserData)}
                value={userData.lastName}
                className="companyaddressparacitySettings"
                type="text"
              />
            </div>

            <div className="MailNameDivBoxSettings">
              <p className="companyNameparaCitySettings">E-mail</p>
              <input
                placeholder="user@gmail.com"
                name="email"
                readOnly
                value={userData.email}
                className="companyaddressparacitySettings"
                type="text"
              />
            </div>

            <div className="PhoneNameDivBoxSettings">
              <p className="companyNameparaCitySettings">Phone number</p>
              <input
                name="phoneNo"
                onChange={handleChange(setUserData)}
                placeholder="000 000 000"
                value={userData.phoneNo}
                className="companyaddressparacitySettings"
                type="text"
              />
            </div>
          </div>
        </div>

        <div className="buttonsBtn" style={{ paddingRight: "24px" }}>
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="saveNewClient1"
          >
            <svg
              className="saveNewClientsvg"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5 21.5H5.5C4.96957 21.5 4.46086 21.2893 4.08579 20.9142C3.71071 20.5391 3.5 20.0304 3.5 19.5V5.5C3.5 4.96957 3.71071 4.46086 4.08579 4.08579C4.46086 3.71071 4.96957 3.5 5.5 3.5H16.5L21.5 8.5V19.5C21.5 20.0304 21.2893 20.5391 20.9142 20.9142C20.5391 21.2893 20.0304 21.5 19.5 21.5Z"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.5 21.5V13.5H7.5V21.5"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 3.5V8.5H15.5"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span style={{ marginTop: "5px", display: "block" }}>Save</span>
          </button>
        </div>
        <div className="dottedLineSettings"></div>
      </div>

      <div className="RightSideSettings">
        <span className="SettingsUsPara">Settings</span>
        <button
          onClick={() => {
            dispatch(saveActiveButton("Setting"));
            navigate("/settings");
          }}
          className="mailSettings"
        >
          <svg
            className="SettingsFAQsvg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5 3.00023C18.8978 2.6024 19.4374 2.37891 20 2.37891C20.5626 2.37891 21.1022 2.6024 21.5 3.00023C21.8978 3.39805 22.1213 3.93762 22.1213 4.50023C22.1213 5.06284 21.8978 5.6024 21.5 6.00023L12 15.5002L8 16.5002L9 12.5002L18.5 3.00023Z"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Profile
        </button>

        <button
          onClick={() => {
            navigate("/billing");
          }}
          className="mailSettings"
        >
          <svg
            className="SettingsFAQsvg"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 21.5V19.5C17 18.4391 16.5786 17.4217 15.8284 16.6716C15.0783 15.9214 14.0609 15.5 13 15.5H5C3.93913 15.5 2.92172 15.9214 2.17157 16.6716C1.42143 17.4217 1 18.4391 1 19.5V21.5"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23 21.4999V19.4999C22.9993 18.6136 22.7044 17.7527 22.1614 17.0522C21.6184 16.3517 20.8581 15.8515 20 15.6299"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 3.62988C16.8604 3.85018 17.623 4.35058 18.1676 5.05219C18.7122 5.7538 19.0078 6.61671 19.0078 7.50488C19.0078 8.39305 18.7122 9.25596 18.1676 9.95757C17.623 10.6592 16.8604 11.1596 16 11.3799"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          My plan
        </button>

        <button
          onClick={() => {
            dispatch(saveActiveButton("Team"));
            navigate("/manageteam");
          }}
          className="mailSettings"
        >
          <svg
            className="SettingsFAQsvg"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.08984 9.49959C9.32495 8.83126 9.789 8.2677 10.3998 7.90873C11.0106 7.54975 11.7287 7.41853 12.427 7.5383C13.1253 7.65808 13.7587 8.02112 14.2149 8.56312C14.6712 9.10512 14.9209 9.79112 14.9198 10.4996C14.9198 12.4996 11.9198 13.4996 11.9198 13.4996"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 17.5H12.01"
              stroke="#D1D1D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Team
        </button>

        <button
          onClick={() => {
            dispatch(saveActiveButton("Billing"));
            navigate("/billing");
          }}
          className="UpgradeBtnsFAQsetting"
        >
          <svg
            className="SettingsFAQsvg"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1290_2574)">
              <path
                d="M9 22.5C9.55228 22.5 10 22.0523 10 21.5C10 20.9477 9.55228 20.5 9 20.5C8.44772 20.5 8 20.9477 8 21.5C8 22.0523 8.44772 22.5 9 22.5Z"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20 22.5C20.5523 22.5 21 22.0523 21 21.5C21 20.9477 20.5523 20.5 20 20.5C19.4477 20.5 19 20.9477 19 21.5C19 22.0523 19.4477 22.5 20 22.5Z"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1.5H5L7.68 14.89C7.77144 15.3504 8.02191 15.764 8.38755 16.0583C8.75318 16.3526 9.2107 16.509 9.68 16.5H19.4C19.8693 16.509 20.3268 16.3526 20.6925 16.0583C21.0581 15.764 21.3086 15.3504 21.4 14.89L23 6.5H6"
                stroke="#484848"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1290_2574">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          Upgrade
        </button>

        <img className="SettingsImagePage" src="DataSettings.png" alt="" />
      </div>
    </div>
  );
};

export default Settings;
